import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputMask } from 'primereact/inputmask';
import { Dropdown } from 'primereact/dropdown';

import { ModalHeader } from 'shared/ModalHeader';
import { RequiredValidation } from 'components/UI/RequiredValidation/RequiredValidation';
import { stateOptions } from 'constants/dropdownOptions';
import { validateCardNumber, validateExpiry } from './helpers';
import useIntuitPayment from './hooks/useIntuitPayment';
import GlobalLoader from 'components/GlobalLoader/GlobalLoader';
import CardList from './components/CardList';

export default function IntuitPaymentModal({ handleClose, paymentDetails, loadData, isFromFirm = false }) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({ defaultValues: { amount: paymentDetails?.due_amount } });

  const isInvoice = paymentDetails?.res_type === 'invoice';
  const selectedCard = watch('selectedCard');

  const { makePayment, cards, setShowCardList, showCardList, deleteCard } = useIntuitPayment(paymentDetails, isFromFirm);

  const onSubmit = (data) => {
    const callback = () => {
      handleClose();
      typeof loadData === 'function' && loadData();
    };
    makePayment(data, callback);
  };
  const amount = watch('amount');
  return (
    <Dialog header={ModalHeader} visible onHide={handleClose} className="intuit-payment-popup" closable={false} style={{ width: '60vw' }}>
      <GlobalLoader />
      <div>
        <div className="intuit-payment-card">
          <div className="d-flex gap-8 flex-wrap gap-50">
            <div className="row align-items-center py-2">
              <div className="col-12">
                <div>
                  <div>
                    <label className="p-medium">Total due amount</label>
                  </div>
                  <div>
                    <b>${paymentDetails?.due_amount}</b>
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-center py-2">
              <div className="col-12">
                <div>
                  <label className="p-medium">{isInvoice ? 'Invoice ID' : 'Payment request ID'} </label>
                  <div>
                    <b>{paymentDetails?.invoice_number || paymentDetails?.payment_request_number}</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center py-2 flex-wrap">
            <div>
              <label className="p-medium">Payment amount{<span className="text-danger">{'*'}</span>}</label>
            </div>
            <div className="ms-4">
              <Controller
                name="amount"
                control={control}
                defaultValue={Number(paymentDetails?.due_amount)}
                rules={{
                  required: 'Payment amount is required',
                  validate: {
                    positive: (value) => value > 0 || 'Amount must be greater than zero',
                    maxDue: (value) =>
                      value <= Number(paymentDetails?.due_amount) ||
                      `Amount must not exceed due amount of $${Number(paymentDetails?.due_amount)}`,
                  },
                }}
                render={({ field }) => (
                  <InputNumber
                    value={field.value}
                    onValueChange={(e) => field.onChange(e.value)}
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    className="input-shadow"
                    inputRef={field.ref}
                  />
                )}
              />
              <RequiredValidation field="amount" errors={errors} />
            </div>
          </div>
        </div>
        {!isFromFirm && (
          <>
            {showCardList ? (
              <div className="d-flex justify-content-between align-items-center mb-3">
                <p className="mb-0">Choose Card</p>
                <span
                  onClick={() => {
                    setShowCardList(false);
                    setValue('selectedCard', null);
                  }}
                  className="p-button p-component p-button-outlined top-button pointer "
                >
                  + Add new card
                </span>
              </div>
            ) : (
              <div className="d-flex justify-content-end">
                <button
                  onClick={() => {
                    setShowCardList(true);
                    setValue('selectedCard', null);
                  }}
                  className="p-button p-component p-button-outlined top-button pointer "
                >
                  Show saved cards
                </button>
              </div>
            )}
          </>
        )}
        {showCardList && !isFromFirm ? (
          <>
            <div className="saved-cards">
              <RequiredValidation field="selectedCard" errors={errors} />
              <Controller
                name="selectedCard"
                control={control}
                rules={{ required: 'Please select a card' }}
                render={({ field }) => (
                  <CardList cards={cards} setSelectedCard={(e) => field.onChange(e)} selectedCard={selectedCard} deleteCard={deleteCard} />
                )}
              />
            </div>
          </>
        ) : (
          <div>
            <div className="row mt-2">
              <div className="col-xs-12">
                <div className="form-group">
                  <label htmlFor="cardNumber" className="p-medium">
                    Card number
                  </label>
                  <div className="input-group">
                    <Controller
                      name="card.number"
                      control={control}
                      rules={{
                        required: 'Card number is required',
                        validate: (value) => validateCardNumber(value) || 'Invalid card number',
                      }}
                      render={({ field }) => (
                        <InputMask
                          mask="9999 9999 9999 9999"
                          placeholder="Enter card number"
                          className="w-100 input-shadow"
                          value={field.value}
                          onChange={(e) => field.onChange(e.value)}
                          inputRef={field.ref}
                        />
                      )}
                    />
                  </div>
                </div>
                <RequiredValidation field="card.number" errors={errors} />
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-xs-7 col-md-7">
                <div className="form-group">
                  <label htmlFor="cardExpiry">
                    <span className="hidden-xs p-medium">Expiration date</span>
                  </label>
                  <Controller
                    name="expiry"
                    control={control}
                    rules={{
                      required: 'Expiry date is required',
                      validate: validateExpiry,
                    }}
                    render={({ field }) => (
                      <InputMask
                        id="expiry"
                        mask="99/9999"
                        placeholder="MM/YYYY"
                        className="w-100 input-shadow"
                        inputRef={field.ref}
                        value={field.value}
                        onChange={(e) => field.onChange(e.value)}
                      />
                    )}
                  />
                </div>
                <RequiredValidation field="expiry" errors={errors} />
              </div>
              <div className="col-xs-5 col-md-5 pull-right">
                <div className="form-group">
                  <label htmlFor="cardCVC" className="p-medium">
                    CVC
                  </label>
                  <Controller
                    name="card.cvc"
                    rules={{
                      required: 'CVC is required',
                      pattern: {
                        value: /^[0-9]{3}$/,
                        message: 'CVC must be a 3-digit number',
                      },
                    }}
                    control={control}
                    render={({ field }) => <InputText className="w-100 input-shadow" type="tel" placeholder="CVC" {...field} />}
                  />
                </div>
                <RequiredValidation field="card.cvc" errors={errors} />
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-xs-12">
                <div className="form-group">
                  <label htmlFor="name" className="p-medium">
                    Name on card
                  </label>
                  <Controller
                    name="card.name"
                    control={control}
                    rules={{
                      required: 'Name on card is required',
                    }}
                    render={({ field }) => <InputText type="text" className="w-100 input-shadow" {...field} />}
                  />
                </div>
                <RequiredValidation field="card.name" errors={errors} />
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-xs-12">
                <div className="form-group">
                  <label htmlFor="streetAddress" className="p-medium">
                    Street address
                  </label>
                  <Controller
                    name="card.address.streetAddress"
                    rules={{
                      required: 'Street address is required',
                      minLength: {
                        value: 2,
                        message: 'City must have at least 2 characters',
                      },
                      maxLength: {
                        value: 200,
                        message: 'City must have no more than 200 characters',
                      },
                    }}
                    control={control}
                    render={({ field }) => <InputText type="text" className="w-100 input-shadow" {...field} />}
                  />
                </div>
                <RequiredValidation field="card.address.streetAddress" errors={errors} />
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-xs-7 col-md-7">
                <div>
                  <label htmlFor="country" className="p-medium">
                    Country
                  </label>
                  <Controller
                    name="card.address.country"
                    defaultValue={'US'}
                    control={control}
                    render={({ field }) => <InputText type="text" className="w-100 input-shadow" {...field} disabled />}
                  />
                </div>
                <RequiredValidation field="card.address.country" errors={errors} />
              </div>

              <div className="col-xs-5 col-md-5 pull-right">
                <div className="form-group">
                  <label htmlFor="city" className="p-medium">
                    City
                  </label>
                  <Controller
                    name="card.address.city"
                    rules={{
                      required: 'City is required',
                      minLength: {
                        value: 2,
                        message: 'City must have at least 2 characters',
                      },
                      maxLength: {
                        value: 50,
                        message: 'City must have no more than 50 characters',
                      },
                    }}
                    control={control}
                    render={({ field }) => <InputText type="text" className="w-100 input-shadow" {...field} />}
                  />
                </div>
                <RequiredValidation field="card.address.city" errors={errors} />
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-xs-7 col-md-7">
                <div className="form-group">
                  <label htmlFor="state" className="p-medium">
                    State
                  </label>
                  <Controller
                    name="card.address.state"
                    control={control}
                    rules={{ required: 'State is required' }}
                    render={({ field }) => (
                      <Dropdown
                        value={field.value}
                        options={stateOptions}
                        onChange={(e) => field.onChange(e.value)}
                        placeholder="Select a State"
                        optionLabel="label"
                        optionValue="value"
                        className="w-100 input-shadow"
                      />
                    )}
                  />
                </div>
                <RequiredValidation field="card.address.state" errors={errors} />
              </div>

              <div className="col-xs-5 col-md-5 pull-right">
                <div className="form-group">
                  <label htmlFor="postalCode" className="p-medium">
                    Postal code
                  </label>
                  <Controller
                    name="card.address.postalCode"
                    rules={{
                      required: 'Postal code is required',
                      minLength: {
                        value: 1,
                        message: 'Postal code must have 1 character minimum',
                      },
                      maxLength: {
                        value: 10,
                        message: 'Postal code must have 10 characters maximum',
                      },
                    }}
                    control={control}
                    render={({ field }) => <InputText type="text" className="w-100 input-shadow" {...field} />}
                  />
                </div>
                <RequiredValidation field="card.address.postalCode" errors={errors} />
              </div>
            </div>
            {!isFromFirm && (
              <div className="mt-1 d-flex align-items-center">
                <Controller
                  name="save_card"
                  control={control}
                  render={({ field }) => <Checkbox checked={field.value} className="me-2" onChange={(e) => field.onChange(e.checked)} />}
                />
                Securely save card for later use.
              </div>
            )}
          </div>
        )}
        <div className="footer-btn">
          <Button className="p-button-secondary outline me-2" label="Cancel" onClick={() => handleClose()} />
          <Button className="p-button p-button-secondary" label={amount ? `Pay $${amount}` : 'Pay'} onClick={handleSubmit(onSubmit)} />
        </div>
      </div>
    </Dialog>
  );
}
