import React, { useContext, useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { AvatarGroup } from 'primereact/avatargroup';
import { Checkbox } from 'primereact/checkbox';
import { Avatar } from 'primereact/avatar';
import { classNames } from 'primereact/utils';
import { Tooltip } from 'primereact/tooltip';
import { TieredMenu } from 'primereact/tieredmenu';
import Cookies from 'universal-cookie';

import { GroupUserTooltip } from 'components/UI/GroupUserTooltip/GroupUserTooltip';
import { handleDateTimeOffset, isTaskOverdue } from 'utils/utility_functions/timezone';
import { useTaskContext } from 'modules/Tasks/Context/TaskContext';
import { UserDetailsContext } from 'context/userDetailsContext';
import { useToggle } from 'hooks/useToggle';
import ImageComponent from 'shared/ImageComponent';
import PriorityDropdown from 'components/UI/PriorityDropdown/PriorityDropdown';
import constants from 'constants/index';
import { handleRedirection, openLinkInBlank } from 'utils/utils';

const cookie = new Cookies();

const tenantId = cookie.get('tenantId');

const getSubTaskLength = (subTask) => {
  try {
    let completed = subTask.filter((val) => val.sub_task_status);
    let total = subTask.length;
    return `${completed.length} done of ${total}`;
  } catch (err) {
    return 0;
  }
};

function getStyle({ draggableStyle, virtualStyle, isDragging }) {
  const combined = {
    ...virtualStyle,
    ...draggableStyle,
  };

  // Being lazy: this is defined in our css file
  const grid = 4;

  // when dragging we want to use the draggable style for placement, otherwise use the virtual style
  const result = {
    ...combined,
    height: isDragging ? combined.height : combined.height - grid,
    left: isDragging ? combined.left : combined.left + grid,
    width: isDragging ? draggableStyle.width : `calc(${combined.width} - ${grid * 2}px)`,
    marginBottom: grid,
  };

  return result;
}

const TaskCard = (props) => {
  const { provided, item, style, isDragging, index, setRowHeight, toggledSubTask, isClosed, boardData, cardAction } = props;
  const history = useHistory();
  const userContext = useContext(UserDetailsContext);
  const { handleTaskComplete, updateTask, handleSubTaskCheckBox, setSelectedTask, caseDetails, isFromCase, isFromLead } = useTaskContext();
  const [priorityValue, setPriorityValue] = useState(item?.priority ?? '');
  const [showSubTasks, setShowSubTasks] = useToggle(toggledSubTask?.current?.includes(item?.task_id));

  const rowRef = useRef({});
  const menu = useRef(null);

  useEffect(() => {
    if (rowRef.current) {
      setRowHeight(index, rowRef.current.clientHeight);
    }
  }, [rowRef, showSubTasks]); // eslint-disable-line

  const updateTaskPriority = (e) => {
    const { sk, pk, task_id, due_date, case_id, task_stage, groups, assign_to, task_name } = item;
    setPriorityValue(e?.value);
    updateTask(task_id, {
      priority: e?.value,
      sk,
      pk,
      due_date,
      case_id,
      task_stage,
      groups,
      assign_to,
      task_name,
    });
  };

  const ViewTask = () => {
    const url = '/tasks';
    const stateToPass = { viewTask: item };

    if (!openLinkInBlank(url, stateToPass)) {
      setSelectedTask(item);
    }
  };

  const onClickTaskCaseName = (e) => {
    const { case_name, case_id, is_lead } = item;
    isFromLead ? e.preventDefault() : case_name === 'TBD' || is_lead ? redirectToPage(e, true) : redirectToPage(e);
    // case_name && case_id ? redirectToPage(e, is_lead) : e.preventDefault();
  };
  const redirectToPage = (e, isLead = false) => {
    e.preventDefault();
    const { case_id } = item || {};
    let route = '';
    if (isLead) {
      route = case_id ? `/leads/${case_id}` : '/leads';
    } else {
      route = case_id ? `/cases/${case_id}` : '/cases';
    }

    handleRedirection(history, route);
  };

  const toggleList = () => {
    // save sub tasks toggle state in useRef to retain them on rendering
    if (Array.isArray(toggledSubTask?.current)) {
      if (toggledSubTask?.current.includes(item?.task_id)) {
        toggledSubTask.current = toggledSubTask?.current?.filter((v) => v !== item?.task_id);
      } else toggledSubTask.current = [...toggledSubTask?.current, item?.task_id];
    }
    setShowSubTasks();
  };

  const navigateToModule = (item) => {
    let route = '/';
    if (item?.group_id && item?.group_name) {
      route = `/settings/groups`;
    } else if (item?.contact_id) {
      //this is client
      route = `/contacts/${item?.contact_id}`;
    } else if (item?.firm_user_id) {
      //this all users other than client
      route = `/settings/team-management/${item?.firm_user_id}`;
    }

    handleRedirection(history, route);
  };

  const markAsComplete = (item) => {
    if (item.task_stage !== 'done') {
      handleTaskComplete(item);
    }
  };

  const assignedToUsers = [
    ...item?.assign_to?.filter((v) =>
      Boolean(
        (v?.cognito_username || v?.firm_user_id) &&
          v?.is_individual_user &&
          (v?.firm_user_id ? caseDetails?.case_team_member_ids?.includes(v?.firm_user_id) : true)
      )
    ),
    ...(item?.groups || []),
  ];

  const moveToItems = [];
  const usedLabels = new Set(); // Set to track used labels

  if (boardData && boardData.columns) {
    Object.values(boardData?.columns)?.forEach((column) => {
      const label = column?.title;
      if (!usedLabels?.has(label)) {
        // Check if label is not already used
        moveToItems.push({
          label: label,
          command: () => {
            const moveEvent = {
              draggableId: `${item.sk}#${item.pk}`,
              type: 'DEFAULT',
              source: {
                index: index,
                droppableId: item?.task_stage,
              },
              reason: 'DROP',
              mode: 'FLUID',
              destination: {
                droppableId: column?.id,
                index: 0,
              },
              combine: null,
            };
            cardAction('move', moveEvent);
          },
        });
        usedLabels.add(label); // Add label to used labels set
      }
    });
  }

  const items = [
    {
      label: 'Move To',
      items: moveToItems, // Use moveToItems here
    },
  ];

  return (
    <div
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      style={getStyle({
        draggableStyle: provided.draggableProps.style,
        virtualStyle: style,
        isDragging,
      })}
      className={`item ${isDragging ? 'is-dragging' : ''}`}
    >
      <div className="container bg-white w-100 p-3 card-layout" ref={rowRef}>
        <div className="d-flex align-items-center pb-2 lh-1 task-border">
          <div className="">
            <Tooltip target={`.tooltip-${index}`} />
            <i
              className={classNames({
                [`tooltip-${index}`]: true,
                'fas fa-check-circle mark-complete-icon': true,
                'mark-as-completed': item?.task_stage === 'done',
              })}
              data-pr-tooltip={item.task_stage === 'done' ? 'Task Completed' : 'Mark as complete'}
              onClick={() => markAsComplete(item)}
            />
          </div>

          <div
            className="task-title text-bold pointer ellipsis-1 redirection-link-no-text-transform"
            title={item.task_name}
            onClick={ViewTask}
          >
            {item.task_name}
          </div>

          <div className="col-sm-4 d-flex justify-content-end text-end ms-auto">
            <PriorityDropdown
              className="w-100 border rounded priority-dropdown align-items-center"
              value={priorityValue}
              onChange={updateTaskPriority}
              iconOnly
              disabled={isClosed}
            />
          </div>
          <div
            className="d-flex border border-1 rounded  justify-content-center p-2 task-lane-header-options pointer ms-2"
            onClick={(e) => menu.current.toggle(e)}
          >
            <i className="pi pi-ellipsis-v"></i>
            <TieredMenu model={items} popup ref={menu} id="popup_menu" />
          </div>
        </div>
        {!(isFromCase || isFromLead) && (
          <div className="d-flex align-items-center justify-content-between py-2">
            {/* <div className="col-6 d-flex align-items-center task-sub-head">
              <span>{item?.is_lead ? "Lead" : "Case"}</span>
            </div> */}
            {item?.case_name && item?.case_id ? (
              <div
                className={`text-break text-truncate text-nowrap text-end pointer text-primary-dark`}
                title={item?.case_name}
                onClick={onClickTaskCaseName}
              >
                {item?.case_name ? item.case_name : 'N/A'}
              </div>
            ) : (
              <div className={`text-break text-end`}>{'N/A'}</div>
            )}
          </div>
        )}
        <div className="d-flex align-items-center justify-content-between py-2">
          <div className="col-8 d-flex align-items-center task-sub-head">
            <i className="icon-calendar pe-1" />
            Due Date
          </div>
          <div className="col-4">
            <div className="taskcreate-date d-flex justify-content-end">
              <span className="d-flex">
                {handleDateTimeOffset(userContext?.userDetails?.timezone, item?.due_date, constants.month_date_year_format)}
              </span>
            </div>
          </div>
        </div>
        <div className="date-wrap d-flex align-items-center py-2">
          <div className="col-8 d-flex align-items-center task-sub-head">
            <i className="icon-contacts pe-1" /> Assignees
          </div>
          <div className="col-4 d-flex justify-content-end">
            <AvatarGroup>
              {assignedToUsers?.map((user, i) => {
                if (i < 4) {
                  return (
                    <ImageComponent
                      key={i}
                      filePath={
                        user?.contact_id || user?.firm_user_id
                          ? `${tenantId}/${user?.contact_id ? user?.contact_id : user?.firm_user_id}/profile_image`
                          : null
                      }
                      fileName={user.assignee_name || user?.group_name}
                      className={`me-1 task-avatar assignee-${user?.assignee_id || user?.group_id}-${i}`}
                      style={{ backgroundColor: '#2196F3', color: '#ffffff' }}
                      onClick={() => user?.group_status !== 'Deleted' && navigateToModule(user)}
                      toolTipTarget={`.assignee-${user?.assignee_id || user?.group_id}-${i}`}
                      // toolTipContent={user?.assignee_name || GroupUserTooltip(user, item?.assign_to)}
                      toolTipContent={
                        <>
                          {user?.firm_user_id && <i className="fas fa-user-tie me-2"></i>}
                          {user?.assignee_name || GroupUserTooltip(user, item?.assign_to)}
                        </>
                      }
                      tooltipPosition="top"
                      showTooltip={true}
                    />
                  );
                }
              })}
              {assignedToUsers?.length > 4 && (
                <Avatar
                  label={`+${assignedToUsers?.length - 4}`}
                  className="me-1 pointer"
                  style={{
                    backgroundColor: '#9c27b0',
                    color: '#ffffff',
                    width: 25,
                    height: 25,
                  }}
                  shape="circle"
                />
              )}
            </AvatarGroup>
          </div>
        </div>
        <div className="d-flex py-2">
          <div className="col-12 d-flex justify-content-between">
            <div className="small pointer d-flex align-items-center" onClick={toggleList}>
              Subtasks<span className="black-600 ms-2"> {` (${getSubTaskLength(item.sub_task)}) `}</span>
              {showSubTasks ? <i className="pi pi-angle-up ms-2" /> : <i className="pi pi-angle-down ms-2" />}
            </div>
            {isTaskOverdue(item?.due_date, userContext?.userDetails?.timezone) && item.task_stage !== 'done' && (
              <span className="tag m-1 ms-0 overdue-tag">Overdue</span>
            )}
          </div>
        </div>
        <div className="d-flex flex-column">
          {showSubTasks &&
            item?.sub_task?.map((subItem, index) => {
              return (
                <div className="col-12 d-flex justify-content-between border sub-tasks p-2 my-1" key={index}>
                  <div className="d-flex align-items-center  F-size12" key={index} style={{ overflow: 'auto' }}>
                    <Checkbox
                      checked={subItem.sub_task_status}
                      inputId={index}
                      onChange={(e) => handleSubTaskCheckBox(e.checked, subItem?.sk, item)}
                      disabled={isClosed}
                      className="pointer h-auto me-2"
                    />
                    <span key={index} style={{ width: '88%' }} title={subItem?.sub_task_description} className="text-overflow-ellipsis">
                      {subItem?.sub_task_description}
                    </span>
                  </div>
                  <AvatarGroup>
                    {subItem?.assign_to?.map((user, i) => {
                      if (i < 4) {
                        return (
                          <ImageComponent
                            key={i}
                            filePath={
                              user?.contact_id || user?.firm_user_id
                                ? `${tenantId}/${user?.contact_id ? user?.contact_id : user?.firm_user_id}/profile_image`
                                : null
                            }
                            fileName={user.assignee_name || user?.group_name}
                            className={`me-1 task-avatar assignee-${user?.assignee_id || user?.group_id}-${i}`}
                            style={{ backgroundColor: '#2196F3', color: '#ffffff' }}
                            onClick={() => user?.group_status !== 'Deleted' && navigateToModule(user)}
                            toolTipTarget={`.assignee-${user?.assignee_id || user?.group_id}-${i}`}
                            // toolTipContent={user?.assignee_name || GroupUserTooltip(user, item?.assign_to)}
                            toolTipContent={
                              <>
                                {user?.firm_user_id && <i className="fas fa-user-tie me-2"></i>}
                                {user?.assignee_name || GroupUserTooltip(user, item?.assign_to)}
                              </>
                            }
                            tooltipPosition="top"
                            showTooltip={true}
                          />
                        );
                      }
                    })}
                    {subItem?.assign_to?.length > 4 && (
                      <Avatar
                        label={`+${subItem?.assign_to?.length - 4}`}
                        className="me-1 pointer"
                        style={{
                          backgroundColor: '#9c27b0',
                          color: '#ffffff',
                          width: 25,
                          height: 25,
                        }}
                        shape="circle"
                      />
                    )}
                  </AvatarGroup>
                </div>
              );
            })}
        </div>
      </div>
      {item?.text}
    </div>
  );
};

export default React.memo(TaskCard);
