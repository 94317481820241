import React, { useState, memo, useEffect, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Chips } from 'primereact/chips';
import { Button } from 'primereact/button';
import ImageComponent from 'shared/ImageComponent';
import TextSnippetWrapper from 'components/TextSnippetWrapper';
import useSendMail from '../hooks/useSendMail';
import { handleDateTimeOffset } from 'utils/utility_functions/timezone';
import { UserDetailsContext, useUserDetailsContext } from 'context/userDetailsContext';
import { sanitizeHtmlContent } from '../utils/utils';
import Dropzone from 'react-dropzone';
import { MultiSelect } from 'primereact/multiselect';
import { useLoaderDispatch } from 'context/LoaderContext';
import { uploadFiles } from 'utils/filesUtils';
import { presignedURLGet } from 'modules/file_manager/services';
import { useNavbarContext } from 'context/NavbarContext';
import InputErrorMessage from 'components/UI/InputErrorMessage/InputErrorMessage';

const MailPreviewContainer = ({ children, subject, caseId, isFromMailLogs, setShow, setShowMailDetailsModal, caseName }) => {
  return (
    <div
      className="border shadow-middle p-2 mail-preview-inner max-height-90"
      style={{
        overflowY: 'auto',
      }}
    >
      {!caseId && !isFromMailLogs && (
        <div className="d-flex justify-content-between">
          <h1 className="m-2 sub-title">{subject}</h1>
          <span className="d-flex align-items-center text-hint gap-1">
            <i className="pi pi-info-circle" /> To reply, please map this email to a Case/Lead
          </span>
        </div>
      )}
      {isFromMailLogs && (
        <div className="d-flex justify-content-end">
          <Button
            type="button"
            className="p-button p-component p-button-primary button outline ms-auto mb-2"
            label="Map to Case/Lead"
            onClick={() => {
              setShow(true);
              // setShowMailDetailsModal(false);
            }}
          />
        </div>
      )}
      <div className="w-100 h-100 overflow-y-auto mail-threads">{children}</div>
    </div>
  );
};

const MailItem = memo(({ mailItem, index, numberOfThreads, caseDetails, existingFiles, handleEmailData, setQuery }) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const { sendMail } = useSendMail();
  const { userDetails } = useUserDetailsContext();
  const setLoader = useLoaderDispatch();
  const userContext = useContext(UserDetailsContext);

  const { downloadFile: downloadAs } = useNavbarContext();

  const [showReplyBox, setShowReplyBox] = useState(false);
  const [showFullContent, setShowFullContent] = useState(index === numberOfThreads - 1);

  const recipients = mailItem?.is_sent
    ? mailItem?.email_members || []
    : (mailItem?.email_members || []).filter((member) => !member.is_sender);

  const replyRecipients = mailItem?.is_sent
    ? mailItem?.email_members || []
    : (mailItem?.email_members || []).filter((member) => member.is_sender);

  const ccList = mailItem?.cc_list || [];
  const [chipEmails, setChipEmails] = useState(replyRecipients.map((item) => item?.user_email));
  const [files, setFiles] = useState([]);
  const userId = userContext?.firmDetails?.current_user;

  useEffect(() => {
    setChipEmails(replyRecipients.map((item) => item?.user_email));
  }, [showReplyBox]);

  const toggleReplyBox = () => {
    setShowReplyBox(!showReplyBox);
    reset();
  };

  const onSubmit = async (data) => {
    if (files?.length > 0) {
      setLoader(true);

      try {
        const uploadedFiles = await uploadFiles(files, 'email/outbound', caseDetails?.case_id, userId);

        data.attachments = [...(data.attachments || []), ...uploadedFiles];
      } catch (error) {
        console.error(error);
      } finally {
        setLoader(false);
      }
    }
    const formattedData = {
      ...data,
      subject: mailItem?.subject,
      email_content: data?.email_content,
      cc_list: [],
      bcc_list: [],
      reply_to: mailItem?.email_message_id || undefined,
      case_id: caseDetails?.case_id,
      case_name: caseDetails?.case_description?.case_name,
      is_lead: caseDetails?.is_lead ? caseDetails?.is_lead : false,
      recipient_list: chipEmails,
      reply_reference_pk: mailItem?.pk,
      reply_reference_sk: mailItem?.sk,
      attachments: data?.attachments,
    };

    // Map the email_members to get the user_name, since the recipient_list expects a list of emails,
    // we need to map the emails to the user_name for appending to existing data to view in the UI
    const recipientMap = new Map(mailItem?.email_members.map((item) => [item?.user_email, item?.user_name]));
    const recipientListWithUserNames = chipEmails.map((email) => ({
      user_email: email,
      user_name: recipientMap.get(email) || email,
    }));
    // allData variable contains the currently replied email data, along with previous reply mail data
    const allData = {
      ...mailItem,
      email_members: recipientListWithUserNames,
      email_content: data?.email_content,
      time: new Date().toISOString(),
      email_from: {
        user_email: userDetails?.business_email,
        user_name: userDetails?.first_name + ' ' + userDetails?.last_name,
      },
      attachments: data?.attachments,
    };
    const response = await sendMail(formattedData);

    if (response.status === 200) {
      const { cc_list, ...rest } = allData;

      handleEmailData(rest); // giving the data to the parent component using callback funtion
      toggleReplyBox();
    }
  };

  const onChangeFile = async (filesData) => {
    setFiles([...files, ...filesData]);
  };

  // const onChangeFile = (filesData) => {
  //   // clearErrors('replyMessage');
  //   setFiles([...files, ...filesData]);
  // };

  const downloadHandler = async (file) => {
    let file_path = file?.file_path;
    let file_name = file?.file_name;
    let data = await presignedURLGet(file_path);
    let url = data?.data?.file_url;
    downloadAs(url, file_name);
  };

  return (
    <div className="py-3 px-2 border-bottom">
      <div className="w-100">
        {/* Email Header */}
        <div className="d-flex flex-row justify-content-between py-2 pointer">
          <div className="d-flex gap-2 flex-row align-items-center w-100" onClick={() => setShowFullContent(!showFullContent)}>
            <ImageComponent
              fileName={mailItem?.email_from?.user_email?.charAt(0)}
              style={{ backgroundColor: '#2196F3', color: '#ffffff' }}
              fileSize="medium.jpg"
            />
            <div className="d-flex flex-column w-100 gap-1">
              <div className="d-flex flex-row w-100 justify-content-between">
                <div className="w-100 d-flex flex-row">
                  <h5 className="caption-bold mb-0 pe-2">
                    <span>{mailItem?.email_from?.user_name || mailItem?.email_from?.user_email} </span>
                  </h5>
                  <span className="text-hint cursor-text">
                    {'<'}
                    {mailItem?.email_from?.user_email}
                    {'>'}
                  </span>
                </div>
              </div>
              <div className="d-flex flex-row w-100 justify-content-between">
                <div className="w-100 gap-1">
                  <div className="d-flex text-hint gap-1 w-100">
                    <span>to:</span>
                    <span className="cursor-text">{recipients.map((item) => item?.user_email).join(', ')}</span>
                  </div>
                  {ccList?.length > 0 && (
                    <div className="d-flex text-hint gap-1">
                      <span>cc:</span>
                      <span className="cursor-text">{ccList?.map((item) => item).join(', ')}</span>
                    </div>
                  )}
                </div>
                <div className="d-flex justify-content-end align-items-center w-100">
                  <span className="text-hint black-600">{handleDateTimeOffset(null, mailItem?.time, 'MM-DD-YYYY, hh:mm A')}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Email Content */}
      {showFullContent && (
        <div className="my-3 mail-preview-container">
          {mailItem ? (
            <div className="d-flex flex-column gap-2 mb-2">
              <div>
                <span dangerouslySetInnerHTML={sanitizeHtmlContent(mailItem?.email_content)}></span>
              </div>
            </div>
          ) : (
            <span>No Content</span>
          )}
          {mailItem?.attachments?.length > 0 && (
            <>
              <div className="mt-4 pt-2 mb-2 p-medium ">Attachments</div>
              <div className="d-flex align-items-center gap-3 flex-wrap">
                {mailItem?.attachments.map((file) => (
                  <span
                    key={file?.file_id}
                    onClick={() => downloadHandler(file)}
                    className="d-flex align-items-center text-primary-dark pointer"
                  >
                    <i className="pi pi-file me-1"></i>
                    {file?.file_name}
                  </span>
                ))}
              </div>
            </>
          )}
          {caseDetails.case_id && caseDetails?.case_description?.case_name && (
            <div className="col-12 d-flex justify-content-end">
              <Button
                label="Reply"
                onClick={toggleReplyBox}
                className="btn-outline p-button-primary"
                icon="pi pi-reply"
                disabled={!caseDetails?.case_id}
              />
            </div>
          )}
          {showReplyBox && (
            <div className="w-100 mt-3 border shadow-middle p-3">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                  <span className="me-2">To </span>
                  {/* <Chips
                    className="input-shadow border-0 taging"
                    placeholder="Recipients"
                    value={chipEmails}
                    onChange={(e) => setChipEmails(e.value)}
                    separator=","
                  /> */}
                  <Controller
                    name="recipient_list"
                    control={control}
                    rules={{
                      required: chipEmails?.length === 0 ? 'Required' : false,
                      validate: (value) => {
                        if (value) {
                          // Check if the recipient list contains the user's email
                          if (value.some((email) => email.trim() === userDetails?.business_email)) {
                            return 'You cannot send an email to your own address';
                          }

                          // Check if the email domain ends with .lawft.com
                          const internalDomainRegex = /@.*\.lawft\.com$/i;

                          // Validate each email format using regex
                          const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                          for (const email of value) {
                            const trimmedEmail = email.trim();

                            // Check for internal user email domain
                            if (internalDomainRegex.test(trimmedEmail)) {
                              return 'You cannot send email to internal users';
                            }

                            if (!emailRegex.test(email.trim())) {
                              return `Invalid email format: ${email}`;
                            }
                          }

                          return true; // No error if all validations pass
                        }
                      },
                    }}
                    render={({ field }) => (
                      <Chips
                        inputRef={field.ref}
                        id={field.name}
                        value={chipEmails}
                        onChange={(e) => {
                          setChipEmails(e?.value);
                          field.onChange(e?.value);
                        }}
                        placeholder="To"
                        className={'w-100 input-shadow'}
                        separator=","
                      />
                    )}
                  />
                </div>
                <div>
                  <Button
                    icon="icon icon-delete mx-1 cursor-pointer F-size16"
                    className="p-button-danger p-button-text"
                    onClick={toggleReplyBox}
                    aria-label="Delete"
                  />
                </div>
              </div>
              {errors.recipient_list && <InputErrorMessage>{errors.recipient_list.message}</InputErrorMessage>}

              <div>
                <div className="py-3 bg-white b-radius16 d-flex flex-column gap-4 email-quill-reply">
                  <Controller
                    control={control}
                    name="email_content"
                    className="shadow-small"
                    render={({ field: { onChange, value } }) => (
                      <div
                        style={{
                          minHeight: '200px',
                        }}
                      >
                        <TextSnippetWrapper
                          className="email-quill-editor shadow-small"
                          type="editor"
                          value={value}
                          style={{
                            resize: 'both',
                          }}
                          onChange={(e) => onChange(e)}
                        />
                      </div>
                    )}
                  />
                  {files?.length > 0 && (
                    <div>
                      <ul className="list-unstyled">
                        {files &&
                          files?.length > 0 &&
                          files?.map((val, index) => (
                            <div className="d-flex align-items-center">
                              <i className="fas fa-paperclip me-2 mt-1"></i>
                              <li key={index} className="text-break ms-1">
                                {val.name}
                              </li>
                              <i
                                className="icon-delete ms-3 pointer"
                                onClick={() => {
                                  let removedFiles = files.filter((data) => data.path !== val.path);
                                  setFiles(removedFiles);
                                }}
                              ></i>
                            </div>
                          ))}
                      </ul>
                    </div>
                  )}

                  <div className="col-12 px-2 drag-drop-box d-flex flex-wrap justify-content-center align-items-center">
                    <Dropzone
                      onDrop={(acceptedFiles) => onChangeFile(acceptedFiles)}
                      accept="application/pdf, application/msword, .docx, .doc, audio/*, video/*,image/*"
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section className="d-flex align-items-center">
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p className="text-center mb-0">Click or drag file to this area to upload</p>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    <p className="mb-0 d-flex align-items-center">
                      <i>or</i>
                    </p>
                    <Controller
                      name="attachments"
                      control={control}
                      render={({ field: { name, value, onChange } }) => (
                        <MultiSelect
                          options={
                            existingFiles?.length === 0
                              ? [
                                  {
                                    label: 'No Records Found',
                                    value: 'no-records-found',
                                  },
                                ]
                              : existingFiles
                          }
                          className="input-shadow existing-files"
                          placeholder="Select or Search Existing Files"
                          display="chip"
                          value={value || []}
                          filter
                          onChange={(e) => {
                            onChange(e.value);
                          }}
                          // disabled={!messageDetails.case_id}
                          optionLabel="label"
                          optionValue="value"
                          optionDisabled={(index) => index?.value === 'no-records-found'}
                          showClear
                          resetFilterOnHide
                          id={name}
                          onFilter={(event) => setQuery(event?.filter)}
                          emptyFilterMessage="No records found"
                          showSelectAll={false}
                        />
                      )}
                    />
                  </div>

                  <Button
                    label="Send"
                    className="p-button-primary"
                    style={{
                      alignSelf: 'flex-end',
                    }}
                    icon="pi pi-send"
                    onClick={handleSubmit(onSubmit)}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {/* Attachments (if needed in the future) */}
    </div>
  );
});

const MailPreview = ({
  previewMail,
  caseDetails,
  existingFiles,
  handleEmailData,
  setQuery,
  isFromMailLogs,
  setShow,
  setShowMailDetailsModal,
}) => {
  const numberOfThreads = previewMail?.length;

  return (
    <MailPreviewContainer
      subject={previewMail[0]?.subject}
      caseId={caseDetails?.case_id}
      caseName={caseDetails?.case_description?.case_name}
      existingFiles={existingFiles}
      setQuery={setQuery}
      isFromMailLogs={isFromMailLogs}
      setShow={setShow}
      setShowMailDetailsModal={setShowMailDetailsModal}
    >
      {previewMail.map((mailItem, index) => (
        <MailItem
          key={index + mailItem?.time}
          mailItem={mailItem}
          index={index}
          numberOfThreads={numberOfThreads}
          caseDetails={caseDetails}
          existingFiles={existingFiles}
          setQuery={setQuery}
          handleEmailData={handleEmailData}
        />
      ))}
    </MailPreviewContainer>
  );
};

export default MailPreview;
