import { Button } from 'primereact/button';
import React from 'react';

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Whoops!</h1>
      <p style={styles.message}>An unexpected error has occurred. Please try again.</p>
      <Button className="p-button-primary" onClick={resetErrorBoundary}>
        Try again
      </Button>
    </div>
  );
}

const styles = {
  container: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#333',
    textAlign: 'center',
    padding: '0 20px',
  },
  header: {
    fontSize: '2.5rem',
    marginBottom: '1rem',
  },
  message: {
    fontSize: '1.2rem',
    marginBottom: '2rem',
  },
};

export default ErrorFallback;
