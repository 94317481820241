import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';
import { confirmDialog } from 'primereact/confirmdialog';
import { Tag } from 'primereact/tag';

import { useRelationRole } from 'hooks/useRelationRole';
import { useBusinessRole } from 'hooks/useBusinessRole';
import { generateFullName } from 'utils/generateFullNameUtils';
import { PI_PROVIDERS_TYPE } from 'constants/index';
import { useCaseContact } from 'modules/cases/hooks/useCaseContact';
import { useToggle } from 'hooks/useToggle';
import { formatPhoneNumber, handleRedirection } from 'utils/utils';
import ImageComponent from 'shared/ImageComponent';
import AddContact from './AddContact';
import { handleSettingSkAndPkToLocalStorage } from 'utils/localStorageUtils';
import { tableCell } from 'modules/reports/components/TableCell/TableCell';

export default function RelatedContacts({ isCaseClosed, isLeadClosed, isLeadSpecific, caseDetails, getDetails }) {
  const history = useHistory();
  const [isOpenAddContact, toggleAddContact] = useToggle();
  const { relationRoleList } = useRelationRole();
  const { businessRoleList } = useBusinessRole();
  const { updateRelations, updateContact, deleteRelationMember, addContactRelation } = useCaseContact({
    caseDetails,
    isLeadSpecific,
    getDetails,
  });

  const isClosed = isCaseClosed || isLeadClosed;

  const nonPiContacts = useMemo(() => {
    const clientContacts = caseDetails?.client_details.reduce((accumulator, currentValue) => {
      if (currentValue?.is_primary_contact !== true && caseDetails?.client_details?.length !== 1) {
        accumulator.push({
          ...currentValue,
          is_manually_added: true,
        });
      }
      return accumulator;
    }, []);
    return [...clientContacts, ...caseDetails?.relations?.filter((data) => !PI_PROVIDERS_TYPE.includes(data?.contact_type))];
  }, [caseDetails]);

  const clientDetails = useMemo(() => {
    if (caseDetails?.client_details?.length > 0) {
      return caseDetails?.client_details[0];
    } else {
      return caseDetails?.client_details?.find((data) => data?.is_primary_contact === true);
    }
  }, [caseDetails]);

  const piContacts = useMemo(() => caseDetails?.relations?.filter((data) => PI_PROVIDERS_TYPE.includes(data?.contact_type)), [caseDetails]);

  const onContactSubmit = (formData, isNew) => {
    addContactRelation(isNew, formData).then(() => toggleAddContact());
  };

  const confirmDeleteMember = (data) => {
    confirmDialog({
      message: 'Are you sure want to delete this relation?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => deleteRelationMember(data?.contact_id),
    });
  };

  // -----------------Related contacts columns Start----------------------
  const relatedName = (rowData) => {
    const { sk, pk, contact_id, title, first_name, profile_image, contact_type } = rowData;
    const route = contact_id ? `/contacts/${contact_id}` : '/contacts';
    return (
      <>
        <div
          onClick={() => {
            handleSettingSkAndPkToLocalStorage(sk, pk);
            handleRedirection(history, route, rowData);
          }}
          className="d-flex"
        >
          <span className="p-column-title text-break">Name</span>
          <div className="d-inline-block">
            <div className="d-flex">
              <ImageComponent filePath={profile_image} fileName={contact_type === 'business' ? title : first_name} fileSize="small.jpg" />
              <span
                className="redirection-link-no-text-transform"
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '100px',
                }}
                title={generateFullName(rowData)}
              >
                {generateFullName(rowData)}
              </span>
            </div>
            {!(rowData?.status === 'ACTIVE') ? (
              <Tag className="ms-4 tag-cases border-0 archived-tags" value={rowData?.status} rounded></Tag>
            ) : null}
          </div>
        </div>
      </>
    );
  };

  const relatedRole = (rowData) => {
    return (
      <>
        <span className="p-column-title text-break">Role</span>
        <span className="d-inline-table">{rowData.role ?? '-'}</span>
      </>
    );
  };

  const relatedPrimaryContact = (rowData) => {
    const handleClick = (e) => {
      if (!!rowData?.is_manually_added) {
        updateContact({ ...rowData, [e.target.name]: e.checked }, rowData?.contact_id, true);
      } else {
        updateRelations({ relations: [{ ...rowData, [e.target.name]: e.checked }] });
      }
    };
    return (
      <>
        <span className="p-column-title text-break">Primary Contact</span>
        <span className={'d-inline-table ' + (isClosed ? 'pe-none' : '')}>
          <Checkbox
            checked={rowData.is_primary_contact}
            name="is_primary_contact"
            onChange={handleClick}
            disabled={!(rowData?.status === 'ACTIVE') || isClosed}
          />
        </span>
      </>
    );
  };

  const onBehalfTemplate = (rowData) => {
    const handleClick = (e) => {
      updateRelations({ relations: [{ ...rowData, type: e.checked ? 'on-behalf' : undefined }] });
    };
    return (
      <>
        <span className="p-column-title text-break">On Behalf</span>
        <span className={'d-inline-table ' + (isClosed ? 'pe-none' : '')}>
          <Checkbox
            checked={rowData.type === 'on-behalf'}
            name="is_primary_contact"
            onChange={handleClick}
            disabled={!(rowData?.status === 'ACTIVE') || isClosed}
          />
        </span>
      </>
    );
  };

  const relatedBillingContact = (rowData) => {
    const handleClick = (e) => updateRelations({ relations: [{ ...rowData, [e.target.name]: e.checked }] });
    return (
      <>
        <span className="p-column-title text-break">Billing Contact</span>
        <span className={'d-inline-table ' + (isClosed ? 'pe-none' : '')}>
          <Checkbox
            checked={rowData.is_billing_contact}
            name="is_billing_contact"
            onChange={handleClick}
            disabled={!(rowData?.status === 'ACTIVE') || isClosed}
          />
        </span>
      </>
    );
  };

  const relatedLawftAccess = (rowData, props) => {
    let isEmailExist = 'email' in rowData && rowData?.email !== '';
    let statusArchived = rowData?.status === 'ARCHIVED';
    const handleClick = (e) => {
      let _item = { ...rowData };
      if (e.checked === false) {
        _item.notify_case_updates = false;
      }
      const updateData = { relations: [{ ..._item, [e.target.name]: e.checked }] };
      updateRelations(updateData);
    };
    return (
      <>
        <span className="p-column-title text-break">Enable Lawft Access</span>
        <span className={'d-inline-table ' + (isClosed || rowData?.contact_type === 'business' || !isEmailExist ? 'pe-none' : '')}>
          {!isEmailExist && (
            <Tooltip target={`.disabled-checkbox-3-${props?.rowIndex}`} content="Add Email to Enable Lawft Access" position="top" />
          )}
          <Checkbox
            checked={rowData.is_create_client_portal}
            onChange={handleClick}
            disabled={!isEmailExist || isClosed || rowData?.contact_type === 'business' || statusArchived}
            name="is_create_client_portal"
            className={`${!isEmailExist || rowData?.contact_type === 'business' ? `disabled-checkbox-3-${props?.rowIndex}` : ''}`}
          />
        </span>
      </>
    );
  };

  const relatedNotifyCaseUpdates = (rowData, props) => {
    const handleClick = (e) => updateRelations({ relations: [{ ...rowData, [e.target.name]: e.checked }] });
    return (
      <>
        <span className="p-column-title text-break">Notify Case Updates</span>
        <span className={'d-inline-table ' + (isClosed || rowData?.contact_type === 'business' ? 'pe-none' : '')}>
          {!rowData?.is_create_client_portal && (
            <Tooltip
              target={`.disabled-checkbox-4-${props?.rowIndex}`}
              content="Client doesn't have access to the portal."
              position="top"
              disabled={rowData?.status === 'ARCHIVED'}
            />
          )}
          <Checkbox
            checked={rowData?.notify_case_updates}
            onChange={handleClick}
            name="notify_case_updates"
            disabled={
              !rowData?.is_create_client_portal || isClosed || rowData?.contact_type === 'business' || rowData?.status === 'ARCHIVED'
            }
            className={`${
              !rowData?.is_create_client_portal || rowData?.contact_type === 'business' ? `disabled-checkbox-4-${props?.rowIndex}` : ''
            }`}
          />
        </span>
      </>
    );
  };

  const relatedAction = (rowData) => {
    const handleClick = () => {
      if (rowData?.status === 'ACTIVE') {
        confirmDeleteMember(rowData);
      }
    };
    const disabled = rowData?.type === 'on-behalf' || isClosed || rowData?.status === 'ARCHIVED';
    return (
      <>
        <span className="p-column-title text-break">Action</span>
        <span className={'d-inline-table' + (disabled ? 'pe-none' : '')}>
          <i className={'icon-delete cursor-pointer icon-red ' + (disabled ? 'pe-none opacity-50' : '')} onClick={handleClick} />
        </span>
      </>
    );
  };

  const roleEditor = ({ rowData }) => {
    const handleChange = (e) =>
      updateRelations({ relations: [{ ...rowData, [e.target.name]: e.value, client_id: clientDetails?.contact_id }] });

    return (
      <React.Fragment>
        {rowData?.status === 'ACTIVE' && !isClosed ? (
          <Dropdown
            value={rowData?.role}
            onChange={handleChange}
            name="role"
            options={rowData?.contact_type === 'business' ? businessRoleList : relationRoleList}
            optionLabel={rowData?.contact_type === 'business' ? 'name' : 'relation_role'}
            optionValue={rowData?.contact_type === 'business' ? 'name' : 'relation_role'}
            placeholder="Select"
            className="input-shadow w-100 border-0"
          />
        ) : (
          <>{rowData?.role ?? '-'}</>
        )}
      </React.Fragment>
    );
  };

  const relatedPhn = (rowData) => {
    return (
      <>
        <span className="p-column-title text-break">Phone Number</span>
        {formatPhoneNumber(rowData.phone) ?? '-'}
      </>
    );
  };

  return (
    <div className="row">
      <div className="col-sm-12">
        <Tooltip target=".on-behalfs" />
        <Card title="Related Contacts" className="datatable-responsive">
          {!isOpenAddContact ? (
            <div className="d-flex mb-3">
              <div className="col-12 text-end">
                <Button className="p-button p-button-secondary" onClick={toggleAddContact} disabled={isClosed}>
                  Add
                </Button>
              </div>
            </div>
          ) : null}
          <DataTable value={nonPiContacts} responsiveLayout="scroll" emptyMessage="N/A" className="p-datatable-responsive related-contacts">
            <Column header="Name" body={relatedName} bodyClassName="ellipsis-text" style={{ width: '150px' }} />
            <Column header="Email" field="email" body={tableCell} bodyClassName="ellipsis-text" />
            <Column header="Phone Number" field="phone" body={relatedPhn} bodyClassName="ellipsis-text" className="ten-percent" />
            <Column header="Role" body={relatedRole} editor={roleEditor} />
            {isLeadSpecific && (
              <Column
                header={
                  <span
                    style={{ position: 'relative', fontWeight: 'normal' }}
                    className="tooltip-header d-flex align-items-center"
                    data-pr-tooltip="Person who made first contact"
                  >
                    On Behalf
                    <i
                      className="ms-1 pi pi-info-circle on-behalfs cursor-pointer"
                      data-pr-tooltip="Person who made first contact"
                      style={{ fontSize: '13px' }}
                    />
                  </span>
                }
                body={onBehalfTemplate}
                style={{ width: '130px' }}
              />
            )}
            {!isLeadSpecific && (
              <Column header="Primary Contact" body={relatedPrimaryContact} bodyClassName="ellipsis-text" className="ten-percent" />
            )}
            {!isLeadSpecific && (
              <Column header="Billing Contact" body={relatedBillingContact} bodyClassName="ellipsis-text" className="ten-percent" />
            )}
            <Column header="Enable Lawft Access" body={relatedLawftAccess} bodyClassName="ellipsis-text" className="ten-percent" />
            <Column header="Notify Case Updates" body={relatedNotifyCaseUpdates} bodyClassName="ellipsis-text" className="ten-percent" />
            <Column header="Action" body={relatedAction} className="related-contact-action" />
          </DataTable>
          {isOpenAddContact && <AddContact {...{ toggleAddContact, isLeadSpecific, onContactSubmit }} />}
        </Card>
      </div>

      {!!piContacts.length && (
        <div className="col-sm-12 mt-4">
          <Card title="Providers and Vendors" className="datatable-responsive">
            <DataTable value={piContacts} responsiveLayout="scroll" emptyMessage="N/A" className="p-datatable-responsive related-contacts">
              <Column header="Name" body={relatedName} />
              <Column header="Email" field="email" body={tableCell} />
              <Column header="Phone Number" field="phone" body={relatedPhn} />
              <Column header="Role" body={relatedRole} editor={roleEditor} />
              {!isLeadSpecific && <Column header="Primary Contact" body={relatedPrimaryContact} />}
              {!isLeadSpecific && <Column header="Billing Contact" body={relatedBillingContact} />}
              <Column header="Action" body={relatedAction} className="related-contact-action" />
            </DataTable>
          </Card>
        </div>
      )}
    </div>
  );
}
