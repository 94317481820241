import FileSelection from 'components/FileSelection/FileSelection';
import GlobalLoader from 'components/GlobalLoader/GlobalLoader';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import ExtractedEntitiesTable from './ExtractedEntitiesTable';
import { handleRedirection } from 'utils/utils';
import { Button } from 'primereact/button';
import { extractEntities, getEntities } from 'services/fileManager/fileManagerServices';
import useFetchFiles from 'hooks/useFetchFiles';
import { WebSocketConnectionContext } from 'context/WebSocketConnectionContext';
import { ProgressSpinner } from 'primereact/progressspinner';

export default function AiExtractEntities() {
  let { type, id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const caseData = location.state || JSON.parse(localStorage.getItem('caseData'));

  const { files, getFiles, handleSetFiles } = useFetchFiles();
  const websocketContext = useContext(WebSocketConnectionContext);

  const [selectedFiles, setSelectedFiles] = useState({});
  const [extractedEntitiesData, setExtractedEntitiesData] = useState({});
  const [confirmedSelection, setConfirmedSelection] = useState(false);

  const handleGetFiles = () => {
    let obj = {
      case_id: id,
    };
    getFiles(obj);
  };

  const toggleSelect = (id) => {
    setConfirmedSelection(true);
    handleSetFiles((prevFiles) => {
      const updatedFiles = JSON.parse(JSON.stringify(prevFiles));

      const updateNode = (nodes) => {
        Object.keys(nodes)?.forEach((key) => {
          if (nodes[key]?.entry_type === 'file' && nodes[key]?.file_id === id) {
            nodes[key].is_checked = !nodes[key].is_checked;
          } else if (nodes[key]?.entry_type === 'folder' && nodes[key]?.sub_items) {
            updateNode(nodes[key]?.sub_items);
          }
        });
      };
      updateNode(updatedFiles);
      return updatedFiles;
    });
    setSelectedFiles((prevSelected) => {
      const newSelectedFiles = { ...prevSelected };
      let found = false;
      Object.keys(prevSelected)?.forEach((key) => {
        if (prevSelected[key]?.file_id === id) {
          delete newSelectedFiles[key];
          found = true;
        }
      });
      if (!found) {
        const findFile = (nodes) => {
          Object.keys(nodes)?.forEach((key) => {
            if (nodes[key]?.entry_type === 'file' && nodes[key]?.file_id === id) {
              newSelectedFiles[key] = nodes[key];
              return;
            } else if (nodes[key]?.entry_type === 'folder' && nodes[key]?.sub_items) {
              findFile(nodes[key]?.sub_items);
            }
          });
        };
        findFile(files);
      }

      return newSelectedFiles;
    });
  };

  const handleFindEntities = () => {
    const folderDetailsSet = new Set();

    const validFiles = Object.fromEntries(
      Object.entries(selectedFiles).filter(([key, file]) => file.entities_invalid_document_flag !== true)
    );

    Object.values(selectedFiles)?.forEach((file) => {
      const findFolder = (nodes, parentFolders = []) => {
        Object.keys(nodes)?.forEach((key) => {
          const node = nodes[key];

          if (node?.entry_type === 'file' && node?.file_id === file.file_id) {
            parentFolders?.forEach((folder) => {
              folderDetailsSet.add(JSON.stringify({ pk: folder.pk, sk: folder.sk }));
            });
          } else if (node?.entry_type === 'folder' && node?.sub_items) {
            findFolder(node?.sub_items, [...parentFolders, node]);
          }
        });
      };

      findFolder(files);
    });

    const folderDetails = Array.from(folderDetailsSet)?.map((item) => JSON.parse(item));

    const data = {
      file_details: validFiles,
      case_id: id,
      is_lead: type !== 'cases',
    };
    if (type === 'cases' && caseData?.case_name) {
      data.case_name = caseData?.case_name;
    } else if (type === 'leads' && caseData?.main_client_name) {
      data.main_client_name = caseData?.main_client_name;
    }
    if (folderDetails?.length > 0) {
      data.folder_details = folderDetails;
    }
    extractEntities(data)
      .then(async (response) => {
        let arr = response.data;
        setExtractedEntitiesData(arr);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const listEntities = (download = false) => {
    const obj = {
      case_id: id,
      download_flag: download ? 1 : 0,
    };
    getEntities(obj)
      .then(async (response) => {
        let arr = response?.data;
        if (!download) {
          setExtractedEntitiesData(arr);
        } else {
          const formatDate = (timestamp) => {
            return new Date(timestamp)
              .toLocaleString('en-US', {
                month: 'short',
                day: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
              })
              .replace(',', '');
          };
          const blob = new Blob([arr], { type: 'text/csv' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          const timeStamp = formatDate(Date.now());
          link.download = `entities (${timeStamp}).csv`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const extractSelectedFiles = (nodes) => {
      let selected = {};

      Object.keys(nodes)?.forEach((key) => {
        if (nodes[key]?.entry_type === 'file' && nodes[key]?.is_checked) {
          selected[key] = nodes[key];
        } else if (nodes[key]?.entry_type === 'folder' && nodes[key]?.sub_items) {
          selected = { ...selected, ...extractSelectedFiles(nodes[key]?.sub_items) };
        }
      });
      return selected;
    };
    setSelectedFiles(extractSelectedFiles(files));
  }, [files]);

  useEffect(() => {
    if (!confirmedSelection && Object.keys(selectedFiles)?.length > 0) {
      listEntities();
    }
  }, [selectedFiles]);

  useEffect(() => {
    if (
      websocketContext?.aiEntitiesData?.body &&
      websocketContext?.aiEntitiesData?.body?.entity?.task_status === 'Success' &&
      websocketContext?.aiEntitiesData?.body?.entity?.case_id === id
    ) {
      if (
        websocketContext?.aiEntitiesData?.body?.entity?.entities &&
        Object.keys(websocketContext?.aiEntitiesData?.body?.entity?.entities)?.length
      ) {
        setExtractedEntitiesData(websocketContext?.aiEntitiesData?.body?.entity);
        if (websocketContext?.aiEntitiesData?.body?.entity?.error_documents) {
          handleGetFiles();
        }
      } else {
        setExtractedEntitiesData({ message: 'No Data Found' });
      }
    } else if (
      websocketContext?.aiEntitiesData?.body &&
      websocketContext?.aiEntitiesData?.body?.entity?.task_status === 'Failed' &&
      websocketContext?.aiEntitiesData?.body?.entity?.case_id === id
    ) {
      setExtractedEntitiesData(websocketContext?.aiEntitiesData?.body?.entity);
      if (websocketContext?.aiEntitiesData?.body?.entity?.error_documents) {
        handleGetFiles();
      }
    }
  }, [websocketContext?.aiEntitiesData]);

  useEffect(() => {
    handleGetFiles();
    return () => {
      localStorage.removeItem('caseData');
    };
  }, []);

  return (
    <>
      <GlobalLoader />
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center py-2 my-3">
          <div className="pointer">
            <i
              onClick={() => {
                const url = `/${type}/${id}/files`;
                handleRedirection(history, url);
              }}
              className="icon-back icon-box me-3 pointer"
            ></i>
            <label className="sub-title">Entity Detection</label>
          </div>
        </div>
        <div className="row mb-3" style={{ maxHeight: '100%' }}>
          <div className="d-flex mt-2 align-items-center mb-0">
            <span className="d-flex p-0" style={{ color: 'rgba(0, 0, 0, 0.6)', lineHeight: '18px', fontSize: '12px' }}>
              <i className="pi pi-info-circle me-2"></i>
              AI-generated responses may contain errors or inaccuracies. You should not rely on AI responses without independent
              verification.
            </span>
          </div>
          <div className="col-lg-4 col-12 p-3" style={{ height: '100%' }}>
            <div className="d-flex">
              <div className="col-md-12 col-12 d-flex justify-content-between align-items-center">
                <h1 className="caption-bold mb-0">File Selection</h1>
                <div className="d-flex justify-content-end">
                  <Button
                    label="Find Entities"
                    className="p-button-rounded p-button-primary"
                    onClick={() => {
                      setConfirmedSelection(true);
                      handleFindEntities();
                    }}
                    disabled={
                      !(selectedFiles && Object.keys(selectedFiles)?.length) || extractedEntitiesData?.task_status === 'In Progress'
                    }
                  />
                </div>
              </div>
            </div>
            {Object.keys(files)?.length > 0 ? (
              <div className="my-3">
                <div className="col-12">
                  <div className="bg-white shadow-middle p-3 overflow-y-auto">
                    <FileSelection files={files} toggleSelect={toggleSelect} />
                  </div>
                </div>
              </div>
            ) : (
              <div className="shadow-middle p-3 bg-white mt-3 no-entity-card">
                <div className="row">
                  <div className="col-12">No Files Found</div>
                </div>
              </div>
            )}
          </div>
          <div className="col-lg-8 col-12 p-3" style={{ height: '100%', overflowY: 'auto' }}>
            <div className="d-flex">
              <div className="col-md-12 col-12 d-flex justify-content-between align-items-center">
                <h1 className="caption-bold mb-0">Entities</h1>
                <div className="d-flex justify-content-end">
                  <Button
                    label="Download"
                    className="p-button-rounded p-button-primary"
                    onClick={() => {
                      listEntities(true);
                    }}
                    disabled={
                      !(extractedEntitiesData?.entities && extractedEntitiesData?.entities?.length) ||
                      extractedEntitiesData?.task_status === 'In Progress' ||
                      !(
                        extractedEntitiesData?.entities?.filter((entity) => Object.values(entity).some((value) => value.trim() !== ''))
                          ?.length > 0
                      )
                    }
                  />
                </div>
              </div>
            </div>
            {extractedEntitiesData?.entities &&
            extractedEntitiesData?.entities?.length &&
            extractedEntitiesData?.task_status !== 'In Progress' ? (
              extractedEntitiesData?.entities?.filter((entity) => Object.values(entity).some((value) => value.trim() !== ''))?.length >
              0 ? (
                <div className="my-3">
                  <ExtractedEntitiesTable selectedFiles={selectedFiles} extractedEntitiesData={extractedEntitiesData} />
                </div>
              ) : (
                <div className="shadow-middle p-3 bg-white mt-3 no-entity-card">
                  <div className="row">
                    <div className="col-12">No Entities Found</div>
                  </div>
                </div>
              )
            ) : (
              <div className="shadow-middle p-3 bg-white mt-3 no-entity-card">
                <div className="row">
                  <div className="col-12">
                    {
                      <div className="d-flex align-items-center gap-2 justify-content-center">
                        {extractedEntitiesData?.task_status === 'In Progress' && (
                          <ProgressSpinner className="m-0" style={{ width: '20px', height: '20px' }} />
                        )}
                        {extractedEntitiesData?.message ||
                          (extractedEntitiesData?.task_status === 'Success' ? 'No Data Found' : 'Entities have not been extracted')}
                      </div>
                    }
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
