import React, { useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { getFileTypeFromMimeType, replaceLawftDate, returnFileType } from 'utils/utils';
import { useUserDetailsContext } from 'context/userDetailsContext';
import { handleDateTimeOffset } from 'utils/utility_functions/timezone';
import { getSignedUrl } from 'modules/file_manager/services';
import { useNavbarContext } from 'context/NavbarContext';
import constants from 'constants/index';
import { generateFullName } from 'utils/generateFullNameUtils';
import { SentimentDot } from 'components/SentimentDot/SentimentDot';

const messageStatus = (status) => {
  switch (status) {
    case 'delivered':
      return 'Delivered';
    case 'undelivered':
      return 'Not Delivered';
    case 'failed':
      return 'Failed';
    case 'sent':
      return 'Sent';
    case 'initiated':
      return 'Sending';
    default:
      return 'Not Delivered';
  }
};

const getUserName = (data = false) => {
  if (data && data.created_by && data.created_by.first_name) {
    return `${data.created_by.first_name} ${data.created_by.last_name}`;
  } else {
    return 'User';
  }
};

const getExtension = (file) => file?.media_url?.split('.')?.reverse()?.[0];

function Message({ selectedThread, message, index, setRowHeight }) {
  const { firmPhoneNumber, userDetails } = useUserDetailsContext();
  const { downloadFile: _download } = useNavbarContext();

  const downloadFile = async (file) => {
    try {
      if (file && file.mms_path) {
        file.file_path = file.mms_path;
        const link = await getSignedUrl(file);
        _download(link, file.display_filename);
      }
    } catch (error) {}
  };

  const myself = firmPhoneNumber === message.from;

  const rowRef = useRef({});

  useEffect(() => {
    if (rowRef.current) {
      setRowHeight(index, rowRef.current.clientHeight);
    }
  }, [rowRef]); // eslint-disable-line

  return (
    <div
      key={index}
      ref={rowRef}
      className={myself ? 'd-flex flex-wrap py-2 my-chat flex-column' : 'd-flex flex-wrap py-2 reply-chat flex-column'}
    >
      <div className="d-flex justify-content-end user-profile">
        <div className="user-dtls">
          <div className={myself ? 'F-size12 me-2 text-end' : 'F-size12 me-2'}>
            {myself ? getUserName(message) : generateFullName(selectedThread?.client_name)}
          </div>
          <div className="F-size12"></div>
        </div>
      </div>

      <div className="d-flex justify-content-end reply-body">
        <div className="border shadow-small p-2 my-1 msg-box d-flex justify-content-between me-2">
          <div className="d-flex flex-column">
            <div className="d-flex">{replaceLawftDate(message?.body, userDetails?.timezone) ?? null}</div>
            <div className="ms-auto text-message-files">
              {message?.media_url && (
                <React.Fragment>
                  <span className="icon-save download-file-icon shadow" onClick={() => downloadFile(message)}></span>
                  {getFileTypeFromMimeType(message?.MediaContentType0) === 'image' || returnFileType(getExtension(message)) === 'image' ? (
                    <img
                      src={message.media_url}
                      className="chat-image"
                      alt=""
                      width="200"
                      height="auto"
                      style={{ minHeight: 100 }}
                      onLoad={() => setRowHeight(index, rowRef.current.clientHeight)}
                    />
                  ) : getFileTypeFromMimeType(message?.MediaContentType0) === 'video' ||
                    returnFileType(getExtension(message)) === 'video' ? (
                    <video controls className="videoInsert" src={message.media_url} autoPlay={false} preload="none">
                      <source src={message.media_url} type="video/mp4" />
                      <source src={message.media_url} type="video/ogg" />
                      <source src={message.media_url} type="video/mov" />
                      <source src={message.media_url} type="video/MOV" />
                    </video>
                  ) : getFileTypeFromMimeType(message?.MediaContentType0) === 'audio' ||
                    returnFileType(getExtension(message)) === 'audio' ? (
                    <span className="message-file-container">
                      <i className="mb-1 message-file-icon fa fa-solid fa-file-audio"></i>
                      <span className="text-truncate">{` ${message.display_filename || 'Audio'}`}</span>
                    </span>
                  ) : (
                    <span className="message-file-container">
                      <i className="icon-casedocument-filled message-file-icon"></i>
                      <span className="text-truncate">{` ${message.display_filename || 'File'}`}</span>
                    </span>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
          {Boolean(message?.last_message_status === 'delivered' || message?.last_message_status === 'sent') && (
            <div className="d-flex align-items-end F-size12" style={{ marginLeft: '1rem' }}>
              {message?.last_message_status === 'delivered' && <i className="fas fa-check-double" style={{ color: 'lightgreen' }} />}
              {message?.last_message_status === 'sent' && <i className="fas fa-check" style={{ color: 'lightgreen' }} />}
            </div>
          )}
        </div>
        {message?.sentiment_analysis?.sentiment && (
          <SentimentDot tooltipPosition="top" type={message?.sentiment_analysis?.sentiment} typeOnly />
        )}
      </div>
      <p className={classNames({ 'F-size12': true, 'text-end': myself, 'mb-0': true })}>
        {handleDateTimeOffset(userDetails?.timezone, message?.time, constants.hour_minute_12_format)}{' '}
        {myself && messageStatus(message?.last_message_status)}
      </p>
    </div>
  );
}
export default React.memo(Message);
