/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { dhm, diff_hours, updateTimeEntry, deleteTimeEntry } from 'services/timerServices';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import useSpinner from 'hooks/useSpinner';
import { listCasesAndLeads } from 'services/CaseServices';
import TextSnippetWrapper from 'components/TextSnippetWrapper';
import GlobalTooltip from 'components/GlobalTooltip';

export const Timer = (props) => {
  const { timerList, setTimerList, timer, onChangeNotes, isListTimeEntry, getIndexofArrayByKeyValue, setShowTimerWidget, showTimerWidget } =
    props;
  let [time, setTime] = useState();
  let [paused, setPaused] = useState(false);
  let interval = '';
  const [confirmDelete, setConfirmDelete] = useState();
  const [loading, setLoading] = useState(false);
  const [spinner, showSpinner, hideSpinner] = useSpinner(true, true);
  const [caseList, setCaseList] = useState([]);
  const [caseAndLeadsList, setCaseAndLeadsList] = useState();

  const loadCaseAndLeadsList = (is_lead = false, case_id, exclude_pi_case) => {
    listCasesAndLeads(is_lead, case_id, exclude_pi_case)
      .then((response) => setCaseAndLeadsList(response?.data))
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (showTimerWidget) {
      loadCaseAndLeadsList();
    }
  }, [showTimerWidget]);

  // const { caseAndLeadsList } = loadCaseAndLeadsList();
  useEffect(() => {
    if (caseAndLeadsList && caseAndLeadsList?.case_list?.length) {
      let data = caseAndLeadsList?.case_list?.map((val) => {
        val.case_name =
          val && val.case_description && val.case_description.case_name
            ? `${val.unique_number ? `${val.unique_number} ` : ''}${val.case_description.case_name}`
            : 'TBD';
        return val;
      });
      setCaseList(data);
    }
  }, [caseAndLeadsList?.case_list]);

  const onChangeCase = (data, id) => {
    let index = getIndexofArrayByKeyValue(timerList, 'sk', id);
    let timerList_ = [...timerList];
    let selectedCase = caseList.filter((index) => index.case_name === data);
    timerList_[index] = {
      ...timerList_[index],
      case_name: data,
      case_id: selectedCase[0]?.case_id,
    };
    setTimerList(timerList_);
    updateTimeEntry(timerList_[index], false, '', true, true).then((response) => {});
  };

  const onFocus = () => {
    try {
      clearInterval(interval);
      //props.loadTimer();
    } catch (err) {}
  };
  useEffect(() => {
    if (timer) {
      loadTimer();
      setLoading(false);
      hideSpinner();
    }
    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    window.addEventListener('focus', onFocus);
  }, []);

  const loadTimer = () => {
    let start_time = timer.start_time;
    let end_time = timer?.end_time;
    // let time_entry_status = timer?.time_entry_status;
    let current_date = new Date();
    if (start_time && !end_time) {
      setPaused(false);
      let start_date = new Date(start_time);
      let difference = current_date - start_date;
      setTime(difference);
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 1000);
      }, 1000);
      localStorage.setItem('currentTimer', JSON.stringify(timer));
    } else if (end_time) {
      let start_date = new Date(start_time);
      let end_date = new Date(end_time);
      let difference = end_date - start_date;
      if (timer.total_times) {
        let total_times = timer.total_times.split(':');
        let h = parseInt(total_times[0]);
        let m = parseInt(total_times[1]);
        let s = parseInt(total_times[2]);
        start_time = end_date.getTime() - (h * 60 * 60 * 1000 + m * 60 * 1000 + s * 1000);
        difference = end_date.getTime() - start_time;
      }
      setTime(difference);
      setPaused(true);

      clearInterval(interval);
      hideSpinner();
    } else {
      setPaused(true);
      setTime(0);
    }
  };

  const resumeTimer = () => {
    if (timer.sk && !loading) {
      timer.end_time = '';
      if (timer.total_times) {
        let total_times = timer.total_times.split(':');
        let h = parseInt(total_times[0]);
        let m = parseInt(total_times[1]);
        let s = parseInt(total_times[2]);
        timer.start_time = new Date().getTime() - (h * 60 * 60 * 1000 + m * 60 * 1000 + s * 1000);
      }
      if (localStorage.getItem('currentTimer')) {
        setLoading(true);
        showSpinner();
        let currentTimer = JSON.parse(localStorage.getItem('currentTimer'));
        if (currentTimer.sk) {
          let start_time = currentTimer.start_time;
          let end_time = new Date();
          let duration = end_time - new Date(start_time);
          currentTimer.end_time = end_time.getTime();
          currentTimer.total_times = dhm(duration);
          currentTimer.start_time = '';
          currentTimer.total_hours = diff_hours(duration);
          updateTimeEntry(currentTimer, false, '', true, true)
            .then((response) => {
              updateTimeEntry(timer, false, '', true, true)
                .then((response) => {
                  setLoading(false);
                  hideSpinner();
                  localStorage.setItem('currentTimer', JSON.stringify(timer));
                  clearInterval(interval);
                  props.loadTimer();
                  hideSpinner();
                })
                .catch((err) => {
                  setLoading(false);
                  hideSpinner();
                });
            })
            .catch((err) => {
              setLoading(false);
              hideSpinner();
            });
        }
      } else {
        setLoading(true);
        showSpinner();
        updateTimeEntry(timer, false, '', true, true)
          .then((response) => {
            localStorage.setItem('currentTimer', JSON.stringify(timer));
            clearInterval(interval);
            props.loadTimer();

            setLoading(false);
            hideSpinner();
          })
          .catch((err) => {
            setLoading(false);
            hideSpinner();
          });
      }
    }
  };

  const pauseTimer = () => {
    if (timer.sk && !loading) {
      let start_time = timer.start_time;
      let end_time = new Date();
      let duration = end_time - new Date(start_time);
      timer.end_time = end_time.getTime();
      timer.total_times = isNaN(duration) ? '00:00:00' : dhm(duration);
      timer.start_time = '';
      timer.total_hours = isNaN(duration) ? '0' : diff_hours(duration);
      if (!isNaN(duration)) {
        clearInterval(interval);
        setLoading(true);
        showSpinner();
        updateTimeEntry(timer, false, '', true, true)
          .then((response) => {
            localStorage.removeItem('currentTimer');
            props.loadTimer();
            setLoading(false);
            hideSpinner();
          })
          .catch((err) => {
            setLoading(false);
            hideSpinner();
          });
      }
    }
  };
  const onClickEdit = () => {
    props.selectTimer(timer);
  };

  const onClickDelete = () => {
    let sk = timer.sk.replaceAll('#', '%23');
    deleteTimeEntry(sk)
      .then((response) => {
        props.loadTimer();
      })
      .catch((err) => {});
  };

  return (
    <div className="p-0">
      <ConfirmDialog
        header="Confirm Delete"
        rejectClassName="p-button-secondary outline"
        //className="confirm-delete-popup common-popup-style"
        className="case-delete-popup"
        visible={confirmDelete}
        message=" Do you want to delete this timer?"
        icon="pi pi-exclamation-triangle"
        accept={() => {
          onClickDelete();
        }}
        reject={() => {
          setConfirmDelete(false);
        }}
        onHide={() => {
          setConfirmDelete(false);
        }}
      />
      <div className="px-0 py-2">
        <div className="d-flex align-items-center m-0 py-2">
          {spinner}
          {paused && (
            <GlobalTooltip tooltip="Start" className="d-flex mx-1 cursor-pointer">
              <i
                className={showTimerWidget ? 'icon icon-play-filled F-size22' : 'icon icon-play-filled F-size16'}
                onClick={() => {
                  if (props.hideActions && props.submitTimeEntry) {
                    props.submitTimeEntry();
                  } else {
                    resumeTimer();
                  }
                }}
              />
            </GlobalTooltip>
          )}
          {!paused && (
            // <i className="fa fa-solid fa-pause ms-2" onClick={pauseTimer}></i>
            <GlobalTooltip tooltip="Pause" className="d-flex mx-1 cursor-pointer">
              <i
                className={showTimerWidget ? 'icon icon-timeStop-filled icon-red F-size25' : 'icon icon-timeStop-filled icon-red'}
                onClick={pauseTimer}
              />
            </GlobalTooltip>
          )}
          <span className={showTimerWidget ? 'F-size16' : 'F-size12'}>{dhm(time)}</span>

          {timer?.sk && !props?.hideActions && paused && (
            <>
              <div className="d-flex align-items-center w-100 justify-content-end">
                <GlobalTooltip tooltip="Edit" className="d-flex mx-1 cursor-pointer">
                  <i
                    className="icon-edit F-size16"
                    onClick={(e) => {
                      if (isListTimeEntry) setShowTimerWidget(false);
                      onClickEdit();
                    }}
                  />
                </GlobalTooltip>
                <GlobalTooltip tooltip="Delete" className="d-flex mx-1 cursor-pointer">
                  <i
                    className="icon-delete F-size16"
                    onClick={(e) => {
                      setConfirmDelete(true);
                    }}
                  />
                </GlobalTooltip>
              </div>
            </>
          )}
        </div>
        {isListTimeEntry && (
          <div className="row time-entry-wrp expand-notification border-bottom rounded-0 my-0 py-0 px-3">
            <div className="d-flex align-items-center py-2 px-0 m-0 flex-wrap">
              <div className="col-12 mb-2">
                <label className="p-medium">Case</label>
              </div>
              <div className="col-12">
                <Dropdown
                  placeholder="By Case"
                  options={caseList ? caseList : []}
                  value={timer?.case_name}
                  onChange={(e) => {
                    onChangeCase(e.value, timer?.sk);
                  }}
                  optionLabel={'case_name'}
                  optionValue={'case_name'}
                  className="w-100 input-shadow p-0"
                  filter
                />
              </div>
              <div />
            </div>
            <div className="d-flex align-items-center py-2 px-0 m-0 flex-wrap">
              <div className="col-12 mb-2">
                <label className="p-medium">Description</label>
              </div>
              <div className="col-12 m-0 p-0">
                <TextSnippetWrapper
                  type="textarea"
                  value={timer?.notes ? timer?.notes : ''}
                  onChange={(e) => {
                    onChangeNotes(e.target.value, timer?.sk);
                  }}
                  className="input-shadow w-100"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
