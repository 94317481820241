import React, { useContext } from 'react';

import { useForm } from 'react-hook-form';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import CreateEventForm from './CreateEventForm';

import { useCalendarContext } from 'modules/calendar/Context/CalendarContext';
import { useLoaderDispatch } from 'context/LoaderContext';
import { UserDetailsContext } from 'context/userDetailsContext';
import { useToast } from 'context/ToastContext';

import { addEvent, updateEvent } from 'services/Calender/calendarServices';
import { convertToTimezoneFormat } from 'utils/utility_functions/timezone';
import { uploadFiles } from 'utils/filesUtils';
import { toastConstant } from 'constants/toastmessage';
import { deleteFile } from 'services/calendarServices';
import { ModalHeader } from 'shared/ModalHeader';
import { parseRemiderPayload } from 'utils/utils';
import { useNavbarContext } from 'context/NavbarContext';
import moment from 'moment';

function CreateEventModal({ showCreateEventModal }) {
  const {
    handleSubmit,
    control,
    resetField,
    setValue,
    getValues,
    watch,
    formState: { errors },
    trigger,
  } = useForm();

  const {
    caseDetails,
    isFromCaseSpecificModule,
    isFromLeadSpecificModule,
    editEventModal,
    editAllRecurringEvent,
    getEventList,
    hideCreateEventModal,
    uploadedAttachments,
    setTempDeletedAttachments,
    tempDeletedAttachments,
    eventDetails,
    setShowUserFilter,
    setEditAllRecurringEvent,
  } = useCalendarContext();

  const { addToast } = useToast();

  const userContext = useContext(UserDetailsContext);
  const userId = userContext?.firmDetails?.current_user;
  const setLoader = useLoaderDispatch();
  const { showConfirmDialog } = useNavbarContext();

  const handleEditRecurringEvent = async (data) => {
    if (eventDetails?.is_recurring && data?.is_recurring) {
      const result = await showConfirmDialog({
        footer: {
          cancelLable: 'Cancel',
          okLable: 'Update',
        },
        formFields: [
          {
            type: 'title',
            label: 'Update Event',
          },
          {
            name: 'selection',
            type: 'radiobutton',
            label: 'Option',
            required: true,
            categories: [
              { key: 'current', label: 'This event' },
              { key: 'futures', label: 'This and following events' },
              { key: 'all', label: 'All recurring events' },
            ],
          },
        ],
      })?.catch((error) => console.error('Dialog closed or error:', error));
      if (!result) return;
      const editAll = result?.selection;
      saveCreateEvent(data, editAll);
    } else {
      saveCreateEvent(data);
    }
  };

  const saveCreateEvent = async (data, editAll = 'current') => {
    if (tempDeletedAttachments.length > 0 && editEventModal) {
      await Promise.all(tempDeletedAttachments.map((item) => deleteFileAsync(item)));
      setTempDeletedAttachments([]);
    }

    if (data?.attachments?.length > 0) {
      setLoader(true);
      setShowUserFilter(false);
      await uploadFiles(data?.attachments, 'calendar', data.case?.id, userId)
        .then((res) => {
          setLoader(false);
          data.attachments = res;
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
          return;
        });
    }

    // reminders
    data.reminder = parseRemiderPayload(data.reminder);

    data.reminder = Array.from(new Set(data?.reminder));

    // start and end time to timezone format
    data.meeting_start_time = convertToTimezoneFormat(data.meeting_start_time, userContext?.userDetails?.timezone);
    data.meeting_end_time = convertToTimezoneFormat(data.meeting_end_time, userContext?.userDetails?.timezone);
    data.when = convertToTimezoneFormat(data.when, userContext?.userDetails?.timezone);

    // case name
    data.case_name =
      isFromCaseSpecificModule || isFromLeadSpecificModule ? caseDetails?.case_description?.case_name : data.case?.case_name ?? '';

    data.case_id = isFromCaseSpecificModule || isFromLeadSpecificModule ? caseDetails?.case_id : data.case?.id;
    data.is_lead = isFromLeadSpecificModule ? isFromLeadSpecificModule : data?.case?.is_lead;
    // icon
    data.icon = data.event_type?.event_type_icon ?? '';

    // data.practice_area =
    //   isFromCaseSpecificModule || isFromLeadSpecificModule
    //     ? [caseDetails?.case?.practice_area]
    //     : data?.case?.practice_area
    //     ? [data?.case?.practice_area]
    //     : [data?.practice_area];
    if (isFromCaseSpecificModule || isFromLeadSpecificModule) {
      if (caseDetails?.case?.practice_area) {
        data.practice_area = Array.isArray(caseDetails.case.practice_area)
          ? caseDetails.case.practice_area
          : [caseDetails.case.practice_area];
      } else if (caseDetails?.case_description?.case_practice_area) {
        data.practice_area = Array.isArray(caseDetails.case_description.case_practice_area)
          ? caseDetails.case_description.case_practice_area
          : [caseDetails.case_description.case_practice_area];
      } else if (data?.case?.practice_area) {
        data.practice_area = Array.isArray(data.case.practice_area) ? data.case.practice_area : [data.case.practice_area];
      } else if (data?.practice_area) {
        data.practice_area = Array.isArray(data.practice_area) ? data.practice_area : [data.practice_area];
      }
    } else if (!isFromCaseSpecificModule && !isFromLeadSpecificModule) {
      if (data?.case?.practice_area) {
        data.practice_area = Array.isArray(data.case.practice_area) ? data.case.practice_area : [data.case.practice_area];
      } else if (data?.practice_area) {
        data.practice_area = Array.isArray(data.practice_area) ? data.practice_area : [data.practice_area];
      }
    } else {
      data.practice_area = [];
    }
    data = await appendRemainingItems(data, eventDetails);

    if (!(editEventModal && eventDetails)) {
      data.guests = [...data.contacts, ...data.firm_users];
    }
    data.recurrence_keys = data.recurrence_keys ?? {};
    if (data.is_recurring) {
      data.recurrence_keys.recurrence_limit = convertToTimezoneFormat(
        data.recurrence_keys.recurrence_limit,
        userContext?.userDetails?.timezone
      );
    }

    if (editEventModal) {
      let previousAttachments = Array.isArray(uploadedAttachments) ? uploadedAttachments : [];
      let currentAttachments = Array.isArray(data?.attachments) ? data?.attachments : [];

      const concatenatedArray = [...previousAttachments, ...currentAttachments];
      data.attachments = concatenatedArray;

      data.accessible_list.push(eventDetails?.created_by);

      let month = new Date(data.when).getMonth() + 1;
      let year = new Date(data.when).getFullYear();

      data.event_month = month;
      data.event_year = year;

      let reminderUpdate = checkReminderUpdated(data.reminder, eventDetails?.reminder);

      updateEvent({
        data,
        case_id: isFromCaseSpecificModule || isFromLeadSpecificModule ? caseDetails?.case_id : data.case?.id,
        is_lead: isFromLeadSpecificModule ? isFromLeadSpecificModule : data?.case?.is_lead,
        reminder_updated: reminderUpdate,
        send_update_notification: true,
        recurrence_scope: editAll,
        overriden_start_date: eventDetails?.meeting_start_time || '',
        isPrevEventRecurring: (eventDetails?.is_recurring && data?.is_recurring) || false,
        is_date_changed: !moment(data.when).isSame(eventDetails?.when, 'day') || false,
      })
        .then((res) => {
          // todo: update google and outlook event
          hideCreateEventModal();
          getEventList();
          addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.EVENT_UPDATE_SUCCESS);
        })
        .catch((err) => {
          console.log(err);

          let msg = toastConstant.message.EVENT_UPDATE_FAILURE;
          if (err && err.response && err.response.data) msg = err.response.data;
          addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, msg);
        });
    } else {
      if (isFromCaseSpecificModule || isFromLeadSpecificModule) {
        data.case = {
          id: caseDetails?.case_id,
          value: caseDetails?.case_description?.case_name,
          label:
            caseDetails.case_description && caseDetails.case_description.case_name
              ? `${caseDetails.unique_number ? `${caseDetails.unique_number} ` : ''}${caseDetails.case_description.case_name}`
              : 'TBD',
        };
      }

      addEvent({
        data,
        case_id: isFromCaseSpecificModule || isFromLeadSpecificModule ? caseDetails?.case_id : data.case?.id,
        is_lead: isFromLeadSpecificModule ? isFromLeadSpecificModule : data?.case?.is_lead,
      })
        .then((res) => {
          // console.log(res);
          // todo: create google and outlook event
          addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.EVENT_CREATED_SUCCESS);
          hideCreateEventModal();
          getEventList();
        })
        .catch((err) => {
          console.log(err);
          addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.EVENT_CREATE_FAILURE);
        });
    }
    // console.log('data', data);
  };

  async function deleteFileAsync(file) {
    try {
      const response = await deleteFile(file);
      // const updatedItems = uploadedAttachments.filter((item) => item !== file);
      // setUploadedAttachments(updatedItems);
    } catch (err) {
      console.error(err);
    }
  }

  const appendRemainingItems = (objA, objB) => {
    for (let key in objB) {
      if (!objA.hasOwnProperty(key)) {
        objA[key] = objB[key];
      }
    }
    return objA;
  };

  const checkReminderUpdated = (arr1, arr2) => {
    if (arr1?.length !== arr2?.length) {
      return true;
    }
    return arr1?.every((element, index) => element !== arr2[index]);
  };

  return (
    <Dialog
      visible={showCreateEventModal}
      modal
      onHide={hideCreateEventModal}
      className="new-task-popup media-popup"
      resizable={false}
      header={ModalHeader}
      footer={() => (
        <div className="footer-btn">
          <Button label="Cancel" className="btn p-button-secondary outline me-2" onClick={hideCreateEventModal} />
          <Button
            label={editEventModal ? 'Update' : 'Save'}
            type="button"
            className="p-button-secondary"
            onClick={handleSubmit(handleEditRecurringEvent)}
          />
        </div>
      )}
    >
      <CreateEventForm
        onHide={hideCreateEventModal}
        saveCreateEvent={saveCreateEvent}
        control={control}
        resetField={resetField}
        setValue={setValue}
        getValues={getValues}
        watch={watch}
        errors={errors}
        trigger={trigger}
      />
    </Dialog>
  );
}

export default CreateEventModal;
