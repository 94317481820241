/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { useSelector, useDispatch } from 'react-redux';
import { Calendar } from 'primereact/calendar';
import { getUserList } from 'redux/actions/userListAction';
import { createPaymentRequest, updatePaymentRequest } from 'services/billingServices';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';
import { listAccounts } from 'redux/actions/AccountListAction';
import { getClientUserList, listCasesAndLeads } from 'services/CaseServices';
import { InputNumber } from 'primereact/inputnumber';

import { totalCountEvent } from 'services/generalServices';
import { convertToTimezoneWithStartOfDayFormat } from 'utils/utility_functions/timezone';
import { UserDetailsContext } from 'context/userDetailsContext';
import { ModalHeader } from 'shared/ModalHeader';
import { AutoComplete } from 'primereact/autocomplete';
import debounce from 'lodash.debounce';
import TextSnippetWrapper from 'components/TextSnippetWrapper';
import { useCaseAndLeadsList } from 'hooks/useCaseAndLeadList';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

export const CreatePaymentRequest = (props) => {
  const isFormFieldDisabled = props?.selectedRequest?.payment_request_status?.toLowerCase() === 'paid';
  const [selectedCase, setSelectedCase] = useState({});
  const location = useLocation();

  const [amount, setAmount] = useState();
  const [description, setDescription] = useState();
  const [selectedTeamMember, setSelectedTeamMember] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [billingContacts, setBillingContacts] = useState([]);
  let [formError, setFormError] = useState({
    case: '',
    team_member: '',
    amount: '',
    due_date: '',
    deposit_into: '',
  });
  let [teamMembers, setTeamMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState();
  const [caseList, setCaseList] = useState([]);
  const [searchValue, setSearchValue] = useState();
  const [load, setLoad] = useState(false);
  const userList = useSelector((state) => state.userList.userList);
  const accountsList = useSelector((state) => state.accountsList.accounts.account);

  const userContext = useContext(UserDetailsContext);
  const { caseAndLeadsList } = useCaseAndLeadsList(selectedCase?.id || props?.selectedRequest?.case_id || props?.caseDetails?.case_id);

  const dispatch = useDispatch();
  const { addToast } = useToast();

  // const { caseAndLeadsList } = useCaseAndLeadsList(undefined, true);

  useEffect(() => {
    if (props?.billingSummary?.billing_info?.retainer_amount) {
      setAmount(props?.billingSummary?.billing_info?.retainer_amount);
    }
  }, [props?.billingSummary]);

  useEffect(() => {
    totalCountEvent('user')
      .then((response) => {
        dispatch(getUserList('ACTIVE', response.data, '', 'forward', '', '', false));
      })
      .catch((error) => {});
    totalCountEvent('account')
      .then((response) => {
        dispatch(listAccounts(response.data));
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    const _amount = localStorage.getItem('requestPaymentAmount');
    if (_amount) {
      setAmount(Number(_amount));
      localStorage.removeItem('requestPaymentAmount');
    }
  }, []);

  const searchCase = (e) => {
    let query = e?.query;
    setSearchValue(query);
  };

  const fetchData = useCallback((query) => {
    getAllCaseList(!props?.caseView && !props?.isLeadSpecific ? true : false, '', true, query, 15, true);
  }, []);

  const delayedSearch = useCallback(
    debounce((query) => {
      fetchData(query);
    }, 500),
    [fetchData]
  );

  useEffect(() => {
    if (searchValue !== '') {
      delayedSearch(searchValue);
    } else if (searchValue === '' && load) {
      getAllCaseList(!props?.caseView && !props?.isLeadSpecific ? true : false, '', true, searchValue, 15, true);
    }
  }, [searchValue, delayedSearch, load]);

  function getAllCaseList(
    is_lead = !props?.caseView && !props?.isLeadSpecific ? true : false,
    case_id = '',
    exclude_pi_case = true,
    keyword = '',
    limit = 15,
    is_filter_dropdown = true
  ) {
    listCasesAndLeads(is_lead, case_id, exclude_pi_case, keyword, limit, is_filter_dropdown)
      .then((res) => {
        let tempArray = [];
        res?.data?.case_list?.forEach((item) => {
          tempArray.push({
            label:
              item && item.case_description && item.case_description.case_name
                ? `${item.unique_number ? `${item.unique_number} ` : ''}${item.case_description.case_name}`
                : 'TBD',
            value: item.case_description.case_name,
            id: item?.case_id,
          });
        });
        if (!props?.caseView && !props?.isLeadSpecific) {
          res?.data?.lead_list?.forEach((item) => {
            tempArray.push({
              label:
                item && item.case_description && item.case_description.case_name
                  ? `${item.unique_number ? `${item.unique_number} ` : ''}${item.case_description.case_name}`
                  : 'TBD',
              value: item.case_description.case_name,
              id: item?.case_id,
              is_lead: item?.is_lead,
            });
          });
        }
        if (props.isLeadSpecific) {
          let caseDetails = leadSpecificCaseDetails();
          tempArray.push(caseDetails);
        }
        setCaseList(tempArray);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const accountsListFiltered = useMemo(() => {
    let filteredAccounts = [...(accountsList || [])];
    if (selectedCase?.id) {
      if (caseAndLeadsList?.case_list?.[0]?.default_trust_account?.[0]?.account_id) {
        filteredAccounts = filteredAccounts?.filter(
          (account) =>
            account?.account_id === caseAndLeadsList?.case_list?.[0]?.default_trust_account?.[0]?.account_id ||
            account?.account_type === 'operating'
        );
      } else if (caseAndLeadsList?.lead_list?.[0]?.default_trust_account?.[0]?.account_id) {
        filteredAccounts = filteredAccounts?.filter(
          (account) =>
            account?.account_id === caseAndLeadsList?.lead_list?.[0]?.default_trust_account?.[0]?.account_id ||
            account?.account_type === 'operating'
        );
      }
      if (caseAndLeadsList?.case_list?.[0]?.default_operating_account?.[0]?.account_id) {
        filteredAccounts = filteredAccounts.filter(
          (account) =>
            account?.account_id === caseAndLeadsList?.case_list?.[0]?.default_operating_account?.[0]?.account_id ||
            account?.account_type === 'trust'
        );
      } else if (caseAndLeadsList?.lead_list?.[0]?.default_operating_account?.[0]?.account_id) {
        filteredAccounts = filteredAccounts?.filter(
          (account) =>
            account?.account_id === caseAndLeadsList?.lead_list?.[0]?.default_operating_account?.[0]?.account_id ||
            account?.account_type === 'trust'
        );
      }
    }
    return filteredAccounts;
  }, [accountsList, caseAndLeadsList]);

  const leadSpecificCaseDetails = () => {
    let caseDetails = props?.caseDetails ?? {};
    caseDetails.new_case_name = caseDetails.case_description?.case_name
      ? `${caseDetails.unique_number ? `${caseDetails.unique_number} ` : ''}${caseDetails?.case_description?.case_name}`
      : 'TBD';
    return caseDetails;
  };

  // useEffect(() => {
  //   //unique number and case name is adding here
  //   if (caseAndLeadsList?.case_list && caseAndLeadsList?.case_list?.length) {
  //     let data = caseAndLeadsList?.case_list?.map((val) => {
  //       val.new_case_name = val.case_description?.case_name
  //         ? `${val.unique_number ? `${val.unique_number} ` : ''}${val.case_description.case_name}`
  //         : 'TBD';
  //       return val;
  //     });

  //     if (props.isLeadSpecific) {
  //       let caseDetails = leadSpecificCaseDetails();
  //       data.push(caseDetails);
  //     }
  //     setCaseList(data);
  //   }
  // }, [caseAndLeadsList?.case_list]);

  useEffect(() => {
    if (userList && userList.length > 0) {
      teamMembers = [];
      userList.forEach((index) => {
        let name = '';
        if (index.first_name) name += index.first_name;
        if (index.middle_name) name = name + ' ' + index.middle_name;
        if (index.last_name) name = name + ' ' + index.last_name;
        let team_member = {
          name: name,
          ...index,
        };
        teamMembers.push(team_member);
      });
      setTeamMembers([...teamMembers]);
    }
  }, [userList]);

  useEffect(() => {
    if (props.modal && !props.selectedRequest) {
      const retainerDate = location?.state?.caseDetails?.billing_preferences?.retainer_date;
      clearFormError();
      setSelectedCase();
      setSelectedTeamMember();
      setSelectedDate(retainerDate ? new Date(retainerDate) : undefined);
      setDescription();
      // setAmount();
      setSelectedAccount();
      if (props.caseView && !props.selectedRequest) {
        if (props.isLeadSpecific) {
          let caseDetails = leadSpecificCaseDetails(); //todo
          // setBillingContacts()
          // setSelectedCase(caseDetails);
          onChangeCase({ value: { id: caseDetails?.case_id, label: caseDetails?.case_description?.case_name, ...caseDetails } });
        } else {
          // let case_id = props.isLeadSpecific
          //   ? props?.caseDetails?.case_id
          //   : history.location.pathname.split("/").reverse()[0];

          let case_id = props?.caseDetails?.case_id;
          let case_label = props?.caseDetails?.case_description?.case_name;
          //case name is sete here
          // if (caseList && caseList.length > 0) {
          //   let selectedCase = caseList.filter((index) => index.case_id === case_id);

          //   onChangeCase({ value: { ...selectedCase[0] } });
          // }
          onChangeCase({ value: { id: case_id, label: case_label, ...props?.caseDetails } });
        }
      }
    } else if (props.selectedRequest) {
      try {
        clearFormError();
        setSelectedCase();
        setSelectedTeamMember();
        setSelectedDate();
        setDescription();
        setAmount();
        setSelectedAccount();
        setDescription(props.selectedRequest?.description);
        setAmount(props.selectedRequest.amount);

        if (props.selectedRequest?.due_date) setSelectedDate(new Date(props.selectedRequest?.due_date));

        let billing_contact_id = props.selectedRequest?.billing_contact_id;
        /*  let _teamMembers = teamMembers.filter(
          (index) => index.contact_id === billing_contact_id
        );
        if (_teamMembers.length > 0) setSelectedTeamMember(_teamMembers[0]); */

        let case_id = props.selectedRequest?.case_id;
        let case_label = props?.selectedRequest?.case_description?.case_name;
        // let _case_list = caseList.filter((index) => index.case_id === case_id);
        // if (_case_list.length > 0) {
        //   //setSelectedCase(_case_list[0]
        //   onChangeCase({ value: _case_list[0] }, billing_contact_id);
        // }
        onChangeCase({ value: { id: case_id, label: case_label, ...props?.selectedRequest } }, billing_contact_id);

        let deposit_account_name = props.selectedRequest?.deposit_account_name;

        let account_list = accountsList.filter((index) => index.account_name === deposit_account_name);
        if (account_list.length > 0) setSelectedAccount(account_list[0]);
      } catch (err) {
        console.log('Err', err);
      }
    }
  }, [props.modal, teamMembers, props.selectedRequest, accountsList]);

  const onSubmit = () => {
    if (validateForm()) {
      setLoading(true);
      let data = {
        case_id: selectedCase?.id,
        case_name: selectedCase?.case_description?.case_name ? selectedCase?.case_description?.case_name : selectedCase?.value,
        amount: amount,
        due_date: selectedDate ? convertToTimezoneWithStartOfDayFormat(selectedDate, userContext?.userDetails?.timezone) : '',
        description: description,
        billing_contact_id: selectedTeamMember?.contact_id,
        billing_contact_name: selectedTeamMember?.name,
        deposit_into: selectedAccount?.account_type,
        deposit_account_type: selectedAccount?.account_type,
        deposit_account_name: selectedAccount?.account_name,
        deposit_account_id: selectedAccount?.account_id,
      };
      if (props.selectedRequest) {
        data = { ...props.selectedRequest, ...data };

        updatePaymentRequest(data, props.isLeadSpecific ? props.isLeadSpecific : selectedCase?.is_lead)
          .then((response) => {
            addToast(
              false,
              toastConstant.toasterType.SUCCESS,
              toastConstant.api.SUCCESS,
              toastConstant.message.UPDATE_PAYMENT_REQUEST_SUCCESS
            );
            setLoading(false);
            props.onCreateSuccess();
          })
          .catch((err) => {
            setLoading(false);
            console.log('err', err);
            addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.UPDATE_PAYMENT_REQUEST_FAILED);
          });
      } else {
        createPaymentRequest(data, props.isLeadSpecific ? props.isLeadSpecific : selectedCase?.is_lead)
          .then((response) => {
            addToast(
              false,
              toastConstant.toasterType.SUCCESS,
              toastConstant.api.SUCCESS,
              toastConstant.message.CREATE_PAYMENT_REQUEST_SUCCESS
            );

            setLoading(false);
            props.onCreateSuccess();
          })
          .catch((err) => {
            setLoading(false);
            console.log('err', err);
            addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.CREATE_PAYMENT_REQUEST_FAILED);
          });
      }
    }
  };

  const validateForm = () => {
    let valid = true;
    let paid_amount = props.selectedRequest?.paid_amount;
    paid_amount = paid_amount ? parseInt(paid_amount) : 0;
    if (!selectedCase) {
      formError.case = 'required';
      valid = false;
    }
    if (!selectedTeamMember) {
      formError.team_member = 'required';
      valid = false;
    }
    if (!amount || (amount && amount <= 0)) {
      formError.amount = 'Required valid amount';
      valid = false;
    }
    if (paid_amount > 0 && amount < paid_amount) {
      formError.amount = 'Request amount should be greater than paid amount';
      valid = false;
    }
    if (!selectedDate) {
      formError.due_date = 'Required valid date';
      valid = false;
    }
    if (!selectedAccount) {
      formError.deposit_into = 'Required';
      valid = false;
    }
    setFormError({ ...formError });
    return valid;
  };

  const clearFormError = () => {
    formError = {
      case: '',
      team_member: '',
      rate: '',
      quantity: '',
      amount: '',
      due_date: '',
      deposit_into: '',
    };
    setFormError({ ...formError });
  };
  const selectedCaseTemplate = (option, props) => {
    if (option) {
      return (
        <div>
          <div>{option?.case_description?.case_name}</div>
        </div>
      );
    }
    return <span>{props.placeholder}</span>;
  };

  const onChangeCase = (e, billing_contact_id) => {
    setSelectedCase(e.value);
    setSelectedAccount();
    formError.case = '';
    setFormError({ ...formError });
    // console.log(e.value);
    if (e.value?.id) {
      getClientUserList(e.value.id, true, false, false, false, false, false)
        .then((response) => {
          let user_list = [];
          let selectedContact = '';
          if (response?.data && response.data.length > 0) {
            response.data.forEach((index) => {
              let name = index.first_name ? index.first_name : '';
              if (index.last_name) name = name + ' ' + index.last_name;
              let obj = {
                contact_id: index.contact_id,
                name: name,
                is_confirmed: index.is_confirmed,
              };
              if (billing_contact_id === index.contact_id) {
                selectedContact = obj;
              }
              user_list.push(obj);
            });
          }
          setBillingContacts([...user_list]);
          if (selectedContact) setSelectedTeamMember({ ...selectedContact });
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  };

  useEffect(() => {
    if (props.modal) {
      document.body.style.overflow = 'hidden';
    }
    // Clean up the effect when the component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [props.modal]); // to remove the dropdown scroll issue in dialog dropdown

  const assignToItemTemplate = (v) => {
    if (v?.is_confirmed === false) {
      return <>{`${v?.name} (Unconfirmed)`}</>;
    }
    return <>{v?.name}</>;
  };

  const checkOptionDisabled = (v) => {
    if (v?.is_confirmed === false) {
      return true;
    }
    return false;
  };

  return (
    <Dialog
      visible={props.modal}
      onHide={(e) => {
        props.setModal(false);
      }}
      className="add-contact-dialog create-msg-popup"
      header={ModalHeader}
      closable={false}
    >
      <div className="d-flex justify-content-between align-items-center mt-3 mb-3">
        <div className="modal-heading F-size16 text-bold">
          {props?.selectedRequest ? 'Update Payment Request' : 'Create Payment Request'}
        </div>
      </div>
      <div className="row mt-3 d-flex align-items-center expense-popup">
        <div className="col-12 col-md-4 p-medium">
          {!props?.caseView && !props?.isLeadSpecific ? 'Case/Lead' : 'Case'}
          <span className="text-danger">*</span>
        </div>
        <div className="col-12 col-md-8">
          <AutoComplete
            value={selectedCase?.case_name ? selectedCase?.case_name : selectedCase}
            suggestions={caseList}
            completeMethod={searchCase}
            field="label"
            onChange={(e) => {
              onChangeCase(e);
            }}
            placeholder={!props?.caseView && !props?.isLeadSpecific ? 'Case/Lead Name' : 'Case Name'}
            filter
            disabled={props.caseView}
          />
          {formError && formError.case && <span className="text-danger">Required</span>}
        </div>
        <div className="col-12 col-md-4 mt-3 p-medium">
          Amount
          <span className="text-danger">*</span>
        </div>
        <div className="col-12 col-md-8 mt-3">
          <InputNumber
            className="input-shadow w-100"
            placeholder="Amount"
            value={amount}
            onValueChange={(e) => {
              setAmount(e.target.value);
              formError.amount = '';
              setFormError({ ...formError });
            }}
            mode="currency"
            currency="USD"
            locale="en-US"
            min={1}
            disabled={isFormFieldDisabled}
          />
          {formError && formError.amount && <span className="text-danger">{formError.amount}</span>}
        </div>

        <div className="col-12 col-md-4 mt-3 p-medium">
          Due Date
          <span className="text-danger">*</span>
        </div>
        <div className="col-12 col-md-8 mt-3">
          <Calendar
            className="input-shadow w-100 border-0"
            value={selectedDate}
            onChange={(e) => {
              setSelectedDate(e.value);
              formError.due_date = '';
              setFormError({ ...formError });
            }}
            monthNavigator
            yearNavigator
            yearRange="1920:2030"
            minDate={new Date()}
            disabled={isFormFieldDisabled}
          />
          {formError && formError.due_date && <span className="text-danger">{formError.due_date}</span>}
        </div>
        <div className="col-12 col-md-4 mt-3 p-medium">
          Deposit Info
          <span className="text-danger">*</span>
        </div>
        <div className="col-12 col-md-8 mt-3 ">
          <Dropdown
            placeholder="Select"
            className="w-100"
            options={accountsListFiltered}
            onChange={(e) => {
              setSelectedAccount(e.value);
              formError.deposit_into = '';
              setFormError({ ...formError });
            }}
            optionLabel="account_name"
            filterBy="account_name"
            value={selectedAccount}
            filter
            disabled={isFormFieldDisabled}
          />
          {formError && formError.deposit_into && <span className="text-danger">{formError.deposit_into}</span>}
        </div>
        <div className="col-12 col-md-4 mt-3 p-medium">Message</div>
        <div className="col-12 col-md-8 mt-3 ">
          <TextSnippetWrapper
            type="textarea"
            className="input-shadow w-100 border-0 p-2"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            disabled={isFormFieldDisabled}
          />
        </div>
        <div className="col-12 col-md-4 mt-3 p-medium">
          Billing Contact
          <span className="text-danger">*</span>
        </div>
        <div className="col-12 col-md-8 mt-3">
          <Dropdown
            className="w-100"
            options={billingContacts}
            onChange={(e) => {
              setSelectedTeamMember(e.value);
              formError.team_member = '';
              setFormError({ ...formError });
            }}
            optionLabel="name"
            filterBy="name"
            placeholder={selectedCase ? 'Select Contact' : 'Please select case first'}
            value={selectedTeamMember}
            filter
            disabled={isFormFieldDisabled}
            itemTemplate={assignToItemTemplate}
            // optionDisabled={checkOptionDisabled}
          />
          {formError && formError.team_member && <span className="text-danger">Required</span>}
        </div>
      </div>
      <div className="footer-btn">
        <Button className="p-button-secondary outline mx-2" label="Cancel" onClick={() => props.setModal(false)} />
        <Button
          className="p-button p-button-secondary"
          label={props?.selectedExpense ? 'Update' : 'Save'}
          onClick={onSubmit}
          loading={loading}
          disabled={isFormFieldDisabled}
        />
      </div>
    </Dialog>
  );
};
