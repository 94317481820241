// import useDeletePermission from "modules/file_manager/hooks/useDeletePermission";
import React, { useState } from 'react';
import FileDownload from './FileDownload';
import PrintFile from './PrintFile';
import MenuIcon from '../../../GridView/MenuIcon';
import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';
import { Tooltip } from 'primereact/tooltip';
import { useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { FileSharingClientDetailsModal } from 'components/FileSharing/FileSharingClientDetailsModal';
import SummarizeFile from './SummarizeFile';
import CreateCourtEvent from './CreateCourtEvent';
import GlobalTooltip from 'components/GlobalTooltip';
const cookie = new Cookies();

function ActionsTemplate({ rowData, e, itemsRef }) {
  const { isClientFileManager, isLeadFileManager } = useFileManagerContext();

  const history = useHistory();
  const docTypeVal = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

  const printFileFormats = ['image/png', 'application/pdf', docTypeVal];
  const fileFormatsForSummary = ['application/pdf', 'docx', 'application/msword', docTypeVal];

  const [clientDetailsModalshow, setClientDetailsModalshow] = useState(false);

  const redirectFileDetails = (rowData) => {
    let data = {};
    let currentUser = cookie.get('userId') || null;
    data.file_pk = rowData.pk;
    data.file_sk = rowData.sk;
    data.user_id = currentUser;
    // const myObj = JSON.stringify(data);
    // let buff = Buffer.from(myObj);
    // let stringToBase64 = buff.toString('base64');
    // history.push(`/sign-document?esignature-token=${stringToBase64}`);

    let obj = {
      case_id: rowData.case_id,
      file_pk: rowData?.pk,
      file_sk: rowData?.sk,
      data: {},
      is_lead: isLeadFileManager,
    };
    history.push({
      pathname: '/sign-document',
      state: obj,
    });
  };

  return (
    <React.Fragment key={e.rowIndex}>
      <FileSharingClientDetailsModal
        show={clientDetailsModalshow}
        setShow={setClientDetailsModalshow}
        onHide={() => {
          setClientDetailsModalshow(false);
        }}
        rowData={rowData}
      />
      <span className="p-column-title text-break">Actions</span>
      <span className="text-break relative d-flex align-items-center">
        {rowData.entry_type !== 'folder' && (
          <>
            {!isClientFileManager && rowData?.is_court_setting_notice && <CreateCourtEvent rowData={rowData} />}
            {!isClientFileManager &&
              rowData?.file_type &&
              fileFormatsForSummary.includes(rowData?.file_type?.toLowerCase()) &&
              rowData?.token_count &&
              Number(rowData?.token_count) < 100000 && <SummarizeFile rowData={rowData} />}
            {!isClientFileManager && (
              <span role="button" onClick={() => setClientDetailsModalshow(true)}>
                <GlobalTooltip tooltip="Generate Link" className="bi bi-link-45deg me-3 text-primary-main">
                  <i className="copy-icon" aria-hidden="true" />
                </GlobalTooltip>
              </span>
            )}
            {isClientFileManager && rowData && rowData.esignature_status && rowData.esignature_status === 'PENDING' && (
              <span role="button" onClick={() => redirectFileDetails(rowData)}>
                <GlobalTooltip tooltip="Signature" className="me-3 text-primary-main">
                  <i className="icon-signature" aria-hidden="true" />
                </GlobalTooltip>
              </span>
            )}
            {rowData?.file_type && printFileFormats?.includes(rowData?.file_type?.toLowerCase()) ? <PrintFile rowData={rowData} /> : null}

            <FileDownload rowData={rowData} />
          </>
        )}

        {!isClientFileManager ? <MenuIcon item={rowData} index={e.rowIndex} itemsRef={itemsRef} /> : null}
      </span>
    </React.Fragment>
  );
}

export default ActionsTemplate;
