/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';

import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { confirmDialog } from 'primereact/confirmdialog';
import { TabMenu } from 'primereact/tabmenu';
import { classNames } from 'primereact/utils';

import ImageComponent from 'shared/ImageComponent';
import RelationShip from './Relationship';
import AssignTo from './AssignTo';
import CustomFields from './CustomFields';
import InputErrorMessage from 'components/UI/InputErrorMessage/InputErrorMessage';

import { countriesList, state_list } from 'constants/stateList';
import { PI_PROVIDERS_TYPE } from 'constants/index';
import { titles } from 'common/dropdownOptions';
import { generateFullName } from 'utils/generateFullNameUtils';
import { doesObjectHaveValue, getNestedProperty } from 'utils/utils';
import { contactDetails } from 'services/contact';
import { listContacts } from 'services/lead/leadServices';
import { validatePhoneNumber } from 'modules/lead/helpers/helpers';
import { emailDuplicateCheck } from 'services/Contact/contactServices';
import { useUserDetailsContext } from 'context/userDetailsContext';
import { Tooltip } from 'primereact/tooltip';
import TextSnippetWrapper from 'components/TextSnippetWrapper';

const defaultFields = {
  first_name: '',
  middle_name: '',
  last_name: '',
  email: '',
  street: '',
  city: '',
  state: '',
  zip: '',
  phone: '',
  additional_phone: '',
  title: '',
  custom_field: [],
  custom_field_toggle: '',
};

const NewContactFormLead = (props) => {
  const {
    register,
    formState: { errors, defaultValues },
    customFields,
    trigger,
    setValue,
    control,
    watch,
    reset,
    getValues,
    businessRole,
    relationRole,
  } = props;

  const { currentTabIndex, setCurrentTabIndex, selectedContact, setSelectedContact, duplicateContact, setDuplicateContact } = props;

  const userContext = useUserDetailsContext();

  const [duplicateSearchNumber, setDuplicateSearchNumber] = useState(
    selectedContact?.phone?.replace(/[^0-9.]/g, '')?.substring(0, 10) ?? ''
  );
  const [hasConfirmedTabChange, setHasConfirmedTabChange] = useState(false);
  const conatctCustomFields = customFields?.filter((v) => v.module === 'contact');

  const searchDuplicateContact = async (e) => {
    let formattedNumber = e.target.value?.replace(/[^0-9.]/g, '')?.substring(0, 10);
    if (formattedNumber?.length === 10) {
      try {
        const contact = await listContacts({ keyWord: formattedNumber, pageLimit: 2 });
        setSelectedContact({ ...contact?.data?.contacts?.[0] });
      } catch (error) {
        console.log(error);
      }
    }
    setDuplicateSearchNumber(formattedNumber);
  };

  const dismissSearch = (event) => {
    event?.preventDefault();
    setSelectedContact({});
    setDuplicateSearchNumber('');
  };

  const onTabChange = (e) => {
    const formData = getValues();
    const { custom_field, custom_field_toggle, is_client_opt_in, ...rest_contact } = formData?.contact || {};
    const { custom_field: a, custom_field_toggle: b, is_client_opt_in: c, ...rest_client } = formData?.client || {};
    if (doesObjectHaveValue(rest_contact || {}) || doesObjectHaveValue(rest_client || {})) {
      // confirmTabChange(e.index);
      if (hasConfirmedTabChange) {
        setCurrentTabIndex(e.index);
      } else {
        confirmTabChange(e.index);
      }
    } else {
      setCurrentTabIndex(e.index);
      reset({
        ...formData,
        contact: { ...defaultFields, custom_field: conatctCustomFields },
        client: { ...defaultFields, custom_field: conatctCustomFields },
      });
      setDuplicateSearchNumber('');
      setSelectedContact({});
      setDuplicateContact('');
    }
  };

  const confirmTabChange = (index) => {
    if (selectedContact) {
      confirmDialog({
        className: 'common-popup-style',
        icon: 'pi pi-exclamation-triangle',
        header: 'Confirm To Retain Form Data',
        acceptLabel: 'Confirm',
        rejectLabel: 'Cancel',
        // message:
        //   index === 1
        //     ? 'Client details will be retained to contact details on change, do you want to proceed?'
        //     : 'Contact details will be retained to client details on change, do you want to proceed?',
        message: 'Switching between the Lead and On Behalf of tabs while adding a lead will move the contact information over.',
        accept: () => {
          setHasConfirmedTabChange(true);
          setCurrentTabIndex(index);
          // const formData = getValues();
          // reset({
          //   ...formData,
          //   contact: { ...defaultFields, custom_field: conatctCustomFields },
          //   client: { ...defaultFields, custom_field: conatctCustomFields },
          // });
          // setDuplicateSearchNumber('');
          // setSelectedContact({});
          // setDuplicateContact('');
        },
        onHide: () => {},
        reject: () => {},
      });
    }
  };

  const getContactDetails = (type) => {
    contactDetails(selectedContact?.sk, selectedContact?.pk)
      .then((res) => {
        setValue('relations', res?.data?.relations);
        setValue(type === 'contact' ? 'contact.custom_field' : 'client.custom_field', res?.data?.custom_field);
      })
      .catch((err) => console.error(err));
  };

  const applyToLeadContact = (event) => {
    event?.preventDefault();
    setDuplicateContact('lead_contact');
    const formData = getValues();
    reset({
      ...formData,
      contact: { ...selectedContact, dob: selectedContact?.dob ? new Date(selectedContact.dob) : '', isOld: true },
      client: { ...defaultFields, custom_field: conatctCustomFields },
    });
    getContactDetails('contact');
  };

  const applyToBehalfContact = (event) => {
    event?.preventDefault();
    setDuplicateContact('behalf_contact');
    const formData = getValues();
    reset({
      ...formData,
      contact: { ...selectedContact, dob: selectedContact?.dob ? new Date(selectedContact.dob) : '', isOld: true },
      client: { ...defaultFields, custom_field: conatctCustomFields },
    });
    getContactDetails('contact');
  };

  const applyToBehalfClient = (event) => {
    event?.preventDefault();
    setDuplicateContact('behalf_client');
    const formData = getValues();
    reset({
      ...formData,
      client: { ...selectedContact, dob: selectedContact?.dob ? new Date(selectedContact.dob) : '', isOld: true },
      contact: { ...defaultFields, custom_field: conatctCustomFields },
    });
    getContactDetails('client');
  };

  const dismissAppliedContact = (event) => {
    event?.preventDefault();
    const formData = getValues();
    reset({
      ...formData,
      contact: { ...defaultFields, custom_field: conatctCustomFields },
      client: { ...defaultFields, custom_field: conatctCustomFields },
    });
    setDuplicateContact('');
  };

  const validatePhone = async (value, e, type) => {
    if (!Boolean(value)) {
      return true;
    }
    if (type === 'contact' && (duplicateContact === 'behalf_contact' || duplicateContact === 'lead_contact')) {
      return true;
    }
    if (type === 'client' && duplicateContact === 'behalf_client') {
      return true;
    }
    return validatePhoneNumber(value, null);
  };

  const emailValidation = async (value, e, type) => {
    if (!Boolean(value)) {
      return true;
    }
    if (type === 'contact' && (duplicateContact === 'behalf_contact' || duplicateContact === 'lead_contact')) {
      return true;
    }
    if (type === 'client' && duplicateContact === 'behalf_client') {
      return true;
    }
    try {
      await emailDuplicateCheck(value, null);
      return true;
    } catch (error) {
      return 'Email already exists';
    }
  };

  const RequiredValidation = ({ field, message = 'Field Required' }) => {
    return getNestedProperty(errors, field) ? (
      <InputErrorMessage>{getNestedProperty(errors, field)?.message || message}</InputErrorMessage>
    ) : null;
  };

  const contactFieldDisable = duplicateContact === 'behalf_contact' || duplicateContact === 'lead_contact';
  const clientFieldDisable = duplicateContact === 'behalf_client';

  const oldContact = defaultValues?.contact?.isOld;
  const oldClientContact = defaultValues?.client?.isOld;

  useEffect(() => {
    if (currentTabIndex === 0 && duplicateContact === 'behalf_contact') {
      setDuplicateContact('lead_contact');
    } else if (currentTabIndex === 1 && duplicateContact === 'lead_contact') {
      setDuplicateContact('behalf_contact');
    }
  }, [currentTabIndex]);

  return (
    <React.Fragment>
      <div className="row mt-3">
        {/* <div className="auto-width">
          <div className="text-bold pb-2">DUPLICATE DETECTION</div>
          <div className="pb-2">Enter phone number to find possible duplicates in the system</div>
          <div className="d-flex border input-shadow bg-white rounded p-3 align-items-center auto-width mb-4 flex-wrap">
            <NumberFormat
              className="input-shadow input-search py-2 type-in  border-0 number-format"
              placeholder="Type in"
              value={duplicateSearchNumber}
              onChange={searchDuplicateContact}
              format="(###) ###-####"
              mask="_"
              // disabled={Boolean(duplicateContact)}
            />

            {duplicateSearchNumber.length == 10 && Object.keys(selectedContact).length ? (
              <div className="d-flex align-items-center ms-md-3 ms-0 pt-3 pt-md-0">
                <ImageComponent
                  filePath={selectedContact?.profile_image}
                  fileName={selectedContact?.first_name || selectedContact?.title}
                  fileSize="small.jpg"
                />
                <div>{generateFullName(selectedContact)}</div>

                {currentTabIndex === 0 && (
                  <>
                    {duplicateContact === 'lead_contact' ? (
                      <Button
                        className="ms-2 bg-btn p-button-secondary outline mt-3 mt-md-0"
                        label="Dismiss and create new contact"
                        onClick={dismissAppliedContact}
                      />
                    ) : (
                      <Button className="ms-md-5 ms-2 p-button p-button-secondary" label="Apply Data" onClick={applyToLeadContact} />
                    )}
                  </>
                )}

                {currentTabIndex === 1 && (
                  <>
                    {duplicateContact === 'behalf_contact' ? (
                      <Button
                        className="ms-2 bg-btn p-button-secondary outline mt-3 mt-md-0"
                        label="Dismiss and create new contact"
                        onClick={dismissAppliedContact}
                      />
                    ) : (
                      <Button className="ms-3 p-button p-button-secondary" label="Apply Data To Contact" onClick={applyToBehalfContact} />
                    )}

                    {duplicateContact === 'behalf_client' ? (
                      <Button
                        className="ms-1 bg-btn p-button-secondary outline"
                        label="Dismiss and create new client"
                        onClick={dismissAppliedContact}
                      />
                    ) : (
                      <Button className="ms-3 p-button p-button-secondary" label="Apply Data To Client" onClick={applyToBehalfClient} />
                    )}
                  </>
                )}
                {duplicateContact === '' && (
                  <Button className="ms-1 bg-btn p-button-secondary outline" label="Dismiss" onClick={dismissSearch} />
                )}
              </div>
            ) : null}

            {duplicateSearchNumber.length === 10 && Object.keys(selectedContact).length == 0 && (
              <span className="text-muted ms-2">No match found</span>
            )}
          </div>
        </div> */}
      </div>
      <div className="custom-fields mt-2">
        <form>
          <TabMenu
            model={[{ label: 'Lead' }, { label: 'On Behalf' }]}
            className="mb-3"
            activeIndex={currentTabIndex}
            onTabChange={onTabChange}
          />
          <div className="auto-width">
            <div className="text-bold pb-2">DUPLICATE DETECTION</div>
            <div className="pb-2">Enter phone number to find possible duplicates in the system</div>
            <div className="d-flex border input-shadow bg-white rounded p-3 align-items-center auto-width mb-4 flex-wrap">
              <NumberFormat
                className="input-shadow input-search py-2 type-in  border-0 number-format"
                placeholder="Type in"
                value={duplicateSearchNumber}
                onChange={searchDuplicateContact}
                format="(###) ###-####"
                mask="_"
                // disabled={Boolean(duplicateContact)}
              />

              {duplicateSearchNumber.length == 10 && Object.keys(selectedContact).length ? (
                <div className="d-flex align-items-center ms-md-3 ms-0 pt-3 pt-md-0">
                  <ImageComponent
                    filePath={selectedContact?.profile_image}
                    fileName={selectedContact?.first_name || selectedContact?.title}
                    fileSize="small.jpg"
                  />
                  <div>{generateFullName(selectedContact)}</div>

                  {currentTabIndex === 0 && (
                    <>
                      {duplicateContact === 'lead_contact' ? (
                        <Button
                          className="ms-2 bg-btn p-button-secondary outline mt-3 mt-md-0"
                          label="Dismiss and create new contact"
                          onClick={(e) => dismissAppliedContact(e)}
                        />
                      ) : (
                        <Button className="ms-md-5 ms-2 p-button p-button-secondary" label="Apply Data" onClick={applyToLeadContact} />
                      )}
                    </>
                  )}

                  {currentTabIndex === 1 && (
                    <>
                      {duplicateContact === 'behalf_contact' ? (
                        <Button
                          className="ms-2 bg-btn p-button-secondary outline mt-3 mt-md-0"
                          label="Dismiss and create new contact"
                          onClick={(e) => dismissAppliedContact(e)}
                        />
                      ) : (
                        <Button className="ms-3 p-button p-button-secondary" label="Apply Data To Contact" onClick={applyToBehalfContact} />
                      )}

                      {duplicateContact === 'behalf_client' ? (
                        <Button
                          className="ms-1 bg-btn p-button-secondary outline"
                          label="Dismiss and create new client"
                          onClick={(e) => dismissAppliedContact(e)}
                        />
                      ) : (
                        <Button className="ms-3 p-button p-button-secondary" label="Apply Data To Client" onClick={applyToBehalfClient} />
                      )}
                    </>
                  )}
                  {duplicateContact === '' && (
                    <Button className="ms-1 bg-btn p-button-secondary outline" label="Dismiss" onClick={dismissSearch} />
                  )}
                </div>
              ) : null}

              {duplicateSearchNumber.length === 10 && Object.keys(selectedContact).length == 0 && (
                <span className="text-muted ms-2">No match found</span>
              )}
            </div>
          </div>
          {currentTabIndex === 0 && (
            <div>
              <h5 className="caption-bold">PERSONAL INFORMATION</h5>
              <div className="shadow-middle bg-white lead-data-box p-3">
                <div className="row">
                  <div className="col-lg-6 col-12 ps-lg-4 ps-0 pe-0">
                    <div className="col-md-6 col-12 p-medium ps-3 ps-lg-0">
                      <span>Client Details</span>
                    </div>
                    <hr></hr>
                    <div className="pe-3 ps-lg-0 ps-3">
                      <div className="d-flex align-items-center py-2 flex-wrap">
                        <div className="col-md-4 col-12 p-medium">
                          Title
                          <span className="danger-text"> *</span>
                        </div>
                        <div className="col-md-8 col-12">
                          {defaultValues?.contact?.contact_type === 'business' ||
                          PI_PROVIDERS_TYPE.includes(defaultValues?.contact?.contact_type) ? (
                            <Controller
                              name="contact.title"
                              control={control}
                              rules={{ required: 'Title is required.' }}
                              render={({ field }) => (
                                <InputText
                                  disabled
                                  placeholder="Select"
                                  id={field.name}
                                  {...field}
                                  className="w-100 new-task-popup input-shadow"
                                />
                              )}
                            />
                          ) : (
                            <Controller
                              name="contact.title"
                              control={control}
                              rules={{ required: 'Title is Required' }}
                              render={({ field }) => (
                                <Dropdown
                                  id={field.name}
                                  inputRef={field.ref}
                                  options={titles}
                                  className="w-100"
                                  onChange={(e) => field.onChange(e.value)}
                                  value={field.value}
                                  placeholder="Select"
                                  disabled={contactFieldDisable}
                                />
                              )}
                            />
                          )}
                          <RequiredValidation field={'contact.title'} />
                        </div>
                      </div>

                      <div className="d-flex align-items-center py-2 flex-wrap">
                        <div className="col-md-4 col-12 p-medium">
                          First Name
                          <span className="danger-text"> *</span>
                        </div>
                        <div className="col-md-8 col-12">
                          <Controller
                            name="contact.first_name"
                            control={control}
                            rules={{ required: oldContact ? false : 'First Name is required.' }}
                            render={({ field, fieldState }) => (
                              <TextSnippetWrapper
                                type="text"
                                disabled={contactFieldDisable}
                                placeholder="Type in"
                                id={field.name}
                                {...field}
                                className={classNames({ 'p-invalid': fieldState.invalid, 'w-100 new-task-popup input-shadow': true })}
                              />
                            )}
                          />
                          <RequiredValidation field={'contact.first_name'} />
                        </div>
                      </div>
                      <div className="d-flex align-items-center py-2 flex-wrap">
                        <div className="col-md-4 col-12 p-medium">Middle Name</div>
                        <div className="col-md-8 col-12">
                          <Controller
                            name="contact.middle_name"
                            control={control}
                            render={({ field }) => (
                              <TextSnippetWrapper
                                type="text"
                                disabled={contactFieldDisable}
                                placeholder="Type in"
                                id={field.name}
                                {...field}
                                className="w-100 new-task-popup input-shadow"
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center py-2 flex-wrap">
                        <div className="col-md-4 col-12 p-medium">
                          Last Name
                          <span className="danger-text"> *</span>
                        </div>
                        <div className="col-md-8 col-12">
                          <Controller
                            name="contact.last_name"
                            control={control}
                            rules={{ required: oldContact ? false : 'Last Name is required.' }}
                            render={({ field, fieldState }) => (
                              <TextSnippetWrapper
                                type="text"
                                disabled={contactFieldDisable}
                                placeholder="Type in"
                                id={field.name}
                                {...field}
                                className={classNames({ 'p-invalid': fieldState.invalid, 'w-100 new-task-popup input-shadow': true })}
                              />
                            )}
                          />
                          <RequiredValidation field={'contact.last_name'} />
                        </div>
                      </div>

                      <div className="d-flex align-items-center py-2 flex-wrap">
                        <div className="col-md-4 col-12 p-medium">Date Of Birth</div>
                        <div className="col-md-8 col-12">
                          <Controller
                            name="contact.dob"
                            control={control}
                            render={({ field }) => (
                              <Calendar
                                placeholder="Select"
                                className="w-100 new-task-popup input-shadow"
                                monthNavigator
                                yearNavigator
                                yearRange="1920:3000"
                                id={field.name}
                                value={field.value ? new Date(field.value) : null}
                                onChange={(e) => field.onChange(e.value)}
                                maxDate={new Date()}
                                disabled={contactFieldDisable}
                              />
                            )}
                          />
                        </div>
                      </div>

                      <div className="d-flex align-items-center py-2 flex-wrap">
                        <div className="col-md-4 col-12 p-medium">Phone Number</div>
                        <div className="col-md-8 col-12">
                          <Controller
                            name="contact.phone"
                            control={control}
                            rules={{
                              validate: { asyncValidation: (v, e) => validatePhone(v, e, 'contact') },
                              minLength: { value: 10, message: 'Min length should be 10' },
                            }}
                            render={({ field }) => (
                              <NumberFormat
                                className="input-shadow w-100 p-2 border-0 number-format"
                                placeholder="Type in"
                                format="(###) ###-####"
                                mask="_"
                                name="phone"
                                keyfilter={'pnum'}
                                onValueChange={(e) => setValue('contact.phone', e.value)}
                                value={field.value}
                                disabled={contactFieldDisable}
                                onBlur={() => trigger('contact.phone')}
                              />
                            )}
                          />
                          <RequiredValidation field={'contact.phone'} />
                        </div>
                      </div>
                      <div className="d-flex align-items-center py-2 flex-wrap">
                        <div className="col-12 col-lg-4 p-medium"></div>
                        <div className="col-12 col-lg-8">
                          <div className="d-flex">
                            <Controller
                              name="contact.is_client_opt_in"
                              control={control}
                              defaultValue={true}
                              render={({ field }) => (
                                <InputSwitch
                                  className="input-swich"
                                  checked={field.value}
                                  name={field.name}
                                  value={field.value}
                                  onChange={(e) => {
                                    field.onChange(e.value);
                                  }}
                                />
                              )}
                            />
                            <div className="F-size12 ms-2 d-flex align-items-center black-600">
                              Client has opted in to recieve text messages
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center py-2 flex-wrap">
                        <div className="col-md-4 col-12 p-medium">Additional Number</div>
                        <div className="col-md-8 col-12">
                          <Controller
                            name="contact.additional_phone"
                            control={control}
                            rules={{ minLength: { value: 10, message: 'Min length should be 10' } }}
                            render={({ field }) => (
                              <NumberFormat
                                className="input-shadow w-100 p-2 border-0 number-format"
                                placeholder="Type in"
                                format="(###) ###-####"
                                mask="_"
                                name="phone"
                                keyfilter={'pnum'}
                                onValueChange={(e) => field.onChange(e.value)}
                                value={field.value}
                                disabled={contactFieldDisable}
                              />
                            )}
                          />
                          <RequiredValidation field={'contact.additional_phone'} />
                        </div>
                      </div>
                      <div className="d-flex align-items-center py-2 flex-wrap">
                        <div className="col-md-4 col-12 p-medium">
                          Email {watch('contact.is_create_client_portal') && <span className="danger-text"> *</span>}
                        </div>
                        <div className="col-md-8 col-12">
                          <Controller
                            name="contact.email"
                            control={control}
                            rules={{
                              required: watch('contact.is_create_client_portal') && !!oldContact === false ? 'Email is required.' : false,
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: 'Entered value does not match email format',
                              },
                              validate: { asyncValidation: (v, e) => emailValidation(v, e, 'contact') },
                            }}
                            render={({ field, fieldState }) => (
                              <InputText
                                disabled={contactFieldDisable}
                                placeholder="Type in"
                                id={field.name}
                                {...field}
                                onChange={(e) => {
                                  setValue('contact.email', e.target.value);
                                  if (!e.target.value) {
                                    setValue('contact.is_create_client_portal', false);
                                  }
                                }}
                                onBlur={() => trigger('contact.email')}
                                className={classNames({ 'p-invalid': fieldState.invalid, 'w-100 new-task-popup input-shadow': true })}
                              />
                            )}
                          />
                          <RequiredValidation field={'contact.email'} />
                        </div>
                      </div>
                      <div className="d-flex align-items-center py-2 flex-wrap">
                        <div className="col-md-4 col-12 p-medium">Enable Lawft Access</div>
                        <div className="col-md-8 col-12 d-flex">
                          <Controller
                            name="contact.is_create_client_portal"
                            control={control}
                            render={({ field }) => (
                              <>
                                <div id="detail_lawft_access">
                                  <InputSwitch
                                    disabled={!watch('contact.email')}
                                    className="input-shadow input-swich"
                                    inputId={field.name}
                                    onChange={(e) => field.onChange(e.value)}
                                    checked={field.value}
                                  />
                                  {!watch('contact.email') ? (
                                    <Tooltip target="#detail_lawft_access" position="right" className="case-tooltip">
                                      {'Add Email To Enable Lawft Access'}
                                    </Tooltip>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-12 ps-0 pe-0 pe-lg-4 mt-lg-0 mt-4">
                    <div className="col-md-6 col-12 p-medium ps-3">
                      <span>Address Details</span>
                    </div>
                    <hr></hr>
                    <div className="ps-3 pe-lg-0 pe-3">
                      <div className="d-flex align-items-center py-2 flex-wrap">
                        <div className="col-md-4 col-12 p-medium">Street</div>
                        <div className="col-md-8 col-12">
                          <Controller
                            name="contact.street"
                            control={control}
                            render={({ field }) => (
                              <TextSnippetWrapper
                                type="text"
                                disabled={contactFieldDisable}
                                placeholder="Type in"
                                id={field.name}
                                {...field}
                                className="w-100 new-task-popup input-shadow"
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center py-2 flex-wrap">
                        <div className="col-md-4 col-12 p-medium">City</div>
                        <div className="col-md-8 col-12">
                          <Controller
                            name="contact.city"
                            control={control}
                            render={({ field }) => (
                              <TextSnippetWrapper
                                type="text"
                                disabled={contactFieldDisable}
                                placeholder="Type in"
                                id={field.name}
                                {...field}
                                className="w-100 new-task-popup input-shadow"
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center py-2 flex-wrap">
                        <div className="col-md-4 col-12 p-medium">State</div>
                        <div className="col-md-8 col-12">
                          <Controller
                            name="contact.state"
                            control={control}
                            render={({ field }) => (
                              <Dropdown
                                id={field.name}
                                options={state_list}
                                className="w-100"
                                onChange={(e) => field.onChange(e.value)}
                                value={field.value}
                                placeholder="Select"
                                optionLabel="label"
                                optionValue="value"
                                disabled={contactFieldDisable}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center py-2 flex-wrap">
                        <div className="col-md-4 col-12 p-medium">Zip Code</div>
                        <div className="col-md-8 col-12">
                          <Controller
                            name="contact.zip"
                            control={control}
                            render={({ field }) => (
                              <InputText
                                disabled={contactFieldDisable}
                                keyfilter={'pnum'}
                                maxLength={5}
                                placeholder="Type in"
                                id={field.name}
                                {...field}
                                className="w-100 new-task-popup input-shadow"
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center py-2 flex-wrap">
                        <div className="col-md-4 col-12 p-medium">Country</div>
                        <div className="col-md-8 col-12">
                          <Controller
                            name="contact.country"
                            control={control}
                            render={({ field }) => (
                              <Dropdown
                                id={field.name}
                                options={countriesList}
                                className="w-100"
                                onChange={(e) => field.onChange(e.value)}
                                value={'United States'}
                                placeholder="Select"
                                optionLabel="name"
                                optionValue="name"
                                disabled
                              />
                            )}
                          />
                        </div>
                      </div>

                      {userContext.checkSubPermission('custom_fields') && (
                        <div className="d-flex align-items-center py-2 flex-wrap">
                          <div className="col-sm-6 d-flex addfield">
                            <Controller
                              name="contact.custom_field_toggle"
                              control={control}
                              defaultValue={false}
                              render={({ field }) => (
                                <Button
                                  disabled={contactFieldDisable}
                                  id={field.name}
                                  type="button"
                                  className="p-button button-text text-medium addfield ps-0"
                                  onClick={(e) => field.onChange(!field.value)}
                                >
                                  <i className={field?.value ? 'pi pi-minus F-size14 me-1' : 'pi pi-plus F-size14 me-1'}></i>Add Custom
                                  Fields
                                </Button>
                              )}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    {userContext.checkSubPermission('custom_fields') && watch('contact.custom_field_toggle') && (
                      <CustomFields field="contact.custom_field" control={control} setValue={setValue} fieldDisable={contactFieldDisable} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {currentTabIndex === 1 && (
            <div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="shadow-middle bg-white lead-data-box p-3">
                        <div className="row">
                          <div className="col-sm-12 p-medium">
                            <span>Contact Details</span>
                            <hr></hr>
                          </div>
                        </div>
                        <div className="d-flex align-items-center py-2 flex-wrap">
                          <div className="col-md-4 col-12 p-medium">
                            Title
                            <span className="danger-text"> *</span>
                          </div>
                          <div className="col-md-8 col-12">
                            {defaultValues?.contact?.contact_type === 'business' ||
                            PI_PROVIDERS_TYPE.includes(defaultValues?.contact?.contact_type) ? (
                              <Controller
                                name="contact.title"
                                control={control}
                                rules={{ required: 'Title is required.' }}
                                render={({ field }) => (
                                  <InputText
                                    disabled
                                    placeholder="Select"
                                    id={field.name}
                                    {...field}
                                    className="w-100 new-task-popup input-shadow"
                                  />
                                )}
                              />
                            ) : (
                              <Controller
                                name="contact.title"
                                control={control}
                                rules={{ required: 'Title is Required' }}
                                render={({ field }) => (
                                  <Dropdown
                                    id={field.name}
                                    inputRef={field.ref}
                                    options={titles}
                                    className="w-100"
                                    onChange={(e) => field.onChange(e.value)}
                                    value={field.value}
                                    placeholder="Select"
                                    disabled={contactFieldDisable}
                                  />
                                )}
                              />
                            )}
                            <RequiredValidation field={'contact.title'} />
                          </div>
                        </div>
                        <div className="d-flex align-items-center py-2 flex-wrap">
                          <div className="col-md-4 col-12 p-medium">
                            First Name
                            <span className="danger-text"> *</span>
                          </div>
                          <div className="col-md-8 col-12">
                            <Controller
                              name="contact.first_name"
                              control={control}
                              rules={{ required: oldContact ? false : 'First Name is required.' }}
                              render={({ field, fieldState }) => (
                                <TextSnippetWrapper
                                  type="text"
                                  disabled={contactFieldDisable}
                                  placeholder="Type in"
                                  id={field.name}
                                  {...field}
                                  className={classNames({ 'p-invalid': fieldState.invalid, 'w-100 new-task-popup input-shadow': true })}
                                />
                              )}
                            />
                            <RequiredValidation field={'contact.first_name'} />
                          </div>
                        </div>
                        <div className="d-flex align-items-center py-2 flex-wrap">
                          <div className="col-md-4 col-12 p-medium">Middle Name</div>
                          <div className="col-md-8 col-12">
                            <Controller
                              name="contact.middle_name"
                              control={control}
                              render={({ field }) => (
                                <TextSnippetWrapper
                                  type="text"
                                  disabled={contactFieldDisable}
                                  placeholder="Type in"
                                  id={field.name}
                                  {...field}
                                  className="w-100 new-task-popup input-shadow"
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="d-flex align-items-center py-2 flex-wrap">
                          <div className="col-md-4 col-12 p-medium">
                            Last Name
                            <span className="danger-text"> *</span>
                          </div>
                          <div className="col-md-8 col-12">
                            <Controller
                              name="contact.last_name"
                              control={control}
                              rules={{ required: oldContact ? false : 'Last Name is required.' }}
                              render={({ field, fieldState }) => (
                                <TextSnippetWrapper
                                  type="text"
                                  disabled={contactFieldDisable}
                                  placeholder="Type in"
                                  id={field.name}
                                  {...field}
                                  className={classNames({ 'p-invalid': fieldState.invalid, 'w-100 new-task-popup input-shadow': true })}
                                />
                              )}
                            />
                            <RequiredValidation field={'contact.last_name'} />
                          </div>
                        </div>
                        <div className="d-flex align-items-center py-2 flex-wrap">
                          <div className="col-md-4 col-12 p-medium">Date Of Birth</div>
                          <div className="col-md-8 col-12">
                            <Controller
                              name="contact.dob"
                              control={control}
                              render={({ field }) => (
                                <Calendar
                                  placeholder="Select"
                                  className="w-100 new-task-popup input-shadow"
                                  monthNavigator
                                  yearNavigator
                                  yearRange="1920:3000"
                                  id={field.name}
                                  value={field.value ? new Date(field.value) : null}
                                  onChange={(e) => field.onChange(e.value)}
                                  maxDate={new Date()}
                                  disabled={contactFieldDisable}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="d-flex align-items-center py-2 flex-wrap">
                          <div className="col-md-4 col-12 p-medium">Phone Number</div>
                          <div className="col-md-8 col-12">
                            <Controller
                              name="contact.phone"
                              control={control}
                              rules={{
                                validate: { asyncValidation: (v, e) => validatePhone(v, e, 'contact') },
                                minLength: { value: 10, message: 'Min length should be 10' },
                              }}
                              render={({ field }) => (
                                <NumberFormat
                                  className="input-shadow w-100 p-2 border-0 number-format"
                                  placeholder="Type in"
                                  format="(###) ###-####"
                                  mask="_"
                                  name="phone"
                                  keyfilter={'pnum'}
                                  onValueChange={(e) => setValue('contact.phone', e.value)}
                                  value={field.value}
                                  disabled={contactFieldDisable}
                                  onBlur={() => trigger('contact.phone')}
                                />
                              )}
                            />
                            <RequiredValidation field={'contact.phone'} />
                          </div>
                        </div>
                        <div className="d-flex align-items-center py-2 flex-wrap">
                          <div className="col-12 col-lg-4 p-medium"></div>
                          <div className="col-12 col-lg-8">
                            <div className="d-flex">
                              <Controller
                                name="contact.is_client_opt_in"
                                control={control}
                                defaultValue={true}
                                render={({ field }) => (
                                  <InputSwitch
                                    className="input-swich"
                                    checked={field.value}
                                    name={field.name}
                                    value={field.value}
                                    onChange={(e) => {
                                      field.onChange(e.value);
                                    }}
                                  />
                                )}
                              />
                              <div className="F-size12 ms-2 d-flex align-items-center black-600">
                                Client has opted in to recieve text messages
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center py-2 flex-wrap">
                          <div className="col-md-4 col-12 p-medium">Additional Number</div>
                          <div className="col-md-8 col-12">
                            <Controller
                              name="contact.additional_phone"
                              control={control}
                              rules={{ minLength: { value: 10, message: 'Min length should be 10' } }}
                              render={({ field }) => (
                                <NumberFormat
                                  className="input-shadow w-100 p-2 border-0 number-format"
                                  placeholder="Type in"
                                  format="(###) ###-####"
                                  mask="_"
                                  name="phone"
                                  keyfilter={'pnum'}
                                  onValueChange={(e) => field.onChange(e.value)}
                                  value={field.value}
                                  disabled={contactFieldDisable}
                                />
                              )}
                            />
                            <RequiredValidation field={'contact.additional_phone'} />
                          </div>
                        </div>
                        <div className="d-flex align-items-center py-2 flex-wrap">
                          <div className="col-md-4 col-12 p-medium">
                            Email {watch('contact.is_create_client_portal') && <span className="danger-text"> *</span>}
                          </div>
                          <div className="col-md-8 col-12">
                            <Controller
                              name="contact.email"
                              control={control}
                              rules={{
                                required: watch('contact.is_create_client_portal') && !!oldContact === false ? 'Email is required.' : false,
                                pattern: {
                                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                  message: 'Entered value does not match email format',
                                },
                                validate: { asyncValidation: (v, e) => emailValidation(v, e, 'contact') },
                              }}
                              render={({ field, fieldState }) => (
                                <InputText
                                  disabled={contactFieldDisable}
                                  placeholder="Type in"
                                  id={field.name}
                                  {...field}
                                  onChange={(e) => {
                                    setValue('contact.email', e.target.value);
                                    if (!e.target.value) {
                                      setValue('contact.is_create_client_portal', false);
                                    }
                                  }}
                                  onBlur={() => trigger('contact.email')}
                                  className={classNames({ 'p-invalid': fieldState.invalid, 'w-100 new-task-popup input-shadow': true })}
                                />
                              )}
                            />
                            <RequiredValidation field={'contact.email'} />
                          </div>
                        </div>
                        <div className="d-flex align-items-center py-2 flex-wrap">
                          <div className="col-md-4 col-12 p-medium">Enable Lawft Access</div>
                          <div className="col-md-8 col-12 d-flex">
                            <Controller
                              name="contact.is_create_client_portal"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <div id="detail_lawft_access">
                                    <InputSwitch
                                      disabled={!watch('contact.email')}
                                      className="input-shadow input-swich"
                                      inputId={field.name}
                                      onChange={(e) => field.onChange(e.value)}
                                      checked={field.value}
                                    />
                                    {!watch('contact.email') ? (
                                      <Tooltip target="#detail_lawft_access" position="right" className="case-tooltip">
                                        {'Add Email To Enable Lawft Access'}
                                      </Tooltip>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </>
                              )}
                            />
                          </div>
                        </div>
                        <div className="d-flex align-items-center py-2 flex-wrap">
                          <div className="col-md-4 col-12 p-medium">
                            Role
                            <span className="danger-text"> *</span>
                          </div>
                          <div className="col-md-8 col-12">
                            {defaultValues?.contact?.contact_type === 'business' ||
                            PI_PROVIDERS_TYPE.includes(defaultValues?.contact?.contact_type) ? (
                              <Controller
                                name="contact.role"
                                control={control}
                                rules={{ required: 'Role is Required' }}
                                render={({ field }) => (
                                  <Dropdown
                                    id={field.name}
                                    onChange={(e) => field.onChange(e.value)}
                                    inputRef={field.ref}
                                    value={field.value}
                                    options={businessRole}
                                    optionLabel="name"
                                    optionValue="name"
                                    placeholder="Select"
                                    filterPlaceholder="Search"
                                    className="w-100"
                                  />
                                )}
                              />
                            ) : (
                              <Controller
                                name="contact.role"
                                control={control}
                                rules={{ required: 'Role is Required' }}
                                render={({ field }) => (
                                  <Dropdown
                                    id={field.name}
                                    onChange={(e) => field.onChange(e.value)}
                                    inputRef={field.ref}
                                    value={field.value}
                                    options={relationRole}
                                    optionLabel="relation_role"
                                    optionValue={(v) => v.relation_role?.toLowerCase()}
                                    placeholder="Select"
                                    filterPlaceholder="Search"
                                    className="w-100"
                                  />
                                )}
                              />
                            )}
                            <RequiredValidation field={'contact.role'} />
                          </div>
                        </div>
                        {userContext.checkSubPermission('custom_fields') && (
                          <>
                            <div className="d-flex align-items-center py-2">
                              <div className="col-sm-6 d-flex addfield">
                                <Controller
                                  name="contact.custom_field_toggle"
                                  control={control}
                                  defaultValue={false}
                                  render={({ field }) => (
                                    <Button
                                      id={field.name}
                                      disabled={contactFieldDisable}
                                      type="button"
                                      className="p-button button-text text-medium addfield ps-0"
                                      onClick={(e) => field.onChange(!field.value)}
                                    >
                                      <i className={field?.value ? 'pi pi-minus F-size14 me-1' : 'pi pi-plus F-size14 me-1'}></i>Add Custom
                                      Fields
                                    </Button>
                                  )}
                                />
                              </div>
                            </div>
                            {watch('contact.custom_field_toggle') && (
                              <CustomFields field="contact.custom_field" control={control} fieldDisable={contactFieldDisable} />
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mt-3 mt-md-0">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="shadow-middle bg-white lead-data-box p-3">
                        <div className="row">
                          <div className="col-sm-12 p-medium">
                            Client Details
                            <hr></hr>
                          </div>
                        </div>
                        <div className="d-flex align-items-center py-2 flex-wrap">
                          <div className="col-md-4 col-12 p-medium">
                            Title
                            <span className="danger-text"> *</span>
                          </div>
                          <div className="col-md-8 col-12">
                            {defaultValues?.client?.contact_type === 'business' ||
                            PI_PROVIDERS_TYPE.includes(defaultValues?.client?.contact_type) ? (
                              <Controller
                                name="client.title"
                                control={control}
                                rules={{ required: 'Title is required.' }}
                                render={({ field }) => (
                                  <InputText
                                    disabled
                                    placeholder="Select"
                                    id={field.name}
                                    {...field}
                                    className="w-100 new-task-popup input-shadow"
                                  />
                                )}
                              />
                            ) : (
                              <Controller
                                name="client.title"
                                control={control}
                                rules={{ required: 'Title is Required' }}
                                render={({ field }) => (
                                  <Dropdown
                                    id={field.name}
                                    inputRef={field.ref}
                                    options={titles}
                                    className="w-100"
                                    onChange={(e) => field.onChange(e.value)}
                                    value={field.value}
                                    placeholder="Select"
                                    disabled={clientFieldDisable}
                                  />
                                )}
                              />
                            )}
                            <RequiredValidation field={'client.title'} />
                          </div>
                        </div>
                        <div className="d-flex align-items-center py-2 flex-wrap">
                          <div className="col-md-4 col-12 p-medium">
                            First Name
                            <span className="danger-text"> *</span>
                          </div>
                          <div className="col-md-8 col-12">
                            <Controller
                              name="client.first_name"
                              control={control}
                              rules={{ required: oldClientContact ? false : 'First Name is required.' }}
                              render={({ field, fieldState }) => (
                                <TextSnippetWrapper
                                  type="text"
                                  disabled={clientFieldDisable}
                                  placeholder="Type in"
                                  id={field.name}
                                  {...field}
                                  className={classNames({ 'p-invalid': fieldState.invalid, 'w-100 new-task-popup input-shadow': true })}
                                />
                              )}
                            />
                            <RequiredValidation field={'client.first_name'} />
                          </div>
                        </div>
                        <div className="d-flex align-items-center py-2 flex-wrap">
                          <div className="col-md-4 col-12 p-medium">Middle Name</div>
                          <div className="col-md-8 col-12">
                            <Controller
                              name="client.middle_name"
                              control={control}
                              render={({ field }) => (
                                <TextSnippetWrapper
                                  type="text"
                                  disabled={clientFieldDisable}
                                  placeholder="Type in"
                                  id={field.name}
                                  {...field}
                                  className="w-100 new-task-popup input-shadow"
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="d-flex align-items-center py-2 flex-wrap">
                          <div className="col-md-4 col-12 p-medium">
                            Last Name
                            <span className="danger-text"> *</span>
                          </div>
                          <div className="col-md-8 col-12">
                            <Controller
                              name="client.last_name"
                              control={control}
                              rules={{ required: oldClientContact ? false : 'Last Name is required.' }}
                              render={({ field, fieldState }) => (
                                <TextSnippetWrapper
                                  type="text"
                                  disabled={clientFieldDisable}
                                  placeholder="Type in"
                                  id={field.name}
                                  {...field}
                                  className={classNames({ 'p-invalid': fieldState.invalid, 'w-100 new-task-popup input-shadow': true })}
                                />
                              )}
                            />
                            <RequiredValidation field={'client.last_name'} />
                          </div>
                        </div>
                        <div className="d-flex align-items-center py-2 flex-wrap">
                          <div className="col-md-4 col-12 p-medium">Date Of Birth</div>
                          <div className="col-md-8 col-12">
                            <Controller
                              name="client.dob"
                              control={control}
                              render={({ field }) => (
                                <Calendar
                                  placeholder="Select"
                                  className="w-100 new-task-popup input-shadow"
                                  monthNavigator
                                  yearNavigator
                                  yearRange="1920:3000"
                                  id={field.name}
                                  value={field.value ? new Date(field.value) : null}
                                  onChange={(e) => field.onChange(e.value)}
                                  maxDate={new Date()}
                                  disabled={clientFieldDisable}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="d-flex align-items-center py-2 flex-wrap">
                          <div className="col-md-4 col-12 p-medium">Phone Number</div>
                          <div className="col-md-8 col-12">
                            <Controller
                              name="client.phone"
                              control={control}
                              rules={{
                                validate: { asyncValidation: (v, e) => validatePhone(v, e, 'client') },
                                minLength: { value: 10, message: 'Min length should be 10' },
                              }}
                              render={({ field }) => (
                                <NumberFormat
                                  className="input-shadow w-100 p-2 border-0 number-format"
                                  placeholder="Type in"
                                  format="(###) ###-####"
                                  mask="_"
                                  name="phone"
                                  keyfilter={'pnum'}
                                  onValueChange={(e) => setValue('client.phone', e.value)}
                                  value={field.value}
                                  disabled={clientFieldDisable}
                                  onBlur={() => trigger('client.phone')}
                                />
                              )}
                            />
                            <RequiredValidation field={'client.phone'} />
                          </div>
                        </div>
                        <div className="d-flex align-items-center py-2 flex-wrap">
                          <div className="col-12 col-lg-4 p-medium"></div>
                          <div className="col-12 col-lg-8">
                            <div className="d-flex">
                              <Controller
                                name="client.is_client_opt_in"
                                control={control}
                                defaultValue={true}
                                render={({ field }) => (
                                  <InputSwitch
                                    className="input-swich"
                                    checked={field.value}
                                    name={field.name}
                                    value={field.value}
                                    onChange={(e) => {
                                      field.onChange(e.value);
                                    }}
                                  />
                                )}
                              />
                              <div className="F-size12 ms-2 d-flex align-items-center black-600">
                                Client has opted in to recieve text messages
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center py-2 flex-wrap">
                          <div className="col-md-4 col-12 p-medium">Additional Number</div>
                          <div className="col-md-8 col-12">
                            <Controller
                              name="client.additional_phone"
                              control={control}
                              rules={{ minLength: { value: 10, message: 'Min length should be 10' } }}
                              render={({ field }) => (
                                <NumberFormat
                                  className="input-shadow w-100 p-2 border-0 number-format"
                                  placeholder="Type in"
                                  format="(###) ###-####"
                                  mask="_"
                                  name="phone"
                                  keyfilter={'pnum'}
                                  onValueChange={(e) => field.onChange(e.value)}
                                  value={field.value}
                                  disabled={clientFieldDisable}
                                />
                              )}
                            />
                            <RequiredValidation field={'client.additional_phone'} />
                          </div>
                        </div>
                        <div className="d-flex align-items-center py-2 flex-wrap">
                          <div className="col-md-4 col-12 p-medium">
                            Email {watch('client.is_create_client_portal') && <span className="danger-text"> *</span>}
                          </div>
                          <div className="col-md-8 col-12">
                            <Controller
                              name="client.email"
                              control={control}
                              rules={{
                                required:
                                  watch('client.is_create_client_portal') && !!oldClientContact === false ? 'Email is required.' : false,
                                pattern: {
                                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                  message: 'Entered value does not match email format',
                                },
                                validate: { asyncValidation: (v, e) => emailValidation(v, e, 'client') },
                              }}
                              render={({ field, fieldState }) => (
                                <InputText
                                  disabled={clientFieldDisable}
                                  placeholder="Type in"
                                  id={field.name}
                                  {...field}
                                  onChange={(e) => {
                                    setValue('client.email', e.target.value);
                                    if (!e.target.value) {
                                      setValue('client.is_create_client_portal', false);
                                    }
                                  }}
                                  onBlur={() => trigger('client.email')}
                                  className={classNames({ 'p-invalid': fieldState.invalid, 'w-100 new-task-popup input-shadow': true })}
                                />
                              )}
                            />
                            <RequiredValidation field={'client.email'} />
                          </div>
                        </div>
                        <div className="d-flex align-items-center py-2 flex-wrap">
                          <div className="col-md-4 col-12 p-medium">Enable Lawft Access</div>
                          <div className="col-md-8 col-12 d-flex">
                            <Controller
                              name="client.is_create_client_portal"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <div id="client_detail_lawft_access">
                                    <InputSwitch
                                      disabled={!watch('client.email')}
                                      className="input-shadow input-swich"
                                      inputId={field.name}
                                      onChange={(e) => field.onChange(e.value)}
                                      checked={field.value}
                                    />
                                    {!watch('client.email') ? (
                                      <Tooltip target="#client_detail_lawft_access" position="right" className="case-tooltip">
                                        {'Add Email To Enable Lawft Access'}
                                      </Tooltip>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </>
                              )}
                            />
                          </div>
                        </div>
                        <hr></hr>
                        <div className="row">
                          <div className="col-sm-12 p-medium">Address</div>
                        </div>
                        <hr></hr>
                        <div className="d-flex align-items-center py-2 flex-wrap">
                          <div className="col-md-4 col-12 p-medium">Street</div>
                          <div className="col-md-8 col-12">
                            <Controller
                              name="client.street"
                              control={control}
                              render={({ field }) => (
                                <TextSnippetWrapper
                                  type="text"
                                  disabled={clientFieldDisable}
                                  placeholder="Type in"
                                  id={field.name}
                                  {...field}
                                  className="w-100 new-task-popup input-shadow"
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="d-flex align-items-center py-2 flex-wrap">
                          <div className="col-md-4 col-12 p-medium">City</div>
                          <div className="col-md-8 col-12">
                            <Controller
                              name="client.city"
                              control={control}
                              render={({ field }) => (
                                <TextSnippetWrapper
                                  type="text"
                                  disabled={clientFieldDisable}
                                  placeholder="Type in"
                                  id={field.name}
                                  {...field}
                                  className="w-100 new-task-popup input-shadow"
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="d-flex align-items-center py-2 flex-wrap">
                          <div className="col-md-4 col-12 p-medium">State</div>
                          <div className="col-md-8 col-12">
                            <Controller
                              name="client.state"
                              control={control}
                              render={({ field }) => (
                                <Dropdown
                                  id={field.name}
                                  options={state_list}
                                  className="w-100"
                                  onChange={(e) => field.onChange(e.value)}
                                  value={field.value}
                                  placeholder="Select"
                                  optionLabel="label"
                                  optionValue="value"
                                  disabled={clientFieldDisable}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="d-flex align-items-center py-2 flex-wrap">
                          <div className="col-md-4 col-12 p-medium">Zip Code</div>
                          <div className="col-md-8 col-12">
                            <Controller
                              name="client.zip"
                              control={control}
                              render={({ field }) => (
                                <InputText
                                  disabled={clientFieldDisable}
                                  keyfilter={'pnum'}
                                  maxLength={5}
                                  placeholder="Type in"
                                  id={field.name}
                                  {...field}
                                  className="w-100 new-task-popup input-shadow"
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="d-flex align-items-center py-2 flex-wrap">
                          <div className="col-md-4 col-12 p-medium">Country</div>
                          <div className="col-md-8 col-12">
                            <Controller
                              name="client.country"
                              control={control}
                              render={({ field }) => (
                                <Dropdown
                                  id={field.name}
                                  options={countriesList}
                                  className="w-100"
                                  onChange={(e) => field.onChange(e.value)}
                                  value={'United States'}
                                  placeholder="Select"
                                  optionLabel="name"
                                  optionValue="name"
                                  disabled
                                />
                              )}
                            />
                          </div>
                        </div>
                        {userContext.checkSubPermission('custom_fields') && (
                          <>
                            <div className="d-flex align-items-center py-2 flex-wrap">
                              <div className="col-md-6 col-12 d-flex addfield">
                                <Controller
                                  name="client.custom_field_toggle"
                                  control={control}
                                  defaultValue={false}
                                  render={({ field }) => (
                                    <Button
                                      disabled={clientFieldDisable}
                                      id={field.name}
                                      type="button"
                                      className="p-button button-text text-medium addfield ps-0"
                                      onClick={(e) => field.onChange(!field.value)}
                                    >
                                      <i className={field?.value ? 'pi pi-minus F-size14 me-1' : 'pi pi-plus F-size14 me-1'}></i>Add Custom
                                      Fields
                                    </Button>
                                  )}
                                />
                              </div>
                            </div>
                            {watch('client.custom_field_toggle') && (
                              <CustomFields field="client.custom_field" control={control} fieldDisable={clientFieldDisable} />
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="row mt-3 pt-3">
            <RelationShip {...{ control, register, errors, watch }} />
            <AssignTo {...{ control }} />
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};
export default NewContactFormLead;
