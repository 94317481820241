import React, { useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import { Tooltip } from 'primereact/tooltip';
import { dollarFormat, getPropByString, paginationDropdownOptions, toTitleCase } from 'utils/utils';
import { useTrustAccountList } from './hooks/useTrustAccountList';
import { useCaseListFilter as useFilter } from 'modules/cases/hooks/useCaseListFilter';
import { useAccounts } from 'hooks/useAccounts';
import { handleDateTimeOffset } from 'utils/utility_functions/timezone';
import { useUserDetailsContext } from 'context/userDetailsContext';
import constants from 'constants/index';

const OperatingAccounting = ({ caseDetails, caseView }) => {
  const { accountList } = useAccounts({ accountType: 'operating' });
  const { userDetails } = useUserDetailsContext();

  const { filter, filterOnChange, refreshList } = useFilter({
    case_id: caseView ? caseDetails?.case_id : null,
    account_type: 'operating',
  });
  const {
    trustLogs: operatingLogs,
    loadTrustLogs,
    hasNext,
    hasPrevious,
    fetchNext,
    fetchprev,
    onPageChange,
    pageSize,
    loadTrustAccountBalance,
    operatingBalance,
  } = useTrustAccountList({
    _filter: filter,
  });

  useEffect(() => {
    // calling loadTrustLogs with operating account filter type to get operating logs
    loadTrustLogs({ filter });
    if (caseDetails?.default_operating_account?.[0]?.account_id || filter?.account_id) {
      loadTrustAccountBalance(
        filter?.account_id || caseDetails?.default_operating_account?.[0]?.account_id,
        caseView ? caseDetails?.case_id : null,
        'operating'
      );
    }
  }, [filter]);

  const paginatorTemplate = {
    layout: 'RowsPerPageDropdown PrevPageLink NextPageLink',
    RowsPerPageDropdown: () => {
      return (
        <React.Fragment>
          <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
            Item Per Page
          </span>
          <Dropdown value={pageSize} options={paginationDropdownOptions} onChange={onPageChange} />
        </React.Fragment>
      );
    },
    NextPageLink: ({ iconClassName }) => {
      return (
        <Button onClick={fetchNext} disabled={hasNext} className={'p-paginator-next p-paginator-element p-link'} icon={iconClassName} />
      );
    },
    PrevPageLink: ({ iconClassName }) => {
      return (
        <Button onClick={fetchprev} disabled={hasPrevious} className={'p-paginator-next p-paginator-element p-link'} icon={iconClassName} />
      );
    },
  };

  const fromTrustBody = (rowData) => {
    let entry_type = rowData?.entry_type;
    return (
      <>
        <span className="p-column-title text-break">Amount</span>
        <span>
          <span className={entry_type === 'cr' ? 'text-success' : 'text-danger'} title={dollarFormat(rowData?.amount)}>
            {dollarFormat(rowData?.amount)}
          </span>
          {entry_type === 'cr' && <i className="fas fa-arrow-down ms-2"></i>}
          {entry_type !== 'cr' && <i className="fas fa-arrow-up ms-2"></i>}
        </span>
      </>
    );
  };

  const paymentTypeBody = (rowData) => {
    return (
      <>
        <span className="p-column-title text-break">Payment Type</span>
        <span title={toTitleCase(rowData?.payment_type)}>{toTitleCase(rowData?.payment_type)}</span>
      </>
    );
  };

  const dateBody = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title text-break">Date of Transaction</span>
        <span
          className="text-break"
          title={
            rowData?.record_created_date
              ? handleDateTimeOffset(userDetails?.timezone, rowData.record_created_date, constants.month_date_year_format)
              : undefined
          }
        >
          {rowData?.record_created_date
            ? handleDateTimeOffset(userDetails?.timezone, rowData.record_created_date, constants.month_date_year_format)
            : '-'}
        </span>
      </React.Fragment>
    );
  };

  const accountBody = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title text-break">Account</span>
        <span className="text-break">
          <Link to="/settings/accounts" className="text-bold text-decoration-none" title={rowData?.credit_account_name}>
            {rowData?.credit_account_name}
          </Link>
        </span>
      </React.Fragment>
    );
  };

  const caseTemplate = (rowData, ...rest) => {
    const { header, field } = rest?.[0];
    return (
      <React.Fragment>
        <span className="p-column-title text-break">{header}</span>
        <span className="text-break">
          <Link
            className="cursor-pointer text-decoration-none F-size14 text-bold"
            to={'/cases/' + rowData?.case_id}
            title={getPropByString(rowData, field)}
          >
            {getPropByString(rowData, field)}
          </Link>
        </span>
      </React.Fragment>
    );
  };

  const rowClassName = (rowData) => ({ strikethrough: rowData.is_strikethrough });

  const refreshData = () => {
    refreshList({
      case_id: caseView ? caseDetails?.case_id : null,
      account_type: 'operating',
    });
  };

  return (
    <>
      <div className="d-flex flex-wrap align-items-center filter-wrap mb-3 p-lg-0 p-2 w-100 align-items-center justify-content-between">
        <div className="d-flex flex-wrap align-items-center p-2 filter-wraper">
          {(filter?.account_id || caseView) && (
            <label className="p-0 m-0 filter-label">
              Balance: <span className="text-bold black-600 ">{dollarFormat(operatingBalance)}</span>
            </label>
          )}
        </div>
        <div className="d-flex flex-wrap align-items-center p-2 filter-wraper">
          <label className="text-bold black-600 p-0 m-0 filter-label">Filter:</label>
          <div className="d-flex flex-column ms-2 filter-wraper">
            <Calendar
              className="input-shadow filter-calender"
              id="icon"
              name="dateFilter"
              selectionMode="range"
              value={filter?.dateFilter}
              onChange={filterOnChange}
              showIcon
              placeholder="Date"
              maxDate={new Date()}
            />
          </div>
          {!caseView && (
            <div className="d-flex flex-column ms-2 filter-wraper">
              <Dropdown
                placeholder="By Account"
                name="account_id"
                options={accountList}
                value={filter?.account_id}
                onChange={filterOnChange}
                optionLabel={'account_name'}
                optionValue="account_id"
                className="w-100"
                filter
                showClear
              />
            </div>
          )}
          <div className="d-flex flex-column mx-2">
            <div className="pointer ms-0">
              <i className="fas fa-sync-alt" onClick={refreshData}></i>
              <Tooltip content="Clear Filter" position="top" target=".fa-sync-alt" showDelay={500} />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 datatable-responsive">
          <DataTable
            value={operatingLogs}
            className="p-datatable-responsive"
            rowClassName={rowClassName}
            paginatorTemplate={paginatorTemplate}
            paginator
          >
            {!caseView && <Column header="Case Name" field="case_name" body={caseTemplate} bodyClassName="ellipsis-text" />}
            <Column
              header="Date of Transaction"
              field="record_created_date"
              body={dateBody}
              bodyClassName="ellipsis-text"
              className="ten-percent"
            />
            {!caseView && <Column header="Account" field="credit_account_name" body={accountBody} bodyClassName="ellipsis-text" />}
            <Column header="Payment Type" field="payment_type" body={paymentTypeBody} bodyClassName="ellipsis-text" />
            <Column header="Amount" body={fromTrustBody} bodyClassName="ellipsis-text" />
          </DataTable>
        </div>
      </div>
    </>
  );
};

export default OperatingAccounting;
