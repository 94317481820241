import React from 'react';
import { Controller } from 'react-hook-form';
import { Card } from 'primereact/card';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import AppConstants from 'constants/index';
import { handleDateTimeOffset } from 'utils/utility_functions/timezone';

const ImportantDates = ({ control, userDetails, caseDetails, isCaseClosed, isLeadSpecific, caseReopenDate, caseClosedDate }) => {
  return (
    <>
      {!isLeadSpecific && (
        <div>
          <div className="caption-bold mb-3 mt-4 pt-3">Important Dates</div>
          {/* imp dates */}
          <Card className="mb-4 shadow-middle F-size14">
            <>
              <div className="row mb-3 align-items-center">
                <div className="col-sm-6 text-muted p-medium">Statute of Limitation</div>
                <div className="col-sm-6 d-flex align-items-center edit-field">
                  <Controller
                    name="important_dates.statute_of_limitations"
                    control={control}
                    render={({ field }) => (
                      <Calendar
                        monthNavigator
                        yearNavigator
                        yearRange="1900:2050"
                        showButtonBar
                        dateFormat={AppConstants.date_format}
                        value={field.value ? new Date(field.value) : null}
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                        className="rounded flex-grow-1 w-75 input-shadow"
                        disabled={isCaseClosed}
                        placeholder="Select SOL"
                      ></Calendar>
                    )}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-sm-6 text-muted p-medium">Case Created</div>
                <div className="col-sm-6 d-flex align-items-center edit-field">
                  <div className="w-100 h-100">
                    {caseDetails?.important_dates?.case_created
                      ? handleDateTimeOffset(
                          userDetails.userDetails.timezone,
                          caseDetails?.important_dates?.case_created,
                          AppConstants.month_date_year_format
                        )
                      : 'N/A'}
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-sm-6 text-muted p-medium">Case Re-opened</div>
                <div className="col-sm-6 d-flex align-items-center edit-field">
                  <div className="w-100 h-100">
                    {caseReopenDate
                      ? handleDateTimeOffset(userDetails?.userDetails?.timezone, caseReopenDate, AppConstants.month_date_year_format)
                      : 'N/A'}
                  </div>
                </div>
              </div>
              {isCaseClosed && (
                <div className="row mb-3">
                  <div className="col-sm-6 text-muted " style={{ fontWeight: 500 }}>
                    Case Closed
                  </div>
                  <div className="col-sm-6 d-flex align-items-center edit-field">
                    <div className="w-100 h-100">
                      {caseClosedDate
                        ? handleDateTimeOffset(userDetails.userDetails.timezone, caseClosedDate, AppConstants.month_date_year_format)
                        : 'N/A'}
                    </div>
                  </div>
                </div>
              )}
              <div className="row mb-3">
                <div className="col-sm-6 text-muted p-medium">Client DOB</div>
                <div className="col-sm-6 d-flex align-items-center edit-field">
                  <div className="w-100 h-100">
                    {caseDetails?.client_details?.[0]?.dob ? moment(caseDetails?.client_details?.[0]?.dob).format('MM/DD/yyyy') : 'N/A'}
                  </div>
                </div>
              </div>
            </>
          </Card>
          {/* imp dates */}
        </div>
      )}
    </>
  );
};

export default ImportantDates;
