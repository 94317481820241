/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import Cookies from 'universal-cookie';
import classNames from 'classnames';

import { OverlayPanel } from 'primereact/overlaypanel';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { Dialog } from 'primereact/dialog';
import { Tooltip } from 'primereact/tooltip';

import { listTimeEntries, createTimeEntry, updateTimeEntry, dhm, diff_hours } from 'services/timerServices';
import { UserDetailsContext, useUserDetailsContext } from 'context/userDetailsContext';
import { markNotification, clearNotifications } from 'services/notification/notificationService';
import { loginLogoutEvent } from 'services/generalServices';
import { getUserProfileDetails } from 'services/profileServices';
import { WebSocketConnectionContext } from 'context/WebSocketConnectionContext';
import { openLinkInBlank, getTenantDetails, firmSelectHandler } from 'utils/utils';
import { logout } from 'utils/utils';
import { toastConstant } from 'constants/toastmessage';
import { presignedURLGet } from 'modules/file_manager/services';
import { uuidv4 } from 'common/uuidGenerator';
import { useNavbarContext } from 'context/NavbarContext';
import { getActiveTimerHours } from 'redux/actions/TimerActions';
import { appVersion } from 'config/app_version';
import { ModalHeader } from 'shared/ModalHeader';
import { Notification } from './components/Notification/Notification';
import { Timer } from '../timer/timer';
import { CreateTimer } from '../timer/CreateTimer';
import { useToast } from 'context/ToastContext';
import constants from 'constants/index';
import useSpinner from 'hooks/useSpinner';
import GlobalSearch from './components/GlobalSearch/GlobalSearch';
import logo from '../../assets/images/logo_lawft_transparent.png';
import debounce from 'lodash.debounce';

import { default as InfiniteScrollList } from 'components/InfiniteScrollNotificationList/InfiniteScrollNotificationList';
import GlobalTooltip from 'components/GlobalTooltip';

const cookie = new Cookies();
export const UserProfileContext = React.createContext();
const Layout = ({ children, showSideBar }) => {
  const [tenantId, tenantSk] = getTenantDetails();
  const dispatch = useDispatch();

  const menu = useRef(null);
  const fileMenu = useRef(null);
  const messagesMenu = useRef(null);
  const history = useHistory();
  const [userInfo, setUserInfo] = useState('');
  const [dropDownToggle, setDropDownToggle] = useState(false);
  const [navigationToggle, setNavigationToggle] = useState(false);
  const userDetails = useUserDetailsContext();
  const websocketContext = useContext(WebSocketConnectionContext);
  const notificationPanel = useRef('');
  const { addToast } = useToast();
  const { pathname } = useLocation();

  const userContext = useContext(UserDetailsContext);
  const navbarContextData = useNavbarContext();

  const [currentToken, setCurrentToken] = useState('');
  const [spinner, showSpinner, hideSpinner] = useSpinner(true);
  const [newNotification, setNewNotification] = useState(false);
  const [timerList, setTimerList] = useState();
  const [selectedTimer, setSelectedTimer] = useState();
  const [imageUrl, setImageUrl] = useState('');
  const [toggleVal, setToggleVal] = useState(false);
  const [isFileVisible, setFileVisible] = useState(false);
  const [isOneTime, setIsOneTime] = useState(true);
  const [disableMenu, setDisableMenu] = useState(false);
  const [disableCreateButton, setDisableCreateButton] = useState(false);
  const [showCreateTimer, setShowCreateTimer] = useState(false);
  const [showTimerWidget, setShowTimerWidget] = useState(false);
  const [webSocketNotificationsCount, setWebSocketNotificationsCount] = useState(0);
  const menuBarRef = useRef(null);

  const {
    notifications: notificationList = [],
    next_token: nextToken,
    total_notifications,
  } = navbarContextData?.notificationData?.notifications || {};

  const { isLoading, setNotifications: setNotificationList } = navbarContextData?.notificationData || {};

  const secureMessageNotificationCount = Number(navbarContextData?.notificationData?.notificationCount?.secure_message_count);
  const texMessageNotificationcount = Number(navbarContextData?.notificationData?.notificationCount?.text_message_count);
  const mailNotificationCount = Number(navbarContextData?.notificationData?.notificationCount?.email_count);
  const sidebarNotificationCount = Number(navbarContextData?.notificationData?.notificationCount?.sidebar_message_count);

  const loggedInUserId = cookie.get('userId') || null;

  const totalNotiicationsCount = Number(webSocketNotificationsCount || 0) || Number(total_notifications || 0) || 0;

  const getTotalActiveHours = (timerList) => {
    if (timerList?.length) {
      let totalActiveHours = 0;

      timerList?.forEach((element) => {
        totalActiveHours += Number(element?.total_hours);
      });
      return totalActiveHours?.toFixed(2);
    }
  };

  const getIndexofArrayByKeyValue = (array = [], key = false, value = false) => {
    let index = false;
    if (array && array.length && key && value) {
      array.find((item, i) => {
        let result = false;
        if (item[key] === value) {
          index = i;
          return true;
        }
        return result;
      });
    }
    return index;
  };
  const handlePausedVal = () => {
    let newList = timerList?.filter((item) => item.start_time === '' && item.end_time);
    return newList?.length;
  };

  const debouncedUpdateTimeEntry = useCallback(
    debounce((data) => {
      updateTimeEntry(data, false, '', true, true)
        .then((response) => {})
        .catch((error) => {});
    }, 300),
    []
  );

  const onChangeNotes = (data, id) => {
    let index = getIndexofArrayByKeyValue(timerList, 'sk', id);
    let timerList_ = [...timerList];
    timerList_[index] = { ...timerList_[index], notes: data };
    setTimerList(timerList_);
    // updateTimeEntry(timerList_[index], false, '', true, true).then((response) => {});
    debouncedUpdateTimeEntry(timerList_[index]);
  };
  const documentItems = [
    {
      label: 'File Manager',
      command: () => {
        if (!openLinkInBlank('/file-manager')) {
          window.location.hash = '/file-manager';
          setNavigationToggle(false);
        }
      },
      show: true,
    },
    {
      label: 'Templates',
      command: () => {
        if (!openLinkInBlank('/templates')) {
          window.location.hash = '/templates';
          setNavigationToggle(false);
        }
      },
      show: userDetails.checkSubPermission('document_assembly'),
    },
  ];
  const messageItemsFirmAdmin = [
    {
      label: 'Messages',
      command: () => {
        if (!openLinkInBlank('/messages')) {
          window.location.hash = '/messages';
          setNavigationToggle(false);
          messagesMenu.current.hide();
        }
      },
      template: (item) => {
        return (
          <div
            className="notification-textmsg black-600 cursor-pointer d-flex align-items-center"
            onClick={() => {
              if (!openLinkInBlank('/messages')) {
                window.location.hash = '/messages';
                setNavigationToggle(false);
                messagesMenu.current.hide();
              }
            }}
          >
            <span>{item.label}</span>

            <div class="d-inline-flex message-status">
              {secureMessageNotificationCount > 0 ? (
                <i class="icon-messages me-3 ms-3 new-message-notification">
                  <span className="p-badge">{secureMessageNotificationCount}</span>
                </i>
              ) : (
                <i class="icon-messages me-2 ms-2 new-message-notification"></i>
              )}
              {sidebarNotificationCount > 0 ? (
                <i class="icon-sidebar">
                  <span class="p-badge">{sidebarNotificationCount}</span>
                </i>
              ) : (
                <i class="icon-sidebar"></i>
              )}
            </div>
            {/* <span className={secureMessageNotificationCount > 0 ? 'y-badge bg-danger mt-1' : ''}>
            <span className={secureMessageNotificationCount > 0 ? 'p-badge bg-danger mt-1' : ''}>
              {secureMessageNotificationCount > 0 ? secureMessageNotificationCount : null}
            </span>
            <span className={sidebarNotificationCount > 0 ? 'y-badge bg-danger mt-1' : ''}>
              {sidebarNotificationCount > 0 ? sidebarNotificationCount : null}
            </span> */}
          </div>
        );
      },
      show: true,
    },
    {
      label: `Text Messages`,
      command: () => {
        if (!openLinkInBlank('/text-messages')) {
          window.location.hash = '/text-messages';
          setNavigationToggle(false);
          messagesMenu.current.hide();
        }
      },
      template: (item) => {
        return (
          <div
            className="notification-textmsg black-600 cursor-pointer"
            onClick={(e) => {
              if (!openLinkInBlank('/text-messages')) {
                window.location.hash = '/text-messages';
                setNavigationToggle(false);
                messagesMenu.current.hide();
              }
            }}
          >
            <span>{item.label}</span>
            <span className={texMessageNotificationcount > 0 ? 'p-badge bg-danger mt-1' : ''}>
              {texMessageNotificationcount > 0 ? texMessageNotificationcount : null}
            </span>
          </div>
        );
      },
      show: userDetails.checkSubPermission('texting'),
    },
    {
      label: 'Mail',
      command: () => {
        if (!openLinkInBlank('/mails')) {
          window.location.hash = '/mails';
          setNavigationToggle(false);
          messagesMenu.current.hide();
        }
      },
      template: (item) => {
        return (
          <div
            className="notification-textmsg black-600 cursor-pointer"
            onClick={(e) => {
              if (!openLinkInBlank('/mails')) {
                window.location.hash = '/mails';
                setNavigationToggle(false);
                messagesMenu.current.hide();
              }
            }}
          >
            <span>{item.label}</span>
            <span className={mailNotificationCount > 0 ? 'p-badge bg-danger mt-1' : ''}>
              {mailNotificationCount > 0 ? mailNotificationCount : null}
            </span>
          </div>
        );
      },
      show: !!userDetails?.userDetails?.business_email,
    },
    {
      label: 'Fax',
      command: () => {
        if (!openLinkInBlank('/fax')) {
          window.location.hash = '/fax';
          setNavigationToggle(false);
          messagesMenu.current.hide();
        }
      },
      template: (item) => {
        return (
          <div
            className="notification-textmsg black-600 cursor-pointer"
            onClick={(e) => {
              if (!openLinkInBlank('/fax')) {
                window.location.hash = '/fax';
                setNavigationToggle(false);
                messagesMenu.current.hide();
              }
            }}
          >
            <span>{item.label}</span>
          </div>
        );
      },
      show: userDetails.checkSubPermission('texting'),
    },
  ];

  const items = [
    {
      label: 'New Case',
      icon: 'icon-doc-add',
      command: () => {
        history.replace({
          pathname: `/cases`,
          state: { createTask: true },
        });
      },
      show: true,
    },
    {
      label: 'New File',
      icon: 'icon-files',
      command: () => setFileVisible(true),
      show: true,
    },
    {
      label: 'New Contact',
      icon: 'icon-contacts',
      command: () => {
        history.push({
          pathname: `/contacts/add`,
        });
      },
      show: true,
    },
    {
      label: 'New Lead',
      icon: 'icon-person',
      command: () => {
        history.replace({
          pathname: `/leads`,
          state: { createLead: true },
        });
      },
      show: userContext.checkSubPermission('lead_management'),
    },
    {
      label: 'New Event',
      icon: 'icon-calendar',
      command: () => {
        history.replace({
          pathname: `/calendar`,
          state: { createEvent: true },
        });
      },
      show: true,
    },
    {
      label: 'New Task',
      icon: 'icon-tasks',
      command: (e) => {
        history.replace({
          pathname: `/tasks`,
          state: { openTask: true },
        });
      },
      show: userContext.checkSubPermission('task_management'),
    },
    {
      label: 'Message',
      icon: 'icon-messages',
      command: (e) => {
        history.replace({
          pathname: `/messages`,
          state: { createMessage: true },
        });
      },
      show: true,
    },
    {
      label: 'New Time Entry',
      icon: 'icon-clock',
      command: (e) => {
        history.replace({
          pathname: `/reports/time-manager`,
          state: { createTimeEntry: true },
        });
      },
      show: true,
    },
    {
      label: 'Add Note/Call Log',
      icon: 'icon-notes',
      command: (e) => {
        history.replace({
          pathname: `/notes`,
          state: { isGlobal: true },
        });
      },
      show: true,
    },
  ];

  const fetchUserDetails = () => {
    getUserProfileDetails()
      .then((response) => {
        if (response && response.data) {
          fetchImage(response?.data?.profile_image);
        } else {
        }
      })
      .catch((error) => {});
  };

  const getUserDetails = () => {
    try {
      Auth.currentUserInfo()
        .then((res) => {
          localStorage.setItem('userInfo', JSON.stringify(res));
          setUserInfo(res);
        })
        .catch((error) => {
          localStorage.setItem('userInfo', null);
        });
    } catch (e) {
      localStorage.setItem('userInfo', null);
    }
  };

  const redirectToPage = (e, path) => {
    e.preventDefault();
    if (!openLinkInBlank(path)) {
      history.push(path);
      setNavigationToggle(false);
    }
  };

  const redirectToDashboardPage = (e) => {
    e.preventDefault();
    if (!openLinkInBlank('/dashboard')) {
      history.push('/dashboard');
      setNavigationToggle(false);
    }
  };

  const redirectToCases = (e) => {
    e.preventDefault();
    if (!openLinkInBlank('/cases')) {
      history.push('/cases');
      setNavigationToggle(false);
    }
  };

  const redirectToLeads = (e) => {
    e.preventDefault();
    if (!openLinkInBlank('/leads')) {
      history.push('/leads');
      setNavigationToggle(false);
    }
  };

  const handleProfile = (e) => {
    setDropDownToggle(false);
    redirectToPage(e, '/profile');
  };

  const handleLogout = () => {
    try {
      loginLogoutEvent()
        .then((response) => {})
        .catch((err) => {});
      Auth.signOut()
        .then((data) => {
          setDropDownToggle(false);
          logout(history);
          websocketContext.closeConnection();
        })
        .catch((error) => {});
    } catch (e) {}
  };

  const markAsRead = (e, data) => {
    showSpinner();
    e.preventDefault();
    markNotification(true, [data?.sk])
      .then((response) => {
        navbarContextData?.notificationData?.markasRead(data?.sk);
        navbarContextData?.notificationData?.notifications?.notifications?.length <= 8 &&
          nextToken &&
          navbarContextData?.notificationData?.loadNotifications({ notificationType: '', limit: 10, nextToken });
        hideSpinner();
      })
      .catch((err) => {
        console.log('err', err);
        hideSpinner();
      });
  };

  const showModule = (module, subscriptionPermission) => {
    if (!(userContext?.subscriptionAccess?.[subscriptionPermission] || subscriptionPermission === 'common')) {
      return false;
    }
    let permission = userDetails.permissions ? userDetails.permissions[module] : '';
    let response = false;
    if ([constants.permissions.READ, constants.permissions.WRITE].includes(permission)) {
      response = true;
    }
    return response;
  };

  const clearAll = (e) => {
    e.preventDefault();
    showSpinner();
    clearNotifications()
      .then((response) => {
        hideSpinner();
        setWebSocketNotificationsCount(0);
        setNotificationList((preval) => ({ ...preval, notifications: [] }));
        navbarContextData?.notificationData?.setNotifications({});
      })
      .catch((err) => {
        hideSpinner();
      });
  };

  const loadTimer = () => {
    listTimeEntries(
      {
        case_id: null,
        time_entry_date: null,
        time_entry_status: 'PROGRESS',
        time_entry_from: '',
        time_entry_to: '',
        time_entry_filter: 0,
        search_key: '',
      },
      false
    )
      .then((response) => {
        if (response && response.data && response.data.time_entries) {
          let running_timer = response.data.time_entries.filter((index) => !index.end_time);
          let remaning_timer = response.data.time_entries.filter((index) => index.end_time);
          setTimerList([...running_timer, ...remaning_timer]);
          setDisableCreateButton(false);
          hideSpinner();
        }
      })
      .catch((err) => {});
  };

  const createTimerSuccess = (e) => {
    setShowCreateTimer(false);
    loadTimer();
  };

  const selectTimer = (data) => {
    setSelectedTimer({ ...data });
    let timer_btn = document.getElementById('create-timer');
    if (timer_btn) {
      timer_btn.click();
    }
  };

  const cancelCreateTimer = () => {
    setShowCreateTimer(false);
  };

  const addNewTimer = () => {
    setDisableCreateButton(true);
    showSpinner();
    let data = {
      start_time: new Date().getTime(),
      time_entry_status: 'PROGRESS',
      billable: true,
      hour_rate: '',
      total_times: '00:00:00',
      total_hours: '0',
    };

    let date = new Date();
    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = date.getFullYear();
    date = yyyy + '-' + mm + '-' + dd;
    data.time_entry_date = date;
    createTimeEntry(data).then((response) => {
      if (localStorage.getItem('currentTimer')) {
        let currentTimer = JSON.parse(localStorage.getItem('currentTimer'));
        if (currentTimer.sk) {
          let start_time = currentTimer.start_time;
          let end_time = currentTimer.end_time;

          if (start_time && !end_time) {
            let end_time = new Date();
            let duration = end_time - new Date(start_time);
            currentTimer.end_time = end_time.getTime();
            currentTimer.total_times = dhm(duration);
            currentTimer.start_time = '';
            currentTimer.total_hours = diff_hours(duration);
            updateTimeEntry(currentTimer).then((response) => {
              createTimerSuccess();
            });
          }
        } else {
          createTimerSuccess();
        }
      } else {
        createTimerSuccess();
      }
    });
  };

  const fetchImage = async (filePath) => {
    try {
      let fileSize = 'medium.jpg';
      if (filePath) {
        let proURL = filePath;
        let url = proURL.split('/');
        url.pop();
        if (fileSize) {
          url.push(fileSize);
        } else {
          url.push('small.jpg');
        }
        let finalUrl = url.join('/');
        let data = await presignedURLGet(finalUrl, false, true);
        if (data?.data?.file_url) {
          setToggleVal(!toggleVal);
          setImageUrl(data?.data?.file_url);
        } else {
          setImageUrl('');
        }
      }
    } catch (error) {}
  };

  const handleFromScratch = () => {
    localStorage.removeItem('file_url');
    localStorage.removeItem('file_name');
    localStorage.removeItem('file_size');
    localStorage.removeItem('file_type');
    localStorage.setItem('mode', 'scratch');
    let new_id = uuidv4();
    localStorage.setItem('file_url', `${new_id}.html`);

    history.push({
      pathname: '/templates/builder',
      state: { type: 'scratch', save_as: 'file', from: history.location?.pathname },
    });
    setFileVisible(false);
  };

  const redirectToSubscription = () => {
    history.push('/settings/subscription');
  };

  /**--------------------------------------- useEffect section starts ---------------------------------------**/

  useEffect(() => {
    let totalActiveHours = getTotalActiveHours(timerList);
    dispatch(getActiveTimerHours(totalActiveHours));
    setDisableCreateButton(false);
  }, [timerList]);

  useEffect(() => {
    let accessLevel = cookie.get('access_level') || null;
    if (
      accessLevel &&
      accessLevel !== 'firm-admin' &&
      userDetails?.selectedPlanDetails?.message &&
      userDetails?.selectedPlanDetails?.message === constants?.NON_FIRM_ADMIN_SUBSCRIPTION_CANCEL_MESSAGE
    ) {
      handleLogout();
    }
    if (
      userDetails?.selectedPlanDetails?.status &&
      userDetails?.selectedPlanDetails?.access_modules?.subscription &&
      userDetails?.selectedPlanDetails?.status !== 'completed' &&
      !userDetails?.selectedPlanDetails?.access_modules?.dashboard
    ) {
      setDisableMenu(true);
    } else {
      setDisableMenu(false);
    }
  }, [userDetails]); // eslint-disable-line

  useEffect(() => {
    loadTimer();
    if (tenantId && tenantSk && isOneTime) {
      fetchUserDetails();
      setIsOneTime(false);
    }
  }, [tenantId, tenantSk]); // eslint-disable-line

  useEffect(() => {
    getUserDetails();
  }, []); //eslint-disable-line

  useEffect(() => {
    if (userDetails && userDetails.access_level) {
      let tenantId = cookie.get('tenantId');
      if (userDetails && userDetails.firmDetails && userDetails.firmDetails.current_user && tenantId) {
        websocketContext.connectToWebSocket(tenantId, userDetails?.firmDetails?.current_user);
      } else if (!tenantId) {
        history.replace('/tenant-selection');
      }
    }
  }, [userDetails]); // eslint-disable-line

  useEffect(() => {
    let notificationsArr = [...notificationList] || [];
    const duplicateData = notificationsArr?.some((item) => item?.sk === websocketContext?.notificationData?.sk);
    if (websocketContext?.notificationData) {
      if (
        websocketContext?.notificationData?.notification_type === 'secure_message' &&
        websocketContext?.notificationData?.created_by_user_id &&
        websocketContext?.notificationData?.created_by_user_id === loggedInUserId
      ) {
        return;
      }
      const count = !duplicateData ? total_notifications + 1 : total_notifications;
      setWebSocketNotificationsCount(count);
      if (!duplicateData) {
        notificationsArr.unshift(websocketContext?.notificationData);
        setNewNotification(true);
      }
      if (['inbound_text_message', 'secure_message']?.includes(websocketContext?.notificationData?.notification_type) && loggedInUserId) {
        navbarContextData?.notificationData?.loadNotificationCount();
      }
    }
    setNotificationList((preval) => ({ ...preval, notifications: notificationsArr }));
  }, [websocketContext?.notificationData]); // eslint-disable-line

  useEffect(() => {
    if (websocketContext?.sideBarMessages) {
      if (['sidebar_message']?.includes(websocketContext?.sideBarMessages?.notification_type) && loggedInUserId) {
        navbarContextData?.notificationData?.loadNotificationCount();
      }
    }
    if (websocketContext?.caseEmails) {
      if (['incoming_email']?.includes(websocketContext?.caseEmails?.notification_type) && loggedInUserId) {
        navbarContextData?.notificationData?.loadNotificationCount();
      }
    }
  }, [websocketContext?.sideBarMessages, websocketContext?.caseEmails]);

  //Secure message toast message - Showing "Message sent successfully" to the person who sent the message
  useEffect(() => {
    if (
      websocketContext?.secureMessageData &&
      Object.keys(websocketContext?.secureMessageData)?.length > 0 &&
      websocketContext?.secureMessageData?.created_by_user_id === loggedInUserId &&
      websocketContext?.secureMessageData?.origin !== 'automation'
    ) {
      addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.MESSAGE_SEND_SUCCESS);
      websocketContext?.setSecureMessageData(null);
    }
  }, [websocketContext?.secureMessageData]);

  //Text message toast message
  useEffect(() => {
    if (
      websocketContext?.textMessageData &&
      Object.keys(websocketContext?.textMessageData)?.length > 0 &&
      websocketContext?.textMessageData?.notification_type !== 'outbound_status_callback' &&
      Boolean(websocketContext?.textMessageData?.only_thread_update) === false &&
      notificationList?.length > 0
    ) {
      let notificationsArr = [...notificationList];

      addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, '', 'bottom-right', true, notificationsArr[0]);
    }
  }, [websocketContext?.textMessageData]);

  //Automation error log message section
  useEffect(() => {
    if (websocketContext?.automationErrorLogData && Object.keys(websocketContext?.automationErrorLogData)?.length) {
      let { automationErrorLogData } = websocketContext || {};
      let notificationsArr = [...notificationList] || [];

      notificationsArr?.unshift(automationErrorLogData);
      setNotificationList((preval) => ({ ...preval, notifications: notificationsArr }));
      setNewNotification(true);

      addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, '', 'bottom-right', true, automationErrorLogData);
    }
  }, [websocketContext?.automationErrorLogData]);

  /**--------------------------------------- useEffect section ends ---------------------------------------**/

  if (!showSideBar) return <div id="layout">{children}</div>;
  return (
    <div id="layout">
      <OverlayPanel
        ref={notificationPanel}
        id="overlay_panel"
        style={{ width: '500px' }}
        className="overlaypanel-demo shadow p-1 notification-overlay"
        ariaCloseLabel="notification-panel"
      >
        {spinner}
        <div className="notification-wrapper overflow-hidden">
          <div className="pb-2 border-bottom rounded-0 notification-head mb-2">
            <div className="col-12 d-flex justify-content-between align-items-center">
              <h6 className="text-bold m-0">Notifications</h6>
              {notificationList?.length > 0 && (
                <a href="/" className="text-muted text-decoration-none" onClick={(e) => clearAll(e)}>
                  <small>Clear All</small>
                </a>
              )}
            </div>
          </div>

          {notificationList?.length > 0 ? (
            <InfiniteScrollList
              {...{
                notificationList,
                loadNotifications: navbarContextData?.notificationData?.loadNotifications,
                isLoading,
                markAsRead,
                nextToken,
                currentToken,
                setCurrentToken,
              }}
            />
          ) : (
            <div className="row mt-2">
              <div className="col-12 d-flex justify-content-center">No notifications found</div>
            </div>
          )}
        </div>
        <div className="pb-1 border-top rounded-0 mb-0">
          <div className="col-12 d-flex justify-content-end align-items-center m-0" onClick={(e) => notificationPanel?.current?.toggle(e)}>
            <Link
              to={{ pathname: '/profile', state: { manageNotification: true } }}
              className="text-decoration-none call-back F-size13 text-medium mb-0 pointer"
            >
              Notification Preferences
            </Link>
          </div>
        </div>
      </OverlayPanel>

      {showTimerWidget ? (
        <Dialog
          className="add-contact-dialog open-timemanager-popup new-task-popup"
          header={ModalHeader}
          visible={showTimerWidget}
          onHide={() => {
            setShowTimerWidget(false);
          }}
          closable={false}
          style={{ width: '400px !important', top: '50px', margin: 0, right: 10 }}
          position={'top-right'}
          footer={<></>}
        >
          <div className="d-flex flex-wrap p-0">
            <div className="modal-heading F-size16 text-bold">Paused Timers : {handlePausedVal()}</div>
            {timerList?.map((timerData) => {
              return (
                <div className="col-12 p-0" key={timerData.sk}>
                  <Timer
                    showTimerWidget={showTimerWidget}
                    setTimerList={setTimerList}
                    timerList={timerList}
                    timer={timerData}
                    selectTimer={selectTimer}
                    loadTimer={loadTimer}
                    onChangeNotes={onChangeNotes}
                    getIndexofArrayByKeyValue={getIndexofArrayByKeyValue}
                    isListTimeEntry={true}
                    setShowTimerWidget={setShowTimerWidget}
                    createTimerSuccess={createTimerSuccess}
                  />
                </div>
              );
            })}
            {timerList?.length < 5 ? (
              <div className="col-12 d-flex align-items-center mt-2">
                <Button
                  className="p-button-text p-0"
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedTimer();
                    addNewTimer();
                    setShowTimerWidget(true);
                  }}
                  disabled={disableCreateButton}
                >
                  Start New Timer
                </Button>
              </div>
            ) : (
              <label className="F-size12 text-bold p-medium">Max 5 Timers only</label>
            )}

            <div className="d-flex align-items-center justify-content-center w-100 py-2">{spinner}</div>
            {timerList && timerList.length > 0 && (
              <div className="d-flex align-items-center justify-content-end w-100 py-2">
                <div
                  className="p-button p-button-primary"
                  onClick={(e) => {
                    redirectToPage(e, '/reports/time-manager');
                  }}
                >
                  Open Time Manager
                </div>
              </div>
            )}
          </div>
        </Dialog>
      ) : null}

      {showCreateTimer ? (
        <Dialog
          visible={showCreateTimer}
          onHide={() => {
            setShowCreateTimer(false);
            cancelCreateTimer();
          }}
          closable={false}
          header={ModalHeader}
          style={{ width: '40vw' }}
          className="add-contact-dialog create-msg-popup"
        >
          <CreateTimer
            createTimerSuccess={createTimerSuccess}
            selectedTimer={selectedTimer}
            cancelCreateTimer={cancelCreateTimer}
            isFromTimeManger={true}
          />
        </Dialog>
      ) : null}

      {websocketContext?.notificationPopup ? (
        <Notification
          visible={websocketContext?.notificationPopup}
          notificationData={websocketContext?.notificationData}
          onHide={() => websocketContext?.setNotificationPopup(false)}
        />
      ) : null}

      {userDetails?.selectedPlanDetails?.trial_end ? (
        <div className="d-flex align-items-center subscribe-msg justify-content-end px-3">
          {`Your free trial ${userDetails?.selectedPlanDetails?.status === 'completed' ? 'expires' : 'expired'} on ${moment
            .unix(userDetails?.selectedPlanDetails?.trial_end)
            .format('MM/DD/YYYY')}`}
          {userDetails?.access_level === 'firm-admin' && (
            <button
              className="p-button p-component p-button-secondary ms-3"
              onClick={() => {
                redirectToSubscription();
              }}
            >
              Subscribe Now
            </button>
          )}
        </div>
      ) : null}
      <nav className="navbar navbar-expand-lg px-2 py-3">
        <div className="container-fluid flex-wrap">
          <Link to="/" className="text-dark fw-bold navbar-brand logo-wrap py-0 d-flex align-items-center">
            <img src={logo} alt="logo" width="88" height="18" />
            <label htmlFor="timezone" className="ms-2 version">
              v{appVersion}
            </label>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#basicExampleNav"
            aria-controls="basicExampleNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setNavigationToggle(!navigationToggle)}
          >
            <span className="navbar-toggler-icon d-flex align-items-center">
              <i className="fa fa-bars"></i>
            </span>
          </button>

          <div
            className={navigationToggle ? 'collapse navbar-collapse show' : 'collapse navbar-collapse lawfttop-navbar'}
            id="basicExampleNav"
          >
            <ul className="navbar-nav px-3">
              {showModule('dashboard', 'common') && (
                <li className={classNames('nav-item', { active: pathname?.includes('/dashboard') })}>
                  {disableMenu ? (
                    <a className="nav-link disabledCursor" href="/" onClick={(e) => e.preventDefault()}>
                      Dashboard
                    </a>
                  ) : (
                    <a className="nav-link" href="/" onClick={redirectToDashboardPage}>
                      Dashboard
                    </a>
                  )}
                </li>
              )}

              {showModule('cases', 'case_management') && (
                <li className={classNames('nav-item', { active: pathname?.includes('/cases') })}>
                  {disableMenu ? (
                    <a className="nav-link disabledCursor" href="/" onClick={(e) => e.preventDefault()}>
                      Cases
                    </a>
                  ) : (
                    <a className="nav-link" href="/" onClick={redirectToCases}>
                      Cases
                    </a>
                  )}
                </li>
              )}

              {showModule('files', 'common') && (
                <li className={classNames('nav-item', { active: pathname?.includes('/file-manager') })}>
                  <>
                    <a
                      className={disableMenu ? 'nav-link disabledCursor' : 'nav-link'}
                      href="/"
                      onClick={(event) => {
                        event.preventDefault();
                        !disableMenu && fileMenu.current.toggle(event);
                      }}
                      aria-controls="popup_menu"
                      aria-haspopup
                    >
                      <div className="">
                        Files <i className="pi pi-chevron-down nav-down-arrow" />
                      </div>
                    </a>
                    <Menu model={documentItems?.filter((v) => v.show)} popup ref={fileMenu} id="popup_menu" />
                  </>
                </li>
              )}

              <li className={classNames('nav-item', { active: pathname === '/messages' })}>
                <a
                  className={disableMenu ? 'nav-link disabledCursor' : 'nav-link'}
                  href="/"
                  onClick={(event) => {
                    event.preventDefault();
                    !disableMenu && messagesMenu?.current?.toggle(event);
                  }}
                  aria-controls="popup_menu"
                  aria-haspopup
                >
                  <div>
                    Messages
                    {userDetails?.checkSubPermission('texting') &&
                    (secureMessageNotificationCount || texMessageNotificationcount || sidebarNotificationCount || mailNotificationCount) ? (
                      <i className="icon-window-filled pointer F-size12"></i>
                    ) : null}
                    <i className="pi pi-chevron-down nav-down-arrow" />
                  </div>
                </a>
                <Menu model={messageItemsFirmAdmin?.filter((v) => v.show)} popup ref={messagesMenu} id="popup_menu" />
              </li>

              {showModule('contacts', 'contact_management') && (
                <li className={classNames('nav-item', { active: /^\/contacts(?:\/|$)/.test(pathname) })}>
                  <a
                    className={disableMenu ? 'nav-link disabledCursor' : 'nav-link'}
                    href="/"
                    onClick={(e) => {
                      disableMenu ? e.preventDefault() : redirectToPage(e, '/contacts');
                    }}
                  >
                    Contacts
                  </a>
                </li>
              )}

              {showModule('calendar', 'calendaring') && (
                <li className={classNames('nav-item', { active: pathname === '/calendar' })}>
                  <a
                    className={disableMenu ? 'nav-link disabledCursor' : 'nav-link'}
                    href="/"
                    onClick={(e) => {
                      disableMenu ? e.preventDefault() : redirectToPage(e, '/calendar');
                    }}
                  >
                    Calendar
                  </a>
                </li>
              )}
              {showModule('tasks', 'task_management') && (
                <li className={classNames('nav-item', { active: pathname === '/tasks' })}>
                  <a
                    className={disableMenu ? 'nav-link disabledCursor' : 'nav-link'}
                    href="/"
                    onClick={(e) => {
                      disableMenu ? e.preventDefault() : redirectToPage(e, '/tasks');
                    }}
                  >
                    Tasks
                  </a>
                </li>
              )}

              {showModule('billing', 'common') && (
                <li className={classNames('nav-item', { active: pathname === '/billing' })}>
                  <a
                    className={disableMenu ? 'nav-link disabledCursor' : 'nav-link'}
                    href="/"
                    onClick={(e) => {
                      disableMenu ? e.preventDefault() : redirectToPage(e, '/billing');
                    }}
                  >
                    Billing
                  </a>
                </li>
              )}

              {showModule('leads', 'lead_management') && (
                <li className={classNames('nav-item', { active: pathname?.includes('/leads') })}>
                  <a
                    className={disableMenu ? 'nav-link disabledCursor' : 'nav-link'}
                    href="/"
                    onClick={(e) => (disableMenu ? e.preventDefault() : redirectToLeads(e))}
                  >
                    Leads
                  </a>
                </li>
              )}

              {showModule('reports', 'reports') && (
                <li className={classNames('nav-item', { active: pathname?.includes('/reports') })}>
                  <a
                    className={disableMenu ? 'nav-link disabledCursor' : 'nav-link'}
                    href="/"
                    onClick={(e) => (disableMenu ? e.preventDefault() : redirectToPage(e, '/reports'))}
                  >
                    Reports
                  </a>
                </li>
              )}

              {/* {showModule('reports', 'reports') && ( */}
              <li className={classNames('nav-item', { active: pathname?.includes('/intake-forms') })}>
                <a
                  className={disableMenu ? 'nav-link disabledCursor' : 'nav-link'}
                  href="/"
                  onClick={(e) => {
                    if (!disableMenu) {
                      redirectToPage(e, '/intake-forms');
                    }
                  }}
                >
                  Forms
                </a>
              </li>
              {/* )} */}

              {showModule('automation', 'automated_repeatable_workflows') && (
                <li className={classNames('nav-item', { active: pathname?.includes('/settings/workflow-automation') })}>
                  <a
                    className={disableMenu ? 'nav-link disabledCursor' : 'nav-link'}
                    href="/"
                    onClick={(e) => {
                      // event.preventDefault();
                      if (!disableMenu) {
                        redirectToPage(e, '/settings/workflow-automation');
                      }
                    }}
                  >
                    Automation
                  </a>
                </li>
              )}
            </ul>
          </div>
          <div className="d-flex ms-auto align-items-center">
            <div ref={menuBarRef} className="d-flex align-items-center top-nav-avatar">
              <a
                className="nav-link dropdown-toggle d-flex align-items-center"
                id="navbarDropdownMenuLink"
                data-toggle="dropdown"
                aria-expanded="false"
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  setDropDownToggle(!dropDownToggle);
                }}
              >
                <Avatar
                  image={imageUrl}
                  onImageError={() => setImageUrl('')}
                  className={'me-1'}
                  size={'medium'}
                  shape="circle"
                  imageAlt=""
                />
                {/* <i className="icon-dropdown"></i> */}
              </a>
              <div
                className={
                  dropDownToggle
                    ? 'dropdown-menu dropdown-primary show input-shadow login-dropdown border-0'
                    : 'dropdown-menu dropdown-primary'
                }
                aria-labelledby="navbarDropdownMenuLink"
              >
                <a className="dropdown-item" href="/" onClick={handleProfile}>
                  Profile
                </a>
                {userDetails?.firmDetails?.firm_details?.length > 1 && (
                  <a
                    className="dropdown-item"
                    href="javascript:void(0);"
                    onClick={() => {
                      firmSelectHandler(history);
                    }}
                  >
                    Select Firm
                  </a>
                )}
                <a className="dropdown-item" href="/" onClick={(e) => redirectToPage(e, '/settings/team-management')}>
                  Settings
                </a>
                <a
                  className="dropdown-item"
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    handleLogout();
                  }}
                >
                  Logout
                </a>
              </div>
            </div>

            <div className="d-flex align-items-center px-2 global-search">
              <GlobalSearch />
            </div>

            <div
              className="mr-4 px-2 d-flex align-items-center"
              aria-controls="notification-panel"
              onClick={(e) => {
                notificationPanel?.current?.toggle(e);
                setNewNotification(false);
              }}
            >
              <i className={newNotification ? 'icon-notification bell fa-lg pointer' : 'icon-notification fa-lg pointer'}></i>
              <Tooltip content="Notifications" target=".icon-notification" position="bottom" showDelay={500} />

              {totalNotiicationsCount ? (
                <span className={newNotification ? 'p-badge bg-danger' : 'p-badge'}>
                  {totalNotiicationsCount ? totalNotiicationsCount : null}
                </span>
              ) : null}
            </div>
            <div className="timer-wrap d-flex align-items-center mx-2" id={timerList && timerList.length < 5 ? 'maxtimerCount' : ''}>
              {timerList?.length > 0 && (
                <div className="timer-container">
                  {/*to show only the current timer data  */}
                  <Timer timer={timerList[0]} selectTimer={selectTimer} loadTimer={loadTimer} isListTimeEntry={false} />
                  {timerList && timerList.length < 5 ? (
                    <GlobalTooltip tooltip="Add Timer" className="d-flex mx-1 cursor-pointer">
                      <i
                        className="icon-add F-size12 add-timer"
                        onClick={(e) => {
                          setSelectedTimer();
                          addNewTimer();
                          setShowTimerWidget(true);
                        }}
                      />
                    </GlobalTooltip>
                  ) : (
                    <>
                      <GlobalTooltip tooltip="Max 5 Timers only" className="d-flex mx-1 cursor-pointer">
                        <i className="icon-add F-size12 add-timer" id="maxtimerCount" />
                      </GlobalTooltip>
                    </>
                  )}
                  <Avatar
                    label={`${timerList.length}`}
                    shape="circle"
                    className="mx-1 "
                    onClick={(e) => {
                      e.preventDefault();
                      setShowTimerWidget(true);
                    }}
                  />
                </div>
              )}
              {timerList && timerList.length === 0 && (
                <label
                  className="mx-2"
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedTimer();
                    addNewTimer();
                    setShowTimerWidget(true);
                  }}
                  id=""
                >
                  <span className="d-flex align-items-center" role="button">
                    {<i className="icon icon-timer cursor-pointer d-block d-sm-none"></i>}{' '}
                    <label className="cursor-pointer d-none d-sm-block">Start New Timer</label>
                  </span>
                </label>
              )}

              <span
                id="create-timer"
                className=""
                onClick={(e) => {
                  // createTimerPanel.current.toggle(e);
                  setShowCreateTimer(!showCreateTimer);
                }}
              ></span>
            </div>

            <div className="d-flex align-items-center">
              <Button
                label="Add"
                className="p-button-outlined p-button-info top-button p-button-primary add-button"
                icon="icon-add"
                onClick={(event) => menu.current.toggle(event)}
                aria-controls="popup_menu"
                aria-haspopup
              />
              <Menu model={items.filter((v) => v.show)} popup ref={menu} id="popup_menu" />
            </div>
          </div>
        </div>
      </nav>
      <div className="content-container pt-0">
        <div className="container-fluid">
          <UserProfileContext.Provider
            value={{
              userInfo: userInfo,
              fetchUserDetails: fetchUserDetails,
              setImageUrl: setImageUrl,
            }}
          >
            {children}
          </UserProfileContext.Provider>
        </div>
      </div>

      <Dialog
        className="new-task-popup"
        header={ModalHeader}
        visible={isFileVisible}
        style={{ width: '40vw' }}
        footer={<></>}
        onHide={() => setFileVisible(false)}
      >
        <div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="modal-heading F-size16 text-bold">Add Document</div>
          </div>
          <div className="d-flex justify-content-center align-items-center mb-4">
            <button
              onClick={() => {
                window.location.hash = '/file-manager';
                setFileVisible(false);
              }}
              className="file-shortcut-button b-radius16 d-flex flex-column  justify-content-center align-items-center me-4"
            >
              <i className="fas fa-file-upload fa-5x"></i>
              <span className="text-center"> Upload one document</span>
            </button>
            <button
              onClick={() => {
                window.location.hash = '/file-manager';
                setFileVisible(false);
              }}
              className="file-shortcut-button b-radius16 d-flex flex-column   justify-content-center align-items-center ms-4"
            >
              <i className="fas fa-copy fa-5x"></i>
              <span className="text-center"> Upload multiple documents</span>
            </button>
          </div>
          {userDetails.checkSubPermission('document_assembly') && (
            <div className="d-flex justify-content-center align-items-enter mt-4">
              <button
                onClick={() => {
                  window.location.hash = '/templates';
                  setFileVisible(false);
                }}
                className="file-shortcut-button b-radius16 d-flex flex-column   justify-content-center align-items-center me-4"
              >
                <i className="fas fa-file-word fa-5x"></i>
                <span className="text-center">Create document from template</span>
              </button>
              <button
                onClick={handleFromScratch}
                className="file-shortcut-button b-radius16 d-flex flex-column  justify-content-center align-items-center ms-4"
              >
                <i className="fas fa-file fa-5x"></i>
                <div className="d-flex justify-content-center align-items-center">
                  <span className="text-center">Start with a blank page</span>
                </div>
              </button>
            </div>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default Layout;
