import React from 'react';
import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';
import GlobalTooltip from 'components/GlobalTooltip';

function SummarizeFile(props) {
  const { rowData } = props;
  const { modals, handleSummarizeFile } = useFileManagerContext();
  const { setShowSummarizeModal } = modals;

  const handleSummarize = (rowData) => {
    const data = {
      file_path: rowData?.file_path,
      file_name: rowData?.file_name,
      version_id: rowData?.version_id,
      sk: rowData?.sk,
      regenerate: 0,
    };
    setShowSummarizeModal(true);
    handleSummarizeFile(rowData, data);
  };

  return (
    <>
      <span role="button" onClick={() => handleSummarize(rowData)}>
        <GlobalTooltip tooltip="Summarize" className="bi bi-card-list me-3 text-primary-main">
          <i className="summarise-icon" aria-hidden="true" />
        </GlobalTooltip>
      </span>
    </>
  );
}

export default SummarizeFile;
