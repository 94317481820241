import useFileDownload from 'modules/file_manager/hooks/useFileDownload';
import React from 'react';
import GlobalTooltip from 'components/GlobalTooltip';

function FileDownload(props) {
  const { downloadFile } = useFileDownload();
  const { rowData, isFromFilePreviewModal, filePreviewModalIconClassName } = props;

  const iconClassName =
    isFromFilePreviewModal && filePreviewModalIconClassName ? filePreviewModalIconClassName : 'icon-save text-primary-main';

  return (
    <>
      <span role="button" onClick={() => downloadFile(rowData)}>
        <GlobalTooltip tooltip="Download" className="me-3 text-primary-main">
          <i className={iconClassName} aria-hidden="true" />
        </GlobalTooltip>
      </span>
    </>
  );
}

export default FileDownload;
