import React, { createRef, useEffect, useMemo, useRef, useState } from 'react';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import HeaderCheckbox from './Templates/HeaderCheckbox';

import { TitleTemplate } from './Templates/TitleTemplate';
import CheckboxTemplate from './Templates/CheckboxTemplate';
// import ShareTemplate from './Templates/ShareTemplate';

import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';
import SignatureTemplate from './Templates/SignatureTemplate';
import LastEditedTemplate from './Templates/LastEditedTemplate';
import UploadedTemplate from './Templates/UploadedTemplate';
import ActionsTemplate from './Templates/ActionsTemplate/ActionsTemplate';

import { useUserDetailsContext } from 'context/userDetailsContext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import useFileDownload from 'modules/file_manager/hooks/useFileDownload';

import { paginationDropdownOptions } from 'utils/utils';
import { handlePageNumberInPagination } from 'common/pagination';
import shareIcon from '../../../../../assets/images/shared.svg';
import FileSharingDetailsCard from 'components/FileSharing/FileSharingDetailsCard';
import { FileSharingDetailsModal } from 'components/FileSharing/FileSharingDetailsModal';
import userDetails from 'redux/reducers/userDetails';
import { convertToUTC } from 'utils/utility_functions/timezone';
import * as moment from 'moment';
import { isCurrentDatePastExpiry } from 'utils/utility_functions/isCurrentDatePastExpiry';

function ListView() {
  const {
    files,
    selectedRows,
    selectAll,
    handleSingleCheckboxClick,
    handleSelectAllCheckboxClick,
    pageLimit,
    setPageLimit,
    handleGetFiles,
    onNextPageClick,
    onPrevPageClick,
    fileManagerFilters,
    currentView,
    nextToken,
    transitionToken,
    paginationDirection,
    modals,
    isClientFileManager,
    isGlobalFileManager,
    isCaseFileManager,
    isLeadFileManager,
    caseDetails,
  } = useFileManagerContext();

  const { setShowBulkDeleteModal, setShowBulkMoveModal, setShowShareFileModal, setIsCopy } = modals;
  const [showFileSharingDetailsModal, setshowFileSharingDetailsModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const { downloadFile } = useFileDownload();

  const userContext = useUserDetailsContext();

  const itemsRef = useMemo(
    () =>
      Array(files && files.length ? files.length : 0)
        .fill()
        .map(() => createRef()),
    [files]
  );

  function getFilePathForPagination() {
    if (isGlobalFileManager && userContext) {
      return { module: 'file_manager', subModule: '', listName: 'self' };
    } else if (isCaseFileManager && userContext) {
      return { module: 'case', subModule: '', listName: 'file_manager' };
    } else if (isLeadFileManager && userContext) {
      return { module: 'leads', subModule: '', listName: 'file_manager' };
    } else if (isClientFileManager && userContext) {
      return { module: 'client_portals', subModule: 'filemanager', listName: 'self' };
    }
  }

  const onPageChange = (event) => {
    setPageLimit(event.value);
    let path = getFilePathForPagination();
    handlePageNumberInPagination(userContext, path?.module, path?.subModule, path?.listName, event.value);
    handleGetFiles(event.value);
  };

  function nextChange() {
    let obj = {
      case_id: isGlobalFileManager || isClientFileManager ? fileManagerFilters?.case?.id : caseDetails.case_id,
      selectedFolder: fileManagerFilters.selectedFolder,
      selectedType: fileManagerFilters.fileType,
      tag: fileManagerFilters.tag,
      query: '',
      pagination_direction: paginationDirection,
    };

    onNextPageClick(obj, currentView, pageLimit);
  }

  function previousChange() {
    let obj = {
      case_id: isGlobalFileManager || isClientFileManager ? fileManagerFilters?.case?.id : caseDetails.case_id,
      selectedFolder: fileManagerFilters.selectedFolder,
      selectedType: fileManagerFilters.fileType,
      tag: fileManagerFilters.tag,
      query: '',
      pagination_direction: paginationDirection,
    };
    onPrevPageClick(obj, currentView, pageLimit);
  }

  const PaginationTemplate = {
    layout: 'RowsPerPageDropdown PrevPageLink NextPageLink',
    RowsPerPageDropdown: (options) => {
      return (
        <React.Fragment>
          <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
            Items Per Page
          </span>
          <Dropdown value={options.value} options={paginationDropdownOptions} onChange={onPageChange} disabled={files?.length === 0} />
        </React.Fragment>
      );
    },
    NextPageLink: ({ iconClassName }) => {
      // console.log(nextToken, paginationDirection);
      return (
        <Button
          onClick={nextChange}
          disabled={!nextToken && paginationDirection === 'forward'}
          className={'p-paginator-next p-paginator-element p-link'}
          icon={iconClassName}
        />
      );
    },
    PrevPageLink: ({ iconClassName }) => {
      return (
        <Button
          onClick={previousChange}
          disabled={!transitionToken || (transitionToken && !nextToken && paginationDirection === 'reverse')}
          className={'p-paginator-next p-paginator-element p-link'}
          icon={iconClassName}
        />
      );
    },
  };
  const containsFolders = selectedRows.some((item) => item.entry_type === 'folder');
  async function downloadFiles() {
    try {
      await Promise.all(
        selectedRows.map(async (file) => {
          await downloadFile(file);
        })
      );
      console.log('All files downloaded successfully');
    } catch (error) {
      console.error('Error downloading files:', error);
    }
  }

  const header = (
    <div className="flex flex-wrap align-items-end justify-content-end gap-2 d-flex files-buttons">
      {!isClientFileManager && !isClientFileManager && (
        <Button
          // variant="outline-secondary"
          className="p-button  p-button-primary ms-2"
          onClick={(e) => {
            setShowBulkDeleteModal(true);
          }}
        >
          <i className="icon-delete text-white me-2 pointer" aria-hidden="true" title="delete"></i>
          Delete
        </Button>
      )}

      {!isGlobalFileManager && !isClientFileManager && (
        <Button
          className="p-button  p-button-primary ms-2"
          onClick={() => {
            setShowBulkMoveModal(true);
            setIsCopy(false);
          }}
        >
          <i className="icon-move text-white me-2 pointer" aria-hidden="true" title="Move"></i>
          Move
        </Button>
      )}

      {!isGlobalFileManager && !isClientFileManager && (
        <Button
          className="p-button  p-button-primary ms-2"
          onClick={() => {
            setShowBulkMoveModal(true);
            setIsCopy(true);
          }}
        >
          <i className="icon-copy text-white me-2 pointer" aria-hidden="true" title="Copy"></i>
          Copy
        </Button>
      )}

      {!containsFolders && !isGlobalFileManager && !isClientFileManager && (
        <Button label="Share" className="p-button  p-button-primary outline ms-2" onClick={() => setShowShareFileModal(true)} />
      )}
      {!containsFolders && (
        <Button className="p-button  p-button-primary ms-2" onClick={downloadFiles}>
          <i className="icon-save text-white me-2 pointer" aria-hidden="true" title="Download"></i>Download
        </Button>
      )}
    </div>
  );

  return (
    <>
      <Tooltip target=".share" />
      <DataTable
        header={selectedRows.length ? header : false}
        value={files}
        responsiveLayout="scroll"
        className="p-datatable-responsive file-sharing-details"
        emptyMessage="No Data Found."
        paginator
        rowHover
        paginatorTemplate={PaginationTemplate}
        rows={pageLimit}
        //   rowExpansionTemplate={rowExpansionTemplate}
        //   expandedRows={expandedRows}
        //   onRowToggle={(e) => setExpandedRows(e.data)}
      >
        {files.length > 0 && (
          <Column
            header={
              <HeaderCheckbox
                handleSelectAllCheckboxClick={() => handleSelectAllCheckboxClick(files)}
                selectAll={selectAll}
                selectedRows={selectedRows}
                files={files}
                caseDetails={caseDetails}
              />
            }
            body={(rowData) => (
              <CheckboxTemplate
                handleSingleCheckboxClick={handleSingleCheckboxClick}
                selectedRows={selectedRows}
                rowData={rowData}
                caseDetails={caseDetails}
              />
            )}
            style={{ width: '3em', verticalAlign: 'top' }}
            bodyClassName="ellipsis-text"
          />
        )}
        <Column
          field="display_name"
          className="fileManager-name"
          header="Title"
          body={(rowData, event) => <TitleTemplate rowData={rowData} event={event} isClientFileManager={isClientFileManager} />}
          sortable
          bodyClassName="ellipsis-text"
        />
        {/* <Column field="sharing" header="Sharing" body={(rowData) => <ShareTemplate rowData={rowData} />} style={{ width: '8%' }} /> */}
        {userContext.checkSubPermission('e_signature') && (
          <Column
            field="signature"
            className="fileManager-sign"
            header="Signature"
            body={(rowData) => <SignatureTemplate rowData={rowData} />}
            bodyClassName="ellipsis-text"
          />
        )}

        <Column
          field="time"
          header="Date Uploaded"
          body={(rowData) => <UploadedTemplate userContext={userContext} rowData={rowData} isClientFileManager={isClientFileManager} />}
          sortable
          // bodyClassName="ellipsis-text"
        />
        <Column
          field="updated"
          header="Last Edited"
          body={(rowData) => <LastEditedTemplate userContext={userContext} rowData={rowData} isClientFileManager={isClientFileManager} />}
          sortable
          // bodyClassName="ellipsis-text"
        />
        {!isClientFileManager && (
          <Column
            body={(rowData, index) => (
              <>
                {/* {isCurrentDatePastExpiry(rowData?.lawft_link_expires_on) ? 'Expired' : 'Not Expired'} */}
                {/* <div ref={cardRef}>{showCard[rowData?.sk] && <FileSharingDetailsCard />}</div> */}
                <span>
                  {/* {rowData?.file_shared_contact_id?.length > 0 ? (
                  <span className="share" data-pr-tooltip="Shared File" data-pr-position="right">
                    <img
                      src={shareIcon}
                      alt="Network"
                      width={20}
                      height={20}
                      className="network-icon"
                      data-pr-tooltip="Shared File"
                      data-pr-position="top"
                    />
                    <Tooltip target=".network-icon" />
                  </span>
                ) : (
                  ''
                )} */}
                  {rowData?.entry_type !== 'folder' ? (
                    isCurrentDatePastExpiry(rowData?.lawft_link_expires_on, userContext?.userDetails?.timezone) === false ||
                    rowData?.file_shared_contact_id?.length > 0 ? (
                      <i
                        className="fas fa-check-circle pointer shared-icon share-details"
                        id={`shared-file-tooltip-${rowData.sk}`}
                        onClick={() => {
                          setshowFileSharingDetailsModal(true);
                          setSelectedFile(rowData);
                        }}
                      >
                        <Tooltip
                          target={`#shared-file-tooltip-${rowData.sk.replace(/[^\w\-]/g, '\\$&')}`}
                          className="file-share-tooltip"
                          position="top"
                        >
                          <FileSharingDetailsCard rowData={rowData} />
                        </Tooltip>
                      </i>
                    ) : (
                      <i className="fas fa-times-circle" style={{ cursor: 'default' }}></i>
                    )
                  ) : null}
                </span>
              </>
            )}
            header="Shared"
            exportable={false}
            className="fileManager-sign"
          />
        )}
        <Column
          body={(rowData, e) => <ActionsTemplate rowData={rowData} e={e} itemsRef={itemsRef} caseDetails={caseDetails} />}
          header="Actions"
          // style={{ paddingLeft: '42px' }}
          exportable={false}
          // className="fileManager-actions"
        />
      </DataTable>
      <FileSharingDetailsModal
        show={showFileSharingDetailsModal && selectedFile?.lawft_link_expires_on}
        setShow={showFileSharingDetailsModal}
        onHide={() => {
          setshowFileSharingDetailsModal(false);
        }}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
      />
    </>
  );
}

export default ListView;
