import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import AsyncSelect from 'react-select/async';

import { ModalHeader } from 'shared/ModalHeader';
import { RequiredValidation } from 'components/UI/RequiredValidation/RequiredValidation';
import useShareIntakeForms from '../hooks/useShareIntakeForms';
import CaseListDropDown from 'components/CaseListDropDown/CaseListDropDown';

export default function ShareClientFormModal({ onHide, case_id, form_sk, callBack, pa, isCopyLink = false, isLead = false }) {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  const { clientData, practiceAreaList, shareIntakeFrom, IntakeSearch, getContactList } = useShareIntakeForms(case_id, pa);

  const caseId = watch('case')?.id || case_id;
  const contact = watch('contact');

  const onSubmit = (data) => {
    shareIntakeFrom({
      case_id: case_id || data?.case?.id,
      contact_id: data?.contact?.contact_id,
      form_sk: data?.form?.sk || form_sk,
      send_email: String(!isCopyLink),
      is_lead: isLead || data?.case?.is_lead,
    }).then(() => {
      onHide();
      typeof callBack === 'function' && callBack();
    });
  };

  const assignToItemTemplate = (v) => {
    if (v?.access_level === 'client' || v.contact_id) {
      if (!Boolean(v?.email)) {
        return (
          <>
            {v?.firm_user_id && <i className="fas fa-user-tie me-2"></i>}
            {`${v?.fullName} (Email does not exist)`}
          </>
        );
      }
    }
    return (
      <>
        {v?.firm_user_id && <i className="fas fa-user-tie me-2"></i>}
        {`${v?.fullName}`}
      </>
    );
  };

  return (
    <Dialog
      header={ModalHeader}
      closable={false}
      visible
      modal
      onHide={() => onHide()}
      resizable={false}
      className="create-msg-popup"
      contentStyle={{ overflow: 'visible' }}
    >
      <div className="d-flex justify-content-between align-items-center mt-3  mb-3">
        <div className="modal-heading F-size16 text-bold">Share Form</div>
      </div>
      {case_id ? (
        <div className="row align-items-center py-2">
          <div className="col-lg-3 col-12">
            <label className="p-medium">Client Form{<span className="text-danger">{'*'}</span>}</label>
          </div>
          <div className="col-lg-9 col-12 mb-1">
            {practiceAreaList?.length > 0 && (
              <Controller
                name="form"
                control={control}
                rules={{ required: 'Required Field' }}
                render={({ field }) => (
                  <AsyncSelect
                    getOptionLabel={(v) => v?.form_name}
                    getOptionValue={(v) => v?.sk}
                    placeholder="Select or Search"
                    className="input-shadow w-100"
                    value={field.value}
                    onChange={field.onChange}
                    cacheOptions
                    defaultOptions
                    loadOptions={IntakeSearch}
                    noOptionsMessage={() => 'No Forms Found'}
                  />
                )}
              />
            )}
            <RequiredValidation field={'form'} errors={errors} />
          </div>
        </div>
      ) : (
        <div className="row align-items-center py-2">
          <div className="col-lg-3 col-12">
            <label className="p-medium">Case/Lead{<span className="text-danger">{'*'}</span>}</label>
          </div>
          <div className="col-lg-9 col-12 mb-1">
            <Controller
              name="case"
              control={control}
              defaultValue=""
              rules={{ required: 'Required Field' }}
              render={({ field }) => (
                <CaseListDropDown
                  value={field?.value}
                  onChange={(e) => {
                    if (field?.value !== e) {
                      field.onChange(e);
                      getContactList(e?.id);
                      setValue('contact', undefined);
                    }
                  }}
                  paFilter={pa}
                  placeholder="By Case/Lead"
                  isClearable
                />
              )}
            />
            <RequiredValidation field={'case'} errors={errors} />
          </div>
        </div>
      )}
      <div className="row align-items-center py-2">
        <div className="col-lg-3 col-12">
          <label className="p-medium">Contact{<span className="text-danger">{'*'}</span>}</label>
        </div>
        <div className="col-lg-9 col-12">
          <Controller
            name="contact"
            control={control}
            rules={{ required: 'Required Field' }}
            render={({ field }) => (
              <Dropdown
                options={clientData ?? []}
                optionLabel="fullName"
                className="input-shadow w-100"
                filter
                id={field?.name}
                value={field?.value}
                placeholder="Select a contact"
                onChange={(e) => field.onChange(e.value)}
                emptyFilterMessage="No records found"
                itemTemplate={assignToItemTemplate}
                disabled={!caseId}
                appendTo={'self'}
              />
            )}
          />
          <RequiredValidation field={'contact'} errors={errors} />
        </div>
      </div>
      <div className="footer-btn">
        <Button
          className="p-button-secondary outline mx-2"
          label="Cancel"
          onClick={() => {
            onHide();
          }}
        />
        <Button
          label={(contact && !contact?.email) || isCopyLink ? 'Copy Link' : 'Share'}
          className="p-button p-button-secondary"
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    </Dialog>
  );
}
