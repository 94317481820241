import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputSwitch } from 'primereact/inputswitch';
import { Checkbox } from 'primereact/checkbox';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';

import { useToggle } from 'hooks/useToggle';
import { useFirmUserList } from 'hooks/useFirmUserList';
import { generateFullName } from 'utils/generateFullNameUtils';
import { handleRedirection, sortByKey } from 'utils/utils';
import { handleSettingSkAndPkToLocalStorage } from 'utils/localStorageUtils';
import useTeamMember from 'modules/cases/hooks/useTeamMember';
import ImageComponent from 'shared/ImageComponent';
import { GroupUserTooltip } from 'components/UI/GroupUserTooltip/GroupUserTooltip';

const getLeadRole = (m) => {
  const leadRoles = {
    is_lead_case_manager: 'Lead Case Manager',
    is_lead_paralegal: 'Lead Paralegal',
    is_lead_attorney: 'Lead Attorney',
    is_lead_associate: 'Lead Associate',
  };
  const leadRole = Object.keys(leadRoles).find((key) => m?.[key]);
  return leadRole ? leadRoles[leadRole] : m?.user_type;
};

export default function TeamMember({ caseDetails, isLeadSpecific, isCaseClosed, isLeadClosed, getDetails }) {
  const [isTeamMemberAdd, toggleAddView] = useToggle(false);

  const history = useHistory();
  const { userList } = useFirmUserList(true, false);
  const { deleteTeamMember, handleTeamMemberLawftAcces, updateTeamMembers } = useTeamMember({ caseDetails, isLeadSpecific, getDetails });

  const [selectedList, setSelectedList] = useState([]);
  const teamMembers = useMemo(() => {
    const allUsers = [
      ...caseDetails?.team_member
        ?.filter((v) => v?.is_individual_user || (typeof v?.is_individual_user === 'undefined' && !v?.is_group_user) || !v?.is_group_user)
        ?.map((v) => ({ ...v, is_selected: true }))
        ?.sort((a, b) => a?.user_type - b?.user_type),
      ...(caseDetails?.groups?.map((v) => ({ ...v, is_selected: true }))?.sort((a, b) => a?.group_name - b?.group_name) || []),
    ];
    const sortedFirmUsers = sortByKey(allUsers, 'first_name');

    const leadAttorneys = sortedFirmUsers?.filter((user) => user?.is_lead_attorney);
    const leadAssociates = sortedFirmUsers?.filter((user) => user?.is_lead_associate);
    const leadParalegals = sortedFirmUsers?.filter((user) => user?.is_lead_paralegal);
    const leadCaseManagers = sortedFirmUsers?.filter((user) => user?.is_lead_case_manager);
    const archivedMembers = sortedFirmUsers?.filter((user) => user?.status === 'ARCHIVED');
    const otherActiveMembers = sortedFirmUsers?.filter(
      (user) =>
        !user?.is_lead_attorney &&
        !user?.is_lead_associate &&
        !user?.is_lead_paralegal &&
        !user?.is_lead_case_manager &&
        user?.status === 'ACTIVE'
    );
    return [...leadAttorneys, ...leadAssociates, ...leadParalegals, ...leadCaseManagers, ...otherActiveMembers, ...archivedMembers];
  }, [caseDetails]);

  const onChangeHandler = (e) => {
    setSelectedList((preVal) =>
      preVal?.map((val) => {
        if (val?.firm_user_id === e.value || val?.user_group_id === e.value) {
          if (e.target.name === 'is_selected' || e.target.name === 'notify_case_updates') {
            if (e.target.name === 'notify_case_updates' && e.checked) {
              return { ...val, [e.target.name]: e.checked, is_selected: true };
            }
            if (e.target.name === 'is_selected' && !e.checked) {
              return {
                ...val,
                [e.target.name]: e.checked,
                is_lead_associate: false,
                is_lead_attorney: false,
                is_lead_paralegal: false,
                is_lead_case_manager: false,
                notify_case_updates: false,
              };
            }
            return { ...val, [e.target.name]: e.checked };
          } else {
            return {
              ...val,
              is_lead_associate: false,
              is_lead_attorney: false,
              is_lead_paralegal: false,
              is_lead_case_manager: false,
              [e.target.name]: true,
              is_selected: true,
            };
          }
        } else if (!(e.target.name === 'is_selected' || e.target.name === 'notify_case_updates')) {
          return { ...val, [e.target.name]: false };
        }
        return { ...val };
      })
    );
  };

  const getSelectedItem = (name) => selectedList?.find((v) => v?.[name] === true)?.firm_user_id;

  const confirmDeleteMember = (tMember) => {
    confirmDialog({
      message: 'Are you sure want to delete this team member?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => deleteTeamMember(tMember),
    });
  };

  const handleSave = () => {
    const selectedGroups = selectedList?.filter((v) => v?.is_selected && v?.type === 'user-group');
    const selectedUsers = selectedList?.filter((v) => v?.is_selected && v?.type !== 'user-group');

    const groupUsers = caseDetails?.team_member
      ?.filter(
        (v) =>
          selectedGroups?.some((g) => v?.group_id?.includes(g?.group_id)) &&
          !selectedUsers?.some((u) => v?.firm_user_id === u?.firm_user_id)
      )
      ?.map((v) => ({ ...v, is_individual_user: false }));

    const data = {
      team_member: [...selectedUsers, ...groupUsers].map((v) => ({ ...v, case_id: caseDetails?.case_id, type: undefined })),
      groups: selectedGroups?.map((v) => ({
        group_id: v?.user_group_id,
        group_name: v?.group_name,
        sk: v?.sk,
        notify_case_updates: Boolean(v?.notify_case_updates),
        status: v?.status,
      })),
    };
    updateTeamMembers(data).then(() => toggleAddView());
  };

  const rowheaderTemplate = (data) => {
    return (
      <React.Fragment>
        <strong>{data?.type === 'user-group' ? 'Groups' : 'Users'}</strong>
      </React.Fragment>
    );
  };

  const nameTemplate = (rowData) => {
    const { sk, pk, firm_user_id } = rowData;
    let url = '/settings/team-management';
    let stateToPass = {};

    if (firm_user_id) {
      url = firm_user_id ? `/settings/team-management/${firm_user_id}` : '/settings/team-management';
      stateToPass = rowData;
    }

    if (rowData?.type === 'user-group') {
      url = '/settings/groups';
    }

    return (
      <div
        className="d-flex flex-column flex-sm-row w-100 align-items-center"
        onClick={() => {
          handleRedirection(history, url, stateToPass);
          handleSettingSkAndPkToLocalStorage(sk, pk);
        }}
      >
        <span className="p-column-title text-break d-none">Lead Attorney</span>
        <ImageComponent filePath={rowData?.profile_image} fileName={rowData?.first_name} fileSize="small.jpg" className="col-1 me-2" />
        {firm_user_id && <i className="fas fa-user-tie me-2"></i>}
        <div>
          <span className="d-flex align-items-center text-break redirection-link-no-text-transform">
            {rowData?.group_name || generateFullName(rowData)}
          </span>
          <div className="archived-tags ms-0">{tagFormatting(rowData)}</div>
        </div>
      </div>
    );
  };

  const notifyTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column flex-sm-row w-100 justify-content-center">
        <span className="p-column-title text-break d-none">Enable Lawft Access</span>
        <Checkbox
          id="team_notify_checkbox"
          checked={rowData.notify_case_updates}
          name="notify_case_updates"
          value={rowData?.firm_user_id || rowData?.user_group_id}
          onChange={onChangeHandler}
          disabled={!(rowData?.status === 'ACTIVE')}
        />
      </div>
    );
  };
  const selectColumnTemplate = (rowData) => {
    return (
      <Checkbox
        id="is_selected"
        checked={rowData?.is_selected}
        name="is_selected"
        value={rowData?.firm_user_id || rowData?.user_group_id}
        onChange={onChangeHandler}
        disabled={!(rowData.status === 'ACTIVE')}
      />
    );
  };

  const tagFormatting = (option) => {
    if (Boolean(option?.is_confirmed) === false && option?.status === 'ACTIVE' && !option?.group_name) {
      return <Tag className="m-1 ms-0 tag-cases border-0" value="UNCONFIRMED" rounded />;
    } else if (option?.status === 'ARCHIVED' || option?.group_status === 'ARCHIVED') {
      return <Tag className="m-1 ms-0 tag-cases border-0" value={option?.group_status || option?.status} rounded />;
    }
  };

  const assignToItemTemplate = (option) => {
    return (
      <>
        {option?.firm_user_id && <i className="fas fa-user-tie me-2"></i>}
        {generateFullName(option)}
      </>
    );
  };
  useEffect(() => {
    const archievedUsers =
      teamMembers?.filter((t) => t?.status === 'ARCHIVED')?.map((v) => ({ ...v, is_selected: true, type: 'user' })) || [];
    const allUsers = [
      ...userList
        ?.map((v) => {
          const matched = teamMembers?.find(
            (t) => (t?.firm_user_id && t?.firm_user_id === v?.firm_user_id) || (t?.group_id && t?.group_id === v?.user_group_id)
          );
          const is_group = v?.res_type === 'user-group';
          if (matched) {
            return {
              ...v,
              ...matched,
              type: v?.res_type,
            };
          } else
            return {
              ...v,
              type: v?.res_type,
              sk: is_group ? v?.sk : undefined,
              pk: is_group ? v?.pk : undefined,
            };
        })
        .filter((user) => user.status !== 'ARCHIVED' || user.is_selected),
      ...archievedUsers,
    ];
    const firmUsers = allUsers?.filter((user) => user.type === 'user') || [];
    const groups = allUsers?.filter((user) => user.type === 'user-group') || [];
    const sortedFirmUsers = sortByKey(firmUsers, 'first_name');
    setSelectedList([...sortedFirmUsers, ...groups]);
  }, [userList, teamMembers, isTeamMemberAdd]);

  const usersOnly = selectedList?.filter((v) => v?.type !== 'user-group');

  return (
    <Card title={isLeadSpecific ? 'Assign To' : 'Team Members'}>
      {isTeamMemberAdd ? (
        <>
          <div className="d-flex justify-content-end">
            <div className="text-end">
              <Button
                className="p-button p-button-secondary"
                onClick={handleSave}
                disabled={!Boolean(selectedList?.filter((v) => v?.is_selected)?.length)}
              >
                {isLeadSpecific ? 'Update' : 'Update Team'}
              </Button>
              <Button className="p-button p-button-secondary outline ms-2" onClick={toggleAddView}>
                Cancel
              </Button>
            </div>
          </div>

          <div className="mt-2 d-flex align-items-center py-2 flex-wrap">
            <div className="col-4 p-medium">Lead Attorney</div>
            <div className="col-6">
              <Dropdown
                name="is_lead_attorney"
                optionLabel={(v) => generateFullName(v)}
                optionValue="firm_user_id"
                options={usersOnly?.filter((u) => u?.status !== 'ARCHIVED' || u?.firm_user_id === getSelectedItem('is_lead_attorney'))}
                onChange={onChangeHandler}
                className="input-shadow w-100 border-0"
                value={getSelectedItem('is_lead_attorney')}
                itemTemplate={assignToItemTemplate}
                filter
              />
            </div>
          </div>

          <div className="d-flex align-items-center py-2 flex-wrap">
            <div className="col-4 p-medium">Lead Associate</div>
            <div className="col-6">
              <Dropdown
                name="is_lead_associate"
                optionLabel={(v) => generateFullName(v)}
                optionValue="firm_user_id"
                options={usersOnly?.filter((u) => u?.status !== 'ARCHIVED' || u?.firm_user_id === getSelectedItem('is_lead_associate'))}
                onChange={onChangeHandler}
                className="input-shadow w-100 border-0"
                value={getSelectedItem('is_lead_associate')}
                itemTemplate={assignToItemTemplate}
                filter
              />
            </div>
          </div>
          <div className="d-flex align-items-center py-2 flex-wrap">
            <div className="col-4 p-medium">Lead Paralegal</div>
            <div className="col-6">
              <Dropdown
                name="is_lead_paralegal"
                optionLabel={(v) => generateFullName(v)}
                optionValue="firm_user_id"
                options={usersOnly?.filter((u) => u?.status !== 'ARCHIVED' || u?.firm_user_id === getSelectedItem('is_lead_paralegal'))}
                onChange={onChangeHandler}
                className="input-shadow w-100 border-0"
                value={getSelectedItem('is_lead_paralegal')}
                itemTemplate={assignToItemTemplate}
                filter
              />
            </div>
          </div>
          <div className="d-flex align-items-center py-2 flex-wrap">
            <div className="col-4 p-medium">Lead Case Manager</div>
            <div className="col-6">
              <Dropdown
                name="is_lead_case_manager"
                optionLabel={(v) => generateFullName(v)}
                optionValue="firm_user_id"
                options={usersOnly?.filter((u) => u?.status !== 'ARCHIVED' || u?.firm_user_id === getSelectedItem('is_lead_case_manager'))}
                onChange={onChangeHandler}
                className="input-shadow w-100 border-0"
                value={getSelectedItem('is_lead_case_manager')}
                itemTemplate={assignToItemTemplate}
                filter
              />
            </div>
          </div>
          <div className="d-flex mb-3">
            <div className="col-12">
              <DataTable
                value={selectedList}
                responsiveLayout="scroll"
                emptyMessage="No Data Found."
                selectionMode="checkbox"
                className="case-team-member"
                groupField="type"
                rowGroupMode="subheader"
                rowGroupHeaderTemplate={rowheaderTemplate}
                rowGroupFooterTemplate={() => <></>}
              >
                <Column body={selectColumnTemplate} style={{ width: 25 }} headerStyle={{ width: 25 }} />
                <Column field="name" header="Name" body={nameTemplate} />
                {!Boolean(isLeadSpecific) && (
                  <Column
                    field="notify"
                    header="Notify About Case Updates"
                    style={{ width: 100, textAlign: 'center' }}
                    headerStyle={{ textAlign: 'center' }}
                    body={notifyTemplate}
                  />
                )}
              </DataTable>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="d-flex mb-3 pb-3 border-bottom">
            <div className="col col-sm-6 text-muted F-size12">Name</div>
            <div className="col col-sm-3 text-muted F-size12">Role</div>
            <div className="col col-sm-3 text-muted F-size12">Notify About Case Updates</div>
          </div>

          {teamMembers?.map((m, i) => (
            <div className="d-flex mb-3 align-items-center avatarcurved" key={i}>
              <div
                className="col col-sm-6"
                onClick={() => {
                  const { sk, pk, firm_user_id, group_name } = m;
                  const url = group_name
                    ? '/settings/groups'
                    : firm_user_id
                    ? `/settings/team-management/${firm_user_id}`
                    : '/settings/team-management';
                  handleSettingSkAndPkToLocalStorage(sk, pk);
                  handleRedirection(history, url);
                }}
              >
                {m.group_name ? (
                  <>
                    <div className={`d-flex align-items-center pe-2 text-break groups-tooltip-${i}`}>
                      <ImageComponent fileName={m?.group_name} fileSize="small.jpg" className="col-1 me-2" />
                      <span className="pointer F-size12 redirection-link-no-text-transform file-manager-ellipsis-text" title={m.group_name}>
                        {m.group_name}
                      </span>
                    </div>
                    <Tooltip target={`.groups-tooltip-${i}`} position="top">
                      {GroupUserTooltip(m, caseDetails?.team_member)}
                    </Tooltip>
                  </>
                ) : (
                  <div className="d-flex align-items-center  pe-2 text-break">
                    <ImageComponent
                      filePath={`${m?.tenant_id}/${m?.firm_user_id ? m?.firm_user_id : m?.user_id}/profile_image`}
                      fileName={m?.first_name}
                      fileSize="small.jpg"
                      className="col-1 me-2"
                    />
                    <i className="fas fa-user-tie me-2"></i>
                    <span
                      className="pointer F-size12 redirection-link-no-text-transform file-manager-ellipsis-text"
                      title={`${m.first_name} ${m.last_name}`}
                    >{`${m.first_name} ${m.last_name}`}</span>
                  </div>
                )}
                <div className="archived-tags">{tagFormatting(m)}</div>
              </div>
              <div className={`col col-sm-3 file-manager-ellipsis-text`} title={getLeadRole(m)}>
                {getLeadRole(m)}
              </div>
              <div className="col col-sm-3 d-flex align-items-center justify-content-between">
                <InputSwitch
                  className="input-swich"
                  checked={m.notify_case_updates}
                  onChange={() => handleTeamMemberLawftAcces(m)}
                  disabled={m?.status === 'ARCHIVED' || m?.group_status === 'ARCHIVED' || isCaseClosed || isLeadClosed}
                />
                <i
                  className={
                    `icon-delete delete-tooltip-${i} icon-red cursor-pointer ` + (isCaseClosed || isLeadClosed ? 'pe-none opacity-25' : '')
                  }
                  onClick={() => confirmDeleteMember(m)}
                />
                <Tooltip
                  content={m?.group_name ? 'Delete Group' : 'Delete Team Member'}
                  position="left"
                  target={`.delete-tooltip-${i}`}
                  showDelay={500}
                />
              </div>
            </div>
          ))}
          {!teamMembers?.length && (
            <div className="d-flex mb-3 align-items-center">
              <div className="col-12">N/A</div>
            </div>
          )}
          <div className="my-2">
            <a
              onClick={toggleAddView}
              className={'addfield cursor-pointer ' + (isCaseClosed || isLeadClosed ? 'pe-none opacity-25' : '')}
              href
            >
              <i className="icon-add F-size14 me-1" />
              {isLeadSpecific ? 'Change Assign to' : 'Update Team'}
            </a>
          </div>
        </>
      )}
    </Card>
  );
}
