import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';
import React from 'react';
import { Tooltip } from 'primereact/tooltip';
import GlobalTooltip from 'components/GlobalTooltip';

const docTypeVal = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

function SignatureTemplate({ rowData }) {
  const { modals, setSelectedItem, isClosed, isClientFileManager } = useFileManagerContext();

  let condition = rowData?.file_type && (rowData?.file_type === 'application/pdf' || rowData?.file_type === docTypeVal);

  return (
    <>
      <span className="p-column-title text-break">Signature</span>
      {condition ? (
        <React.Fragment>
          <span className={isClosed ? 'opacity-25 pe-none' : ''}>
            {isClientFileManager ? (
              <span className="text-break">
                {rowData && rowData.esignature_status ? rowData.esignature_status : <i className="far fa-clock"></i>}
              </span>
            ) : (
              <>
                {/* <div
                  className="signature-icon-container pointer d-inline-block"
                  onClick={() => {
                    setSelectedItem(rowData);
                    modals.setShowSignatureModal(true);
                  }}
                >
                  <GlobalTooltip tooltip="Signature" className="me-3 text-primary-main">
                    <i className="icon-signature" aria-hidden="true" />
                  </GlobalTooltip>
                </div> */}
                <div
                  className="signature-icon-container pointer d-inline-block"
                  onClick={() => {
                    setSelectedItem(rowData);
                    modals.setShowSignatureModal(true);
                  }}
                >
                  <i className="icon-signature "></i>
                </div>
                <Tooltip target=".signature-target-icon" />
                <i
                  className="fas fa-circle signature-target-icon capitalize cursor-pointer"
                  data-pr-tooltip={rowData?.esignature_status}
                  style={{
                    color:
                      rowData?.esignature_status?.toLowerCase() === 'pending'
                        ? 'yellow'
                        : rowData?.esignature_status?.toLowerCase() === 'signed'
                        ? 'green'
                        : 'transparent',
                  }}
                  onClick={() => {
                    setSelectedItem(rowData);
                    modals.setEsignStatusModal(!modals.showEsignStatusModal);
                  }}
                ></i>
                {/* {rowData?.esignature_status?.toLowerCase() === 'pending' ? (
                  <i className="fas fa-circle" style={{ color: 'yellow' }}></i>
                ) : rowData?.esignature_status?.toLowerCase() === 'signed' ? (
                  <i className="fas fa-circle" style={{ color: 'green' }}></i>
                ) : null} */}
              </>
            )}
          </span>
        </React.Fragment>
      ) : (
        '-'
      )}
    </>
  );
}

export default SignatureTemplate;
