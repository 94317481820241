import React from 'react';

import { Controller } from 'react-hook-form';
import { Calendar } from 'primereact/calendar';

import InputErrorMessage from 'components/UI/InputErrorMessage/InputErrorMessage';
import LabelName from 'components/UI/LabelName/LabelName';

import moment from 'moment-timezone';
import { roundToNextHalfHour } from 'utils/utility_functions/timezone';
import { useUserDetailsContext } from 'context/userDetailsContext';

function CourtEventDate({ control, trigger, errors, setValue, timezoneBasedTime }) {
  const { userDetails } = useUserDetailsContext();

  function changeTimeValues(time) {
    if (time) {
      setValue('meeting_start_time', roundToNextHalfHour(new Date(time)));
      let updatedTime = roundToNextHalfHour(time);
      let addedTime = updatedTime.setMinutes(updatedTime.getMinutes() + 30);
      let endTime = new Date(addedTime);
      setValue('meeting_end_time', endTime);
    }
  }

  return (
    <div className="d-flex align-items-center py-2 flex-wrap">
      <div className="col-md-4 col-12">
        <LabelName required={true} htmlFor="when">
          When
        </LabelName>
      </div>
      <div className="col-md-8 col-12">
        <Controller
          name="when"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Calendar
              id={field.name}
              value={moment(field.value)?.tz(userDetails?.timezone)?.toDate()}
              onChange={(e) => {
                field.onChange(e.value);
                changeTimeValues(e.value);
                trigger('when');
              }}
              minDate={new Date(timezoneBasedTime)}
              placeholder="Date"
              monthNavigator
              yearNavigator
              yearRange="1800:2150"
              className="input-shadow w-100"
              inputRef={field.ref}
            />
          )}
        />
      </div>
      <div className="offset-md-4">
        {errors?.when?.type === 'required' ? <InputErrorMessage>Please select event date</InputErrorMessage> : ''}
      </div>
    </div>
  );
}

export default CourtEventDate;
