import GlobalLoader from 'components/GlobalLoader/GlobalLoader';
import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { tableCell } from 'modules/settings/TeamManagement/TeamManagementHelper';

export default function ExtractedEntitiesTable(props) {
  const { selectedFiles, extractedEntitiesData } = props;

  const entities = ['Name', 'Address', 'Email', 'Phone Number', 'Organization', 'Title', 'Police Badge Number'];

  return (
    <>
      <GlobalLoader />
      <div className="container-fluid input-shadow" style={{ background: '#FAFAFA', maxHeight: '100%' }}>
        <div className="datatable-responsive">
          <DataTable
            responsiveLayout="scroll"
            className="p-datatable-responsive"
            emptyMessage="No Data Found."
            value={extractedEntitiesData?.entities}
            stripedRows
            scrollable
            scrollHeight="600px"
          >
            {entities?.map((label) => (
              <Column header={label} field={label} sortable body={tableCell} bodyClassName="ellipsis-text" />
            ))}
          </DataTable>
        </div>
      </div>
    </>
  );
}
