import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';

const { default: ImageComponent } = require('shared/ImageComponent');
const { openLinkInBlank, toPascalCase, formatNumbersToCurrencyString } = require('utils/utils');
const _ = require('lodash');

export const routeToDetails = (id, rowData) => {
  localStorage.setItem('sk', rowData.sk);
  localStorage.setItem('pk', rowData.pk);
  // id = id ? id : rowData.user_id; //cognito_username replaced with firm_user_id for multi-tenancy
  id = id ? id : rowData.firm_user_id;
  if (!openLinkInBlank(`/settings/team-management/${id}`)) {
    window.location.hash = `/settings/team-management/${id}`;
  }
};

export const nameBody = (rowData) => {
  let title = [rowData.first_name, rowData.middle_name, rowData.last_name].filter((index) => index !== undefined);
  return (
    <>
      <span className="p-column-title">Name</span>
      <div className="text-primary-main  pointer" style={{ color: '#517DDA' }}>
        <div className="d-flex">
          <ImageComponent
            filePath={rowData && rowData.profile_image}
            fileName={rowData && rowData.first_name}
            fileSize="small.jpg"
            onClick={(e) => {
              routeToDetails(rowData.contact_id, rowData);
              e.preventDefault();
            }}
          />
          <a
            href="/"
            className="text-primary-main text-bold pointer"
            onClick={(e) => {
              routeToDetails(rowData.contact_id, rowData);
              e.preventDefault();
            }}
            // style={{ color: '#517DDA' }}
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '180px',
              color: '#517DDA',
            }}
            title={title.join(' ')}
          >
            {title.join(' ')}
          </a>
        </div>
        <div> {'is_confirmed' in rowData && rowData.is_confirmed === false ? <i style={{ marginLeft: '2rem' }}>Invited </i> : null}</div>
      </div>
    </>
  );
};

export const rateBody = (rowData) => {
  return (
    <>
      <span className="p-column-title">Rate</span>
      <span className="" title={formatNumbersToCurrencyString(rowData?.rate)}>
        {formatNumbersToCurrencyString(rowData?.rate)}
      </span>
    </>
  );
};

const roleLabel = (rowData) => {
  if (rowData && rowData.access_level === 'firm-admin') {
    return 'Firm Admin';
  } else if (rowData.user_type) {
    return _.startCase(_.toLower(rowData.user_type));
  }
};

export const roleBody = (rowData) => {
  return (
    <>
      <span className="p-column-title">Role</span>
      <span className="" title={roleLabel(rowData)}>
        {roleLabel(rowData)}
      </span>
    </>
  );
};

export const groupBody = (rowData, setRemaininggroupsData, overlayPanelRef) => {
  const sanitizedPk = rowData?.pk.replace(/[^a-zA-Z0-9_-]/g, '_');
  const userGroupsArr = rowData?.user_group?.length > 0 ? rowData?.user_group?.map((item) => item?.group_name) : [];
  const displayedGroups = userGroupsArr?.slice(0, 1);
  const remainingGroups = userGroupsArr?.slice(1);

  const handleMoreClick = (e) => {
    setRemaininggroupsData(remainingGroups);
    overlayPanelRef?.current?.toggle(e);
  };
  return (
    <>
      <span className="p-column-title">Groups</span>
      <span
        className="redirection-link-no-text-transform groups-redirection"
        onClick={() => {
          const route = '/settings/groups';
          if (!openLinkInBlank(route)) {
            window.location.hash = route;
          }
        }}
        title={displayedGroups[0]}
      >
        {/* {userGroupsArr?.join()} */}
        {displayedGroups[0]}
      </span>
      {remainingGroups?.length > 0 && (
        <div className="position-relative">
          <Button
            className={`p-mr-2 pa-tags more-tag-button more-tag-${sanitizedPk}`}
            label={`+${remainingGroups?.length} more`}
            onClick={handleMoreClick}
            style={{ padding: '5px 10px' }}
          />
        </div>
      )}
    </>
  );
};

export const tableCell = (row, ...rest) => {
  const { header, field } = rest?.[0];
  let string = row?.[field];
  return (
    <>
      <span className="p-column-title">{header}</span>
      {typeof string === 'string' ? (
        <span className="" title={toPascalCase(string)}>
          {toPascalCase(string)}
        </span>
      ) : (
        <span></span>
      )}
    </>
  );
};
export const statusBody = (rowData) => {
  return (
    <>
      <span className="p-column-title">Status</span>
      <span title={rowData.is_confirmed ? 'Confirmed' : 'Pending'} className="status-tags">
        <Tag
          className={rowData?.is_confirmed ? 's-tag file-manager-ellipsis-text' : 'd-tag file-manager-ellipsis-text'}
          value={rowData.is_confirmed ? 'Confirmed' : 'Pending'}
          severity="success"
        ></Tag>
      </span>
    </>
  );
};
