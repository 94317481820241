import React from 'react';
import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';
import GlobalTooltip from 'components/GlobalTooltip';

function CreateCourtEvent(props) {
  const { rowData } = props;
  const { handleShowCreateEvent } = useFileManagerContext();

  const handleCourtEvent = (rowData) => {
    const data = {
      file_path: rowData?.file_path,
      file_name: rowData?.file_name,
      sk: rowData?.sk,
    };
    handleShowCreateEvent(rowData, data);
  };

  return (
    <>
      <span role="button">
        <GlobalTooltip tooltip="Create Event" className="me-3 text-primary-main">
          <i className="icon-building" onClick={() => handleCourtEvent(rowData)} aria-hidden="true" />
        </GlobalTooltip>
      </span>
    </>
  );
}

export default CreateCourtEvent;
