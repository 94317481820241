import React, { useEffect } from 'react';

import { Controller } from 'react-hook-form';
import { Calendar } from 'primereact/calendar';

import InputErrorMessage from 'components/UI/InputErrorMessage/InputErrorMessage';
import LabelName from 'components/UI/LabelName/LabelName';

import { useCalendarContext } from 'modules/calendar/Context/CalendarContext';
import moment from 'moment-timezone';
import { useUserDetailsContext } from 'context/userDetailsContext';
import { roundToNextHalfHour } from 'utils/utility_functions/timezone';

function EventDate({ control, trigger, errors, setValue, timezoneBasedTime, watch }) {
  const { editEventModal } = useCalendarContext();
  const { userDetails } = useUserDetailsContext();
  const recurrenceLimitWatcher = watch('recurrence_keys.recurrence_limit');
  const isRecurringWatcher = watch('is_recurring');

  function changeTimeValues(time) {
    if (time) {
      let startTime = roundToNextHalfHour(new Date(time));
      setValue('meeting_start_time', startTime);
      let updatedTime = roundToNextHalfHour(time);
      let addedTime = updatedTime.setMinutes(updatedTime.getMinutes() + 30);
      let endTime = new Date(addedTime);
      setValue('meeting_end_time', endTime);

      if (isRecurringWatcher && startTime > new Date(recurrenceLimitWatcher)) {
        setValue('recurrence_keys.recurrence_limit', startTime);
      }
      //   if(recurrenceLimitWatcher) {
    }
  }

  useEffect(() => {
    if (!editEventModal) {
      setValue('when', new Date(timezoneBasedTime));
    }
  }, []);

  return (
    <div className="d-flex align-items-center py-2 flex-wrap">
      <div className="col-md-4 col-12">
        <LabelName required={true} htmlFor="when">
          When
        </LabelName>
      </div>
      <div className="col-md-8 col-12">
        <Controller
          name="when"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Calendar
              id={field.name}
              value={moment(field.value)?.tz(userDetails?.timezone)?.toDate()}
              onChange={(e) => {
                field.onChange(e.value);
                changeTimeValues(e.value);
                trigger('when');
              }}
              minDate={new Date(timezoneBasedTime)}
              placeholder="Date"
              monthNavigator
              yearNavigator
              yearRange="1950:2050"
              className="input-shadow w-100"
              inputRef={field.ref}
            />
          )}
        />
      </div>
      <div className="offset-md-4">
        {errors?.when?.type === 'required' ? <InputErrorMessage>Please select event date</InputErrorMessage> : ''}
      </div>
    </div>
  );
}

export default EventDate;
