import React, { useRef } from 'react';
import { Controller } from 'react-hook-form';
import { Calendar } from 'primereact/calendar';
import { Menu } from 'primereact/menu';
import { Tooltip } from 'primereact/tooltip';

import InputErrorMessage from 'components/UI/InputErrorMessage/InputErrorMessage';
import LabelName from 'components/UI/LabelName/LabelName';
import { useUserDetailsContext } from 'context/userDetailsContext';
import moment from 'moment-timezone';

function CourtEventTime({ control, trigger, errors, setValue, startTimeWatcher }) {
  const { userDetails } = useUserDetailsContext();
  const addTimePanel = useRef(null);

  const items = [
    // {
    //   label: '15 Minutes',
    //   command: () => {
    //     addTimeHandler(startTimeWatcher, 15, 'meeting_end_time');
    //   },
    // },
    {
      label: '30 Minutes',
      command: () => {
        addTimeHandler(startTimeWatcher, 30, 'meeting_end_time');
      },
    },
    {
      label: '1 Hour',
      command: () => {
        addTimeHandler(startTimeWatcher, 60, 'meeting_end_time');
      },
    },
    {
      label: '2 Hours',
      command: () => {
        addTimeHandler(startTimeWatcher, 120, 'meeting_end_time');
      },
    },
  ];

  function addTimeHandler(time, extraTime, field) {
    if (time) {
      let new_time = new Date(time);
      let addedTime = new_time.setMinutes(new_time.getMinutes() + extraTime);
      let endTime = new Date(addedTime);
      setValue(field, endTime);
      trigger('meeting_end_time');
    }
  }

  const getDateInTimezone = (date) => {
    return date ? moment.tz(date, userDetails?.timezone).toDate() : null;
  };

  return (
    <div className="d-flex align-items-center py-2 flex-wrap">
      <div className="col-md-4 col-12">
        <LabelName required={true}>Time</LabelName>
      </div>
      <div className="col-md-8 col-12 d-flex justify-content-between align-items-center">
        <div className="w-50">
          <Controller
            name="meeting_start_time"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Calendar
                id={field.name}
                value={getDateInTimezone(field.value)}
                onChange={(e) => {
                  field.onChange(e.value);
                  trigger('meeting_start_time');
                  addTimeHandler(e.value, 30, 'meeting_end_time');
                }}
                timeOnly
                hourFormat="12"
                stepMinute={30}
                placeholder="Start Time"
                className="input-shadow w-100 "
                inputRef={field.ref}
              />
            )}
          />
        </div>

        <div className="w-50 me-2">
          <Controller
            name="meeting_end_time"
            className="w-100"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Calendar
                id={field.name}
                value={getDateInTimezone(field.value)}
                onChange={(e) => {
                  field.onChange(e.value);
                  trigger('meeting_end_time');
                }}
                timeOnly
                hourFormat="12"
                stepMinute={30}
                placeholder="End Time"
                className="input-shadow w-100 mx-2"
                inputRef={field.ref}
              />
            )}
          />
        </div>
        {startTimeWatcher && (
          <>
            <Tooltip position="right" target="#duration" content={`Time Duration`} style={{ marginLeft: '10px' }} />
            <i
              id="duration"
              label="+"
              className="ms-3 fas fa-plus-circle pointer"
              onClick={(event) => addTimePanel.current.toggle(event)}
              aria-controls="add_time_panel"
              aria-haspopup
            />
            <Menu model={items} popup ref={addTimePanel} id="add_time_panel" />
          </>
        )}
      </div>
      <div className="offset-md-4">
        {errors?.meeting_start_time && <InputErrorMessage>Please select event start time</InputErrorMessage>}
      </div>
      <div>{errors?.meeting_end_time && <InputErrorMessage>Please select event end time</InputErrorMessage>}</div>
    </div>
  );
}

export default CourtEventTime;
