import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCaseDetailsAction } from 'redux/actions/CaseAction';
import { getClientUserList, listCasesAndLeads } from 'services/CaseServices';
import { listTimeEntries } from 'services/timerServices';
import { getTrustBalanceInfo, listExpenses, updateInvoice } from 'services/billingServices';
import { createInvoice, getInvoiceNumber, getSubscriptionPlan } from 'services/billingServices';
import { getInvoice, sendInvoiceReport } from 'services/billingServices';
import { format } from 'date-fns'; //TODO:REMOVE THIS
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';
import { listActivityTypes, getUserHourRate } from 'services/timerServices';
import { listExpenseTypes } from 'redux/actions/ExpenseTypeActions';
import { convertObjectToQuery, fixToTwoDecimal, formatNumbersToCurrencyString, parseRemiderPayload, parseReminderData } from 'utils/utils';
import _ from 'lodash';
import moment from 'moment';
import { confirmDialog } from 'primereact/confirmdialog';
import { getOfficeLocation } from 'redux/actions/OfficeLocationAction';
import { totalCountEvent } from 'services/generalServices';
import { reduceTax } from 'utils/utility_functions/commonCalculations';
import { getSettleMentDetails } from 'services/expenseService';
import { convertToTimezoneFormat, convertToTimezoneWithStartOfDayFormat } from 'utils/utility_functions/timezone';
import { UserDetailsContext } from 'context/userDetailsContext';
import { ViewEditInvoice } from '../ViewEditInvoice';
import { InvoicePrintView } from '../InvoicePrintView';
import { convertPaymentStructuresToLowerCase } from './helpers/createInvoiceHelpers';
import { adjustmentItems, adjustmentTypes, appliedToItems, paymentStructure, paymentTerms } from './configs/create_invoice_configs';
import PaymentPlan from './components/PaymentPlan/PaymentPlan';
import InvoiceTotal from './components/InvoiceTotal/InvoiceTotal';
import AvailableTrustFund from './components/AvailableTrustFund/AvailableTrustFund';
import UnPaidInvoice from './components/UnpaidInvoice/UnpaidInvoice';
import { PERSONAL_INJURY } from './constants/createInvoiceConstants';
import BillingDetails from './components/BillingDetails/BillingDetails';
import InvoiceForForm from './components/InvoiceForForm/InvoiceForForm';
import InvoiceDetailsForm from './components/InvoiceDetailsForm/InvoiceDetailsForm';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import debounce from 'lodash.debounce';
import { useForm } from 'react-hook-form';
import { useAccounts } from 'hooks/useAccounts';

const CreateInvoice = (props) => {
  const { isLeadSpecific, caseView, selectedInvoice, setShowRecordModal, loadInvoices, filters, getPageLimit } = props || {};

  const dispatch = useDispatch();
  // const caseListData = useSelector((state) => state.case.caseList.case);
  const caseDetailsState = useSelector((state) => state.caseDetails);
  const expense_types = useSelector((state) => state.expenseTypeList.expense_types.expense_type);
  const officeLocationList = useSelector((state) => state.officeLocation.officeLocationList);
  const userContext = useContext(UserDetailsContext);
  const { addToast } = useToast();
  const {
    control,
    getValues,
    watch,
    setValue,
    formState: { isDirty },
  } = useForm();
  const { accountList } = useAccounts();

  /** ========================================  React state starts ===================================== **/

  const [caseList, setCaseList] = useState([]);
  const [selectedCase, setSelectedCase] = useState('');
  const [billingContacts, setBillingContacts] = useState();
  let [selectedBillingContact, setSelectedBillingContact] = useState();
  let [timeEntires, setTimeEntries] = useState([]);
  let [expenses, setExpenses] = useState([]);
  const [timeEntriesTotal, setTimeEntriesTotal] = useState(0);
  const [expenseTotal, setExpenseTotal] = useState(0);
  const [billsAndExpenseTotal, setBillsAndExpenseTotal] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalHours, setTotalHours] = useState();
  const [invoiceDate, setInvoiceDate] = useState();
  const [depositInfo, setDepositInfo] = useState();
  const [dueDate, setDueDate] = useState();
  const [invoiceNumber, setInvoiceNumber] = useState();

  const [taxPercentage, setTaxPercentage] = useState(0);
  const [rateWithTax, setRateWithTax] = useState(0); // time_entries_total + tax

  const [subTotalAmount, setSubTotalAmount] = useState(0.0);

  const [selectedStatus, setSelectedStatus] = useState('Created');
  const [checkDate, setCheckDate] = useState('');
  const [checkNumber, setCheckNumber] = useState('');

  const [selectedPaymentTerm, setSelectedPaymentTerm] = useState();
  const [expenseTypes, setExpenseTypes] = useState([]);
  const [flatFeeTotal, setFlatFeeTotal] = useState(0.0);
  const [flatFeeActualTotal, setFlatFeeActualTotal] = useState(0.0);
  let [adjustments, setAdjustments] = useState([]);
  let [adjustmentTotal, setAdjustmentTotal] = useState(0.0);
  let [discount, setDiscount] = useState(0.0);
  let [flatFeeError, setFlatFeeError] = useState({});
  let [timeEntiresError, setTimeEntriesError] = useState({});
  let [expenseError, setExpenseError] = useState({});
  let [adjustmentError, setAdjustmentError] = useState({});
  const [settlementAmount, setSettlementAmount] = useState(0);
  const [litigation, setLitigation] = useState(false);
  const [contingencyItems, setContingencyItems] = useState();
  const [contingencyTotal, setContingencyTotal] = useState(0);
  const [paymentPlan, setPaymentPlan] = useState(false);
  const [paymentStartDate, setPaymentStartDate] = useState();
  const [firstPaymentAmount, setFirstPaymentAmount] = useState(0);
  let [paymentPlanError, setPaymentPlanError] = useState({});
  const [rate, setRate] = useState();
  // const [paymentPlanTaxPercentage, setPaymentPlanTaxPercentage] = useState(0);
  // const [paymentPlanRateWithTax, setPaymentPlanRateWithTax] = useState(0);

  const [invoiceDetails, setInvoiceDetails] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [caseDetails, setCaseDetails] = useState();
  let [teamMembers, setTeamMembers] = useState([]);

  let [flatFees, setFlatFees] = useState([]);
  const [activityTypes, setActivityTypes] = useState();

  const [selectedFrequency, setSelectedFrequency] = useState();
  const [numberOfPayments, setNumberOfPayments] = useState();
  let [paymentDates, setPaymentDates] = useState([]);
  const [subscriptionItems, setSubscriptionItems] = useState([]);
  let [isChanged, setIsChanged] = useState({
    invoice: false,
    time_entries: false,
    expenses: false,
    adjustments: false,
    flat_fees: false,
    payment_plan: false,
    contingency: false,
    retainer: false,
    subscription: false,
  });
  const [trustBalance, setTrustBalance] = useState();
  const [unPaidInvoices, setUnPaidInvoices] = useState([]);
  let [selectedUnpaidInvoices, setSelectedUnpaidInvoices] = useState([]);
  const [trustAccountData, setTrustAccountData] = useState();

  const [forwardedSubtotal, setForwardedSubtotal] = useState(0);
  const [subscriptionTotal, setSubscriptionTotal] = useState(0);
  let [contactDetails, setContactDetails] = useState({});
  const [userHourRate, setUserHourRate] = useState();
  const [showTimeEntry, setShowTimeEntry] = useState(true);
  const [settlementDetails, setSettlementDetails] = useState([]);
  const [searchValue, setSearchValue] = useState();
  const [load, setLoad] = useState();
  const [showAddContactModal, setShowAddContactModal] = useState(false);
  const [showEnableLawftAccessModal, setShowEnableLawftAccessModal] = useState(false);
  const [enableLawftAccessBillingContact, setEnableLawftAccessBillingContact] = useState();
  const [selectedDate, setSelectedDate] = useState([]);
  const [totalDueAmount, setTotalDueAmount] = useState(0);
  const [forwardedTotalDueAmount, setForwardedTotalDueAmount] = useState(0);

  //TODO:remove this and add react hook form validation
  let [formErrors, setFormErrors] = useState({
    case: '',
    contact: '',
    invoice_id: '',
    invoice_date: '',
    due_date: '',
    payment_terms: '',
    status: '',
  });

  /** ========================================  React state ends ===================================== **/

  /** ========================================  Other constant conditions starts  ===================================== **/

  const { FLAT_FEE, SUBSCRIPTION, CONTINGENCY_FEE, EVERGREEN_RETAINER, STANDARD_RETAINER } = convertPaymentStructuresToLowerCase() || {};

  const attorneyFeeAmount = settlementDetails?.length > 0 ? settlementDetails[0]?.attorney_fee_amount : 0;

  const isPersonalInjuryPracticeArea = caseDetails?.case_description?.case_practice_area === PERSONAL_INJURY;

  const lowerCasedPaymentStructure = caseDetails?.billing_preferences?.payment_structure?.toLowerCase() || '';

  const isFlatFee = lowerCasedPaymentStructure === FLAT_FEE;

  const isSubscription = lowerCasedPaymentStructure === SUBSCRIPTION;

  const isContigencyFee = lowerCasedPaymentStructure === CONTINGENCY_FEE;

  const isEverGreenRetainer = lowerCasedPaymentStructure === EVERGREEN_RETAINER;

  const isStandardRetainer = lowerCasedPaymentStructure === STANDARD_RETAINER;

  /** ========================================  Other constant conditions ends  ===================================== **/

  /** ================================= VALIDATION SECTION STARTS ============================= */
  //TODO:REMOVE THIS AND ADD react hook form
  const validateForm = () => {
    let isValid = true;
    if (!selectedCase) {
      formErrors.case = 'Required';
      isValid = false;
    }
    if (!selectedBillingContact) {
      formErrors.contact = 'Required';
      isValid = false;
    }
    if (!invoiceNumber) {
      formErrors.invoice_id = 'Required';
      isValid = false;
    }
    if (!invoiceDate) {
      formErrors.invoice_date = 'Required';
      isValid = false;
    }
    if (!depositInfo) {
      formErrors.deposit_Info = 'Required';
      isValid = false;
    }

    if (
      !dueDate &&
      !paymentPlan &&
      caseDetails?.billing_preferences?.payment_structure?.toLowerCase() !== paymentStructure.CONTINGENCY_FEE?.toLowerCase()
    ) {
      formErrors.due_date = 'Required';
      isValid = false;
    }
    if (!selectedStatus) {
      formErrors.status = 'Required';
      isValid = false;
    }
    if (
      caseDetails?.billing_preferences?.payment_structure?.toLowerCase() === paymentStructure.CONTINGENCY_FEE?.toLowerCase() &&
      caseDetails?.case_description?.case_practice_area === 'Personal Injury'
    ) {
      if (!checkDate) {
        formErrors.check_date = 'Required';
        isValid = false;
      }
      if (!checkNumber) {
        formErrors.check_number = 'Required';
        isValid = false;
      }
      let balance = settlementAmount ?? 0;
      if (caseDetails?.billing_preferences?.is_expense) {
        balance = (settlementAmount ?? 0) - expenseTotal;
      }
      if (balance < 0) {
        isValid = false;
        formErrors.settlement_amount = 'Settlement Amount should be greater than expense';
        setFormErrors({ ...formErrors });
      }
      if (!(settlementAmount > 0)) {
        formErrors.settlement_amount = 'Required';
        isValid = false;
      }
    }
    if (trustAccountData && trustAccountData?.amount_paid) {
      if (trustAccountData.amount_paid > trustAccountData.amount_available) {
        isValid = false;
        formErrors.amount_paid = "Amount paid can't be greater than available balance";
      }
      if (!trustAccountData.credit_account_name) {
        isValid = false;
        formErrors.credit_account_name = 'Required';
      }
      if (trustAccountData?.amount_paid < 0) {
        isValid = false;
        formErrors.amount_paid = 'Invalid Amount';
      }
    }
    if (props.selectedInvoice && props.selectedInvoice?.paid_amount) {
      let paid_amount = props.selectedInvoice?.paid_amount;
      paid_amount = parseFloat(paid_amount);
      if (totalAmount < paid_amount) {
        isValid = false;
        formErrors.total_amount = "Total amount can't be lesser than paid amount";
      }
    }

    setFormErrors({ ...formErrors });
    if (!isValid) {
      addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.FORM_DATA_MISSING);
    }
    return isValid;
  };

  const validatePaymentPlan = () => {
    let total = 0;
    let isValid = true;
    if (paymentPlan) {
      if (paymentDates && paymentDates.length > 0) {
        paymentDates.forEach((index, id) => {
          let amount = index.amount ? parseFloat(index.amount) : 0;
          if (!index.date) {
            paymentPlanError[`${id}_date`] = 'Required';
            isValid = false;
          }
          if (!amount || amount <= 0) {
            paymentPlanError[`${id}_amount`] = 'Required';
            isValid = false;
          } else {
            total = total + amount;
          }
        });
        if (Math.round(total, 2) !== Math.round(parseFloat(totalAmount), 2)) {
          paymentPlanError['total'] = 'Payment plan total is not equal to the invoice total';
          isValid = false;
        }
      }
      if (paymentPlan && (!paymentDates || paymentDates.length === 0) && numberOfPayments && numberOfPayments > 1) {
        paymentPlanError['payment_plan'] = 'Required payment plan';
        isValid = false;
      }
      if (!paymentStartDate && paymentPlan) {
        paymentPlanError['start_date'] = 'Required';
        isValid = false;
      }
      if (paymentPlan && firstPaymentAmount !== 0 && !firstPaymentAmount) {
        paymentPlanError['first_amount'] = 'Required';
        isValid = false;
      }

      if (
        (!paymentDates || (paymentDates && paymentDates.length === 0)) &&
        firstPaymentAmount !== rate &&
        paymentPlan &&
        numberOfPayments === '1'
      ) {
        paymentPlanError['first_amount'] = 'Amount is not equal to fee';
        isValid = false;
      }

      setPaymentPlanError({ ...paymentPlanError });
    }

    if (!isValid) {
      addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.FORM_DATA_MISSING);
    }

    return isValid;
  };

  const validateTables = () => {
    let isValid = true;
    if (parseFloat(caseDetailsState?.caseDetails?.billing_preferences?.due) !== 0 || invoiceDetails?.flat_fees) {
      if (flatFees && flatFees.length > 0) {
        flatFees.forEach((index, id) => {
          if (!index.flat_fee_date) {
            flatFeeError[`${id}_date`] = 'Required';
            isValid = false;
          }
          if (!index.flat_fee_amount || index.flat_fee_amount <= 0) {
            flatFeeError[`${id}_amount`] = 'Required';
            isValid = false;
          }
        });
        setFlatFeeError({ ...flatFeeError });
      }
    }
    if (
      timeEntires &&
      timeEntires.length > 0 &&
      ![paymentStructure.SUBSCRIPTION?.toLowerCase()].includes(caseDetails?.billing_preferences?.payment_structure?.toLowerCase()) &&
      showTimeEntry
    ) {
      timeEntires.forEach((index, id) => {
        //This validations are executed, if it is billable
        if (index?.billable) {
          if (!index.time_entry_date) {
            timeEntiresError[`${id}_date`] = 'Required';
            isValid = false;
          }
          if (!index.team_member_name) {
            timeEntiresError[`${id}_team_member`] = 'Required';
            isValid = false;
          }

          if (!index.activity_type && !index.module_name) {
            timeEntiresError[`${id}_activity_type`] = 'Required';
            isValid = false;
          }
          if (!index.hour_rate || index.hour_rate <= 0) {
            timeEntiresError[`${id}_hour_rate`] = 'Required';
            isValid = false;
          }
          if (!index.total_hours || index.total_hours <= 0) {
            timeEntiresError[`${id}_total_hours`] = 'Required';
            isValid = false;
          }
        }
      });
      setTimeEntriesError({ ...timeEntiresError });
    }
    if (expenses && expenses.length > 0) {
      expenses.forEach((index, id) => {
        if (index.billable) {
          if (!index.expense_date) {
            expenseError[`${id}_date`] = 'Required';
            isValid = false;
          }
          if (!index.team_member_name) {
            expenseError[`${id}_team_member`] = 'Required';
            isValid = false;
          }
          if (!index.expense_type) {
            expenseError[`${id}_expense_type`] = 'Required';
            isValid = false;
          }
          if (!index.rate_per_unit && index.rate_per_unit <= 0) {
            expenseError[`${id}_rate_per_unit`] = 'Required';
            isValid = false;
          }
          if (!index.quantity && index.quantity <= 0) {
            expenseError[`${id}_quantity`] = 'Required';
            isValid = false;
          }
        }
      });
      setExpenseError({ ...expenseError });
    }
    if (adjustments && adjustments.length > 0) {
      adjustments.forEach((index, id) => {
        if (!index.adjustment_item_id) {
          adjustmentError[`${id}_item`] = 'Required';
          isValid = false;
        }
        if (!index.adjustment_to_id) {
          adjustmentError[`${id}_applied_to`] = 'Required';
          isValid = false;
        }
        if (!index.adjustment_type_id) {
          adjustmentError[`${id}_type`] = 'Required';
          isValid = false;
        }
        if (index.adjustment_type_id === 1 && (!index.percentage || index.percentage <= 0)) {
          adjustmentError[`${id}_percentage`] = 'Required';
          isValid = false;
        }
        if (!index.amount || index.amount <= 0) {
          adjustmentError[`${id}_amount`] = 'Required';
          isValid = false;
        }
        if (index.amount && index.basic_amount && +index.amount > +index.basic_amount) {
          adjustmentError[`${id}_amount`] = 'Amount higher than basic amount';
          isValid = false;
        }
      });
      setAdjustmentError({ ...adjustmentError });
    }

    if (!isValid) {
      addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.FORM_DATA_MISSING);
    }
    return isValid;
  };

  const resetFormErrors = (field) => {
    if (field && field === 'all') {
      setFormErrors({
        payee: '',
        payment_structure: '',
        rate: '',
        payment_number: '',
        payment_frequency: '',
        contingency_percentage: '',
        retainer_amount: '',
      });
    } else if (field) {
      formErrors[field] = '';
      setFormErrors({ ...formErrors });
    }
  };

  /** ================================ VALIDATION SECTION ENDS ============================= */

  // const calculateAdjustment = () => {
  //   if (adjustments && adjustments.length > 0) {
  //     adjustments.forEach((index) => {
  //       let amount = 0;
  //       let basic_amount = index.basic_amount ? index.basic_amount : 0;
  //       basic_amount = parseFloat(basic_amount);
  //       let percentage = index.percentage ? index.percentage : 0;
  //       percentage = parseFloat(percentage);
  //       if (index.type && index.type === 'percentage' && basic_amount && percentage) {
  //         amount = basic_amount * (percentage / 100);
  //       } else if (basic_amount) {
  //         amount = basic_amount;
  //       }
  //       index.amount = amount;
  //     });
  //     setAdjustments([...adjustments]);
  //   }
  // };

  const loadTimeEntries = (data, limit) => {
    listTimeEntries(data, false, limit, '', '', true).then((timeEntiresResponse) => {
      if (timeEntiresResponse?.data?.time_entries) {
        if (timeEntiresResponse?.data?.time_entries.length > 0) {
          timeEntiresResponse?.data?.time_entries.forEach((index) => {
            if (!index.team_member_name) {
              let name = '';
              if (index?.created_by_details?.first_name) name += index?.created_by_details?.first_name;
              if (index?.created_by_details?.last_name) name = name + ' ' + index?.created_by_details?.last_name;

              index.team_member_name = name;
              index.team_member_id = index?.created_by_details?.user_id;
            }
          });
        }
        if (
          //if the payment mode is flat fee we need the time entry non billable by default
          [paymentStructure.FLAT_FEE?.toLowerCase(), paymentStructure.CONTINGENCY_FEE?.toLowerCase()].includes(
            props.caseDetails?.billing_preferences?.payment_structure?.toLowerCase()
          )
        ) {
          setTimeEntries([
            ...timeEntiresResponse.data.time_entries.map((data) => {
              return {
                ...data,
                billable: false,
              };
            }),
            ...timeEntires?.filter((data) => data.is_added_entry === true),
          ]);
        } else {
          setTimeEntries([...timeEntiresResponse.data.time_entries, ...timeEntires?.filter((data) => data.is_added_entry === true)]);
        }
      }
    });
  };

  //TODO:Check this logic
  const onChangeCase = async (e) => {
    setSelectedCase(e.value);
    setSelectedBillingContact();
    setPaymentPlan(false);

    setPaymentDates([]);
    if (!props.selectedInvoice) {
      setDueDate();
      setInvoiceDate();
      setSelectedPaymentTerm();
      setValue('reminder', []);
      setValue('is_client_contact', false);
      setValue('is_billing_contact', false);
      //setSelectedStatus();
      setExpenseTotal(0);
      setTotalHours(0);
      setTimeEntriesTotal(0);
      setTotalAmount(0);

      setSubTotalAmount(0);
      setTimeEntries(timeEntires.filter((v) => v.is_added_entry));
      setTimeEntriesError({});
      setExpenses([]);
      setFlatFees([]);
      setAdjustments([]);
      setFlatFeeTotal(0);
      setAdjustmentTotal(0);
      setDiscount(0);

      setSelectedFrequency();
      setNumberOfPayments();
      setPaymentStartDate();
      setRate();
      setTotalAmount(0);

      setContingencyItems();
      setContingencyTotal(0);
      setSubscriptionTotal(0);
      setForwardedSubtotal(0);
      setUnPaidInvoices([]);
      setSelectedUnpaidInvoices([]);
      setTrustAccountData();
      setTrustBalance();
      setFormErrors({
        case: '',
        contact: '',
        invoice_id: '',
        invoice_date: '',
        due_date: '',
        payment_terms: '',
        status: '',
      });
    }

    if (e?.value?.id) {
      try {
        setLoading(true);
        dispatch(getCaseDetailsAction('', '', '', e?.value?.id));
        let user_list = [];
        getClientUserList(e?.value?.id, false, false, true, false, props.isLeadSpecific ? true : false, false, false).then((response) => {
          if (response?.data && response.data.length > 0) {
            response.data.forEach((index) => {
              let name = index.first_name ? index.first_name : '';
              if (index.last_name) name = name + ' ' + index.last_name;
              let obj = {
                name: name,
                ...index,
              };
              user_list.push(obj);
            });

            setBillingContacts([...user_list]);

            if (props.selectedInvoice) {
              let contact_id = user_list.filter((index) => index?.contact_id === invoiceDetails?.invoice?.contact_id);
              if (contact_id.length > 0) setSelectedBillingContact(contact_id[0]);
            }
          }
        });
        let data = {
          case_id: e?.value?.id,
          time_entry_date: '',
          ...pickExtractedDate(selectedDate),
          time_entry_status: 'OPEN',
          search_key: '',
          billing_status: 'billable',
        };

        if (!props.selectedInvoice) {
          if (selectedDate.length !== 0) {
            let response = await totalCountEvent('time_entries', e?.value?.id);
            let limit = response?.data > 0 ? response?.data : 0;
            loadTimeEntries(data, limit);
          }

          let expenseFilter = {
            case_id: e?.value?.id,
            expense_status: 'UNINVOICED',
            expense_from: '',
            expense_to: '',
            expense_date: '',
            expense_filter: '',
            search_key: '',
          };

          listExpenses(expenseFilter).then((expenseResponse) => {
            if (expenseResponse?.data?.expenses) {
              setExpenses([...expenseResponse?.data?.expenses]);
            }
          });
        }

        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }
  };

  const onChangeContact = (e) => {
    setEnableLawftAccessBillingContact(e?.value);
    setIsChanged((preVal) => ({ ...preVal, invoice: true }));
    if (e?.value?.is_create_client_portal === false || !Boolean(e?.value?.email)) {
      // If client portal is not created, enable Lawft access
      setShowEnableLawftAccessModal(true);
    } else {
      setSelectedBillingContact({ ...e.value });

      contactDetails = {};
      contactDetails.city = e?.value?.city;
      contactDetails.street = e?.value?.street;
      contactDetails.state = e?.value?.state;
      contactDetails.country = e?.value?.country;
      contactDetails.zip = e?.value?.zip;
      contactDetails.phone = e?.value?.phone;
      contactDetails.country_code = e?.value?.country_code;
      setContactDetails({ ...contactDetails });
      formErrors.contact = '';
      setFormErrors({ ...formErrors });

      if (e?.value?.contact_id) {
        // getTrustBalanceInfo(selectedCase?.sk, e?.value?.contact_id)
        getTrustBalanceInfo(caseDetailsState?.caseDetails?.sk, e?.value?.contact_id)
          .then((response) => {
            if (response?.data?.is_trust_balance && response?.data?.trust_balance) {
              setTrustBalance({ ...response?.data?.trust_balance });
              let debit_account_id = '';
              let debit_account_name = '';
              let amount_available = '';
              if (response?.data?.trust_balance?.trust_data && response?.data?.trust_balance?.trust_data.length > 0) {
                let trust_data = response?.data?.trust_balance?.trust_data;
                debit_account_id = trust_data[0]?.trust_account_id;
                debit_account_name = trust_data[0]?.trust_account_name;
                amount_available = trust_data[0]?.trust_balance;
              }
              setTrustAccountData({
                client_name: response?.data?.trust_balance?.client_name,
                client_id: response?.data?.trust_balance?.client_name,
                debit_account_id: debit_account_id,
                debit_account_name: debit_account_name,
                credit_account_name: '',
                credit_account_id: '',
                amount_available: amount_available,
                amount_paid: '',
                deposit_into: 'operating',
              });
            }
          })
          .catch((err) => {});
      }
    }
  };

  const onSubmit = (send = false) => {
    if (validateForm() && validateTables() && validatePaymentPlan()) {
      const selectedReminder = getValues();
      let contact_name = '';
      const selectedAccount = accountList?.find((v) => v?.account_id === depositInfo);
      contact_name = selectedBillingContact?.first_name;
      if (selectedBillingContact?.middle_name) contact_name = contact_name + ' ' + selectedBillingContact?.middle_name;
      if (selectedBillingContact?.last_name) contact_name = contact_name + ' ' + selectedBillingContact?.last_name;
      let address = {};
      if (officeLocationList && officeLocationList.length > 0) {
        officeLocationList.map((index) => {
          if (index.is_primary_office === 'true') {
            address = index;
          }
        });
      }
      let case_id = props.isLeadSpecific ? props?.caseDetails?.case_id : selectedCase?.id;
      let case_name = props?.isLeadSpecific
        ? props?.caseDetails?.case_description?.case_name
        : selectedCase?.case_description?.case_name
        ? selectedCase?.case_description?.case_name
        : selectedCase?.value;
      let time_entries = [];
      let expenses_list = [];
      let flat_fees = [];
      let adjustments_list = [];

      if (timeEntires && timeEntires.length > 0) {
        time_entries = timeEntires.map((data) => {
          return {
            ...data,
            total_hours: data?.total_hours ? data.total_hours.toString() : '0',
            hour_rate: data?.hour_rate ? data.hour_rate.toString() : '0',
            case_id: case_id,
            case_name: case_name,
            amount: (data?.hour_rate * data?.total_hours).toString() || '0',
          };
        });
        time_entries = time_entries.map(({ is_added_entry, ...rest }) => ({
          ...rest,
        }));
      }

      if (expenses && expenses.length > 0) {
        expenses_list = expenses.map((data) => {
          return {
            ...data,
            case_id: case_id,
            case_name: case_name,
            quantity: data?.quantity ? data.quantity.toString() : '0',
            rate_per_unit: data?.rate_per_unit ? data.rate_per_unit.toString() : '0',
            total_amount: data?.total_amount ? data.total_amount.toString() : '0',
          };
        });
      }

      if (flatFees && flatFees.length > 0) {
        flat_fees = flatFees.map((data) => {
          return {
            ...data,
            flat_fee_amount: data.flat_fee_amount.toString(),
            case_id: case_id,
            case_name: case_name,
            unique_number: props?.caseDetails?.unique_number,
          };
        });
      }

      if (adjustments && adjustments.length > 0) {
        adjustments_list = adjustments.map((item) => {
          return {
            ...item,
            case_id: case_id,
            case_name: case_name,
            adjustment_item_id: item?.adjustment_item_id ? item.adjustment_item_id.toString() : '0',
            adjustment_to_id: item?.adjustment_to_id ? item.adjustment_to_id.toString() : '0',
            adjustment_type_id: item?.adjustment_type_id ? item.adjustment_type_id.toString() : '0',
            amount: item?.amount ? item.amount.toString() : '0',
            basic_amount: item?.basic_amount ? item.basic_amount.toString() : '0',
          };
        });
      }
      let invoice_assignee = [];
      if (selectedReminder?.is_billing_contact) {
        invoice_assignee.push('billing_contact');
      }
      if (selectedReminder?.is_client_contact) {
        invoice_assignee.push('main_client');
      }

      let data = {
        invoice: {
          is_lead: isLeadSpecific,
          // case_id: selectedCase?.case_id,
          // case_name: selectedCase?.case_description?.case_name,
          case_id: props?.caseDetails?.case_id ? props?.caseDetails?.case_id : selectedCase?.id,
          case_name: props?.caseDetails?.case_description?.case_name
            ? props?.caseDetails?.case_description?.case_name
            : selectedCase?.value,
          contact_id: selectedBillingContact?.contact_id,
          contact_name: contact_name,
          street: contactDetails?.street,
          city: contactDetails?.city,
          state: contactDetails?.state,
          zip_code: contactDetails?.zip,
          country: contactDetails?.country,
          phone: contactDetails?.phone,
          country_code: contactDetails?.country_code,
          invoice_date: invoiceDate
            ? moment(convertToTimezoneWithStartOfDayFormat(invoiceDate, userContext?.userDetails?.timezone)).format('MM/DD/YYYY')
            : '',
          payment_term_id: paymentTerms.findIndex((index) => index.label === selectedPaymentTerm) + 1,
          payment_terms: selectedPaymentTerm || '',
          // due_date: dueDate ? moment(convertToTimezoneWithStartOfDayFormat(dueDate, userContext?.userDetails?.timezone)).format() : '',
          due_date: dueDate ? moment(dueDate).tz(userContext?.userDetails?.timezone).endOf('day').format() : '',
          is_reminder: selectedReminder?.reminder?.length > 0,
          reminder: parseRemiderPayload(selectedReminder?.reminder),
          invoice_assignee: invoice_assignee,
          is_reminder_changed: isDirty,
          // invoice_status: selectedStatus,
          invoice_status: invoiceDetails ? _.startCase(_.toLower(invoiceDetails.invoice_status)) : 'Created',
          invoice_number: invoiceNumber,
          check_date: checkDate,
          check_number: checkNumber,
        },
        time_entries: {
          total_hours: totalHours ? totalHours.toString() : '0',
          total_amount: timeEntriesTotal ? timeEntriesTotal.toString() : '0',
          time_entry_data: time_entries,
        },
        expenses: {
          expense_data: expenses_list,
          total_amount: expenseTotal ? expenseTotal.toString() : '0',
        },
        adjustments: {
          adjustment_data: adjustments_list,
          discount_amount: discount.toString(),
          addition_amount: adjustmentTotal.toString(),
        },
        billed_from: {
          address: address,
        },
        deposit_account_id: selectedAccount?.account_id,
        deposit_account_name: selectedAccount?.account_name,
        deposit_account_type: selectedAccount?.account_type,
      };

      if (
        caseDetails?.billing_preferences?.payment_structure?.toLowerCase() === paymentStructure.CONTINGENCY_FEE?.toLowerCase() &&
        caseDetails?.case_description?.case_practice_area === 'Personal Injury'
      ) {
        data.settlement = {
          balance_owed: settlementDetails[0]?.balance_owed ?? 0,
          check_to_client: totalAmount ?? 0,
          attorney_fee_amount: settlementDetails[0]?.attorney_fee_amount ?? 0,
          total_case_expense: settlementDetails[0]?.total_case_expense ?? 0,
        };
        data.expenses.total_case_expense = settlementDetails[0]?.total_case_expense ?? 0;
        data.medical_bills = settlementDetails[0]?.medical_expense_per_provider ?? 0;
        data.case_expenses = settlementDetails[0]?.case_expense_per_vendor ?? 0;
      }

      if (props.selectedInvoice) {
        data.invoice.is_changed = isChanged.invoice || isDirty;
        data.time_entries.is_changed = isChanged.time_entries;
        data.expenses.is_changed = isChanged.expenses;
        data.adjustments.is_changed = isChanged.adjustments;
      }

      if (
        caseDetailsState.caseDetails?.billing_preferences?.payment_structure?.toLowerCase() === paymentStructure.FLAT_FEE?.toLowerCase() &&
        (parseFloat(caseDetailsState?.caseDetails?.billing_preferences?.due) !== 0 || invoiceDetails?.flat_fees || flat_fees)
      ) {
        let flat_fees_temp = flat_fees.map((object) => {
          delete object?.actual_amount;
          return object;
        });
        data.flat_fees = {
          flat_fee_data: flat_fees_temp,
          total_amount: flatFeeTotal.toString(),
          case_sk: caseDetailsState?.caseDetails?.sk,
          case_pk: caseDetailsState?.caseDetails?.pk,
        };
        if (caseDetailsState?.caseDetails?.billing_preferences?.tax_percentage) {
          data.flat_fees.tax_percentage = caseDetailsState?.caseDetails?.billing_preferences?.tax_percentage;
        }
        if (caseDetailsState?.caseDetails?.billing_preferences?.amount_with_tax) {
          data.flat_fees.default_flat_fee = caseDetailsState?.caseDetails?.billing_preferences?.amount_with_tax.toString();
        } else {
          data.flat_fees.default_flat_fee = caseDetailsState?.caseDetails?.billing_preferences?.rate.toString();
        }
        if (props.selectedInvoice) {
          data.flat_fees.is_changed = isChanged.flat_fees;
          data.flat_fees.total_amount_old = invoiceDetails?.flat_fees?.total_amount;
        }
      }

      if (!showTimeEntry) {
        data.time_entries = {};
      }

      data.is_payment_plan = paymentPlan;
      let paymentDatesTemp = [];
      if (paymentDates?.length) {
        //to make sure the amount is converted to string to prevent the BE from breaking.
        paymentDatesTemp = paymentDates.map((data) => {
          return {
            ...data,
            amount: data?.amount ? data.amount.toString() : '0',
            date: convertToTimezoneWithStartOfDayFormat(data.date, userContext?.userDetails?.timezone),
          };
        });
      }
      if (paymentPlan) {
        data.payment_plan = {
          payment_starting_date: convertToTimezoneWithStartOfDayFormat(paymentStartDate, userContext?.userDetails?.timezone),
          first_payment_amount: firstPaymentAmount ? firstPaymentAmount.toString() : '0',
          payment_plan_data: paymentDatesTemp,
          frequency_of_payment: selectedFrequency,
          number_of_payments: numberOfPayments,
          total_amount: totalAmount.toString(),
        };
        data.invoice.due_date = data.payment_plan.payment_plan_data[paymentDatesTemp.length - 1].date;
        if (props.selectedInvoice) data.payment_plan.is_changed = isChanged.payment_plan;
      }
      if (
        caseDetailsState.caseDetails?.billing_preferences?.payment_structure?.toLowerCase() ===
        paymentStructure.CONTINGENCY_FEE?.toLowerCase()
      ) {
        data.contingency = {
          settled_amount: settlementAmount,
          total_amount: contingencyTotal,
          is_expense_included: caseDetailsState.caseDetails?.billing_preferences?.is_expense,
          rate: caseDetailsState.caseDetails?.billing_preferences?.rate,
          is_litigation: litigation,
          contingency_data: contingencyItems,
        };
        if (props.selectedInvoice) data.contingency.is_changed = isChanged.contingency;
      }
      if (
        caseDetailsState.caseDetails?.billing_preferences?.payment_structure?.toLowerCase() ===
          paymentStructure.EVERGREEN_RETAINER?.toLowerCase() ||
        caseDetailsState.caseDetails?.billing_preferences?.payment_structure?.toLowerCase() ===
          paymentStructure.STANDARD_RETAINER?.toLowerCase()
      ) {
        data.retainer = {
          retainer_amount: caseDetails?.billing_preferences?.retainer_amount,
          retainer_date: caseDetails?.billing_preferences?.retainer_date,
        };
        if (caseDetailsState?.caseDetails?.billing_preferences?.tax_percentage) {
          data.time_entries.tax_percentage = taxPercentage;
          data.time_entries.total_amount_with_tax = rateWithTax ? rateWithTax.toString() : '0';
        }
        if (props.selectedInvoice) data.retainer.is_changed = isChanged.retainer;
      }
      if (
        caseDetailsState?.caseDetails?.billing_preferences?.payment_structure?.toLowerCase() ===
        paymentStructure.SUBSCRIPTION?.toLowerCase()
      ) {
        data.subscription = {
          total_amount: totalAmount.toString(),
          subscription_data: {
            ...subscriptionItems,
            unique_number: props?.caseDetails?.unique_number,
          },
        };
        if (caseDetailsState?.caseDetails?.billing_preferences?.tax_percentage) {
          data.subscription.tax_percentage = taxPercentage;
        }
        if (data?.subscription?.subscription_data?.amount_with_tax) {
          //to make sure only string is send to the server.
          data.subscription.subscription_data.amount_with_tax = (data?.subscription?.subscription_data?.amount_with_tax).toString();
        }
        if (data?.subscription?.subscription_data?.rate) {
          //to make sure only string is send to the server.
          data.subscription.subscription_data.rate = (data?.subscription?.subscription_data?.rate).toString();
        }
        if (props.selectedInvoice) data.subscription.is_changed = isChanged.subscription;
        data.time_entries.time_entry_data = [];
        data.time_entries.total_amount = '0';
        data.time_entries.total_hours = '0.00';
      }
      data.payment_structure = caseDetailsState?.caseDetails?.billing_preferences?.payment_structure;
      setLoading(true);

      if (trustAccountData?.amount_paid) {
        data.is_trust_account = true;
        data.trust_account_data = trustAccountData;
      }
      if (selectedUnpaidInvoices && selectedUnpaidInvoices.length > 0) {
        data.unpaid_invoices = {
          unpaid_invoice_data: selectedUnpaidInvoices,
          total_amount: forwardedSubtotal.toString(),
        };
      }

      if (props.selectedInvoice) {
        let updatedData = {
          ...props.selectedInvoice,
          ...data,
          created_by_details: invoiceDetails.created_by_details,
          invoice_status: _.startCase(_.toLower(invoiceDetails.invoice_status)),
        };
        updateInvoice(updatedData, isLeadSpecific ? isLeadSpecific : selectedCase?.is_lead)
          .then((response) => {
            setLoading(false);
            addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.INVOICE_UPDATE_SUCCESS);
            props.onSuccess();
          })
          .catch((err) => {
            let msg = toastConstant.message.INVOICE_UPDATE_FAILED;
            if (err && err?.response?.data?.message) {
              msg = err.response.data.message;
            }
            setLoading(false);
            addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, msg);
          });
      } else {
        createInvoice(data, props.isLeadSpecific ? props?.isLeadSpecific : selectedCase?.is_lead)
          .then((response) => {
            setLoading(false);
            addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.INVOICE_CREATE_SUCCESS);
            if (send) {
              sendInvoice(response?.data);
            }
            props.onSuccess();
          })
          .catch((err) => {
            let msg = toastConstant.message.INVOICE_CREATE_FAILED;
            if (err && err?.response?.data?.message) {
              msg = err.response.data.message;
            }
            setLoading(false);
            addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, msg);
          });
      }
    }
  };

  const nextDate = (frequency, date) => {
    let now = '';
    switch (frequency) {
      case 'weekly':
        return new Date(date.setDate(date.getDate() + 7));
      case 'biweekly':
        return new Date(date.setDate(date.getDate() + 14));
      case 'monthly':
        return new Date(date.setMonth(date.getMonth() + 1));

      case 'quarterly':
        return new Date(date.setMonth(date.getMonth() + 3));

      case 'yearly':
        return new Date(date.setFullYear(date.getFullYear() + 1));

      default:
        return new Date();
    }
  };

  const addFlatFee = () => {
    if (caseDetailsState.caseDetails?.billing_preferences?.payment_structure?.toLowerCase() === paymentStructure.FLAT_FEE?.toLowerCase()) {
      let _flatFees = flatFees;
      let name = caseDetailsState.caseDetails?.created_by_details?.first_name;
      name = name + ' ' + caseDetailsState.caseDetails?.created_by_details?.last_name;
      let flat_fee_amount = '';
      let due = caseDetailsState?.caseDetails?.billing_preferences?.due;

      if (caseDetailsState?.caseDetails?.billing_preferences?.due) {
        due = parseFloat(due);
        flat_fee_amount = due;
      } else {
        if (caseDetailsState?.caseDetails?.billing_preferences?.amount_with_tax) {
          flat_fee_amount = caseDetailsState?.caseDetails?.billing_preferences?.amount_with_tax.toString();
        } else {
          flat_fee_amount = caseDetailsState?.caseDetails?.billing_preferences?.rate.toString();
        }
      }
      if (flat_fee_amount === '0') flat_fee_amount = '';
      let obj = {
        flat_fee_date: new Date(),
        flat_fee_amount: flat_fee_amount.toString(),
        actual_amount: flat_fee_amount.toString(),
        employee_name: name,
        employee_id: caseDetailsState.caseDetails?.created_by_details?.user_id,
        billable: true,
        item_name: paymentStructure.FLAT_FEE,
      };
      if (caseDetailsState?.caseDetails?.billing_preferences?.tax_percentage) {
        obj.actual_amount = reduceTax(flat_fee_amount, caseDetailsState?.caseDetails?.billing_preferences?.tax_percentage);
      }
      if (props.selectedInvoice) obj.is_new = true;
      _flatFees.push(obj);
      setFlatFees([..._flatFees]);
      isChanged.flat_fees = true;
      setIsChanged({ ...isChanged });
    }
  };

  const addTimeEntry = () => {
    let _timeEntries = timeEntires;
    let obj = { billable: true };
    if (userHourRate) obj.hour_rate = userHourRate;
    if (!userHourRate && timeEntires && timeEntires.length > 0) {
      try {
        let lastIndex = timeEntires.length - 1;
        let hour_rate = timeEntires[lastIndex]?.hour_rate;
        if (hour_rate) obj.hour_rate = hour_rate;
      } catch (err) {}
    }
    if (props.selectedInvoice) obj.is_new = true;
    obj.is_added_entry = true;
    _timeEntries.push(obj);
    setTimeEntries([..._timeEntries]);
  };

  const pickExtractedDate = (dateArray) => {
    return {
      time_entry_from: dateArray?.[0]
        ? convertToTimezoneFormat(moment(dateArray[0]).startOf('day'), userContext?.userDetails.timezone)
        : '',
      time_entry_to: dateArray?.[0]
        ? convertToTimezoneFormat(moment(dateArray[1] ? dateArray[1] : dateArray[0]).endOf('day'), userContext?.userDetails.timezone)
        : '',
    };
  };

  const onChangeCalendar = async (e) => {
    setSelectedDate(e.value);
    let data = {
      case_id: selectedCase?.id,
      time_entry_date: '',
      ...pickExtractedDate(e.value),
      time_entry_status: 'OPEN',
      search_key: '',
      billing_status: 'billable',
    };
    if (!props.selectedInvoice) {
      let response = await totalCountEvent('time_entries', selectedCase?.id);
      let limit = response?.data > 0 ? response?.data : 0;
      loadTimeEntries(data, limit);
    }
  };

  const addExpense = () => {
    let obj = {
      billable: true,
      case_id: '',
      case_name: '',
      expense_date: '',
      expense_status: '',
      expense_type: '',
      expense_type_id: '',
      quantity: '',
      rate_per_unit: '',
      team_member_id: '',
      team_member_name: '',
      total_amount: '',
    };
    if (props.selectedInvoice) obj.is_new = true;
    expenses.push(obj);
    setExpenses([...expenses]);
  };

  const addAdjustment = () => {
    let obj = {
      item: adjustmentItems[0].value,
      applied_to: appliedToItems[0].value,
      type: adjustmentTypes[0].value,
      notes: '',
      basic_amount: '',
      percentage: '',
      amount: '',
    };
    if (props.selectedInvoice) obj.is_new = true;
    adjustments.push(obj);
    setAdjustments([...adjustments]);
  };

  const onChangeFlatFeeDate = (e, props) => {
    let index = props?.rowIndex;
    flatFees[index].flat_fee_date = e.value;
    flatFeeError[`${index}_date`] = '';
    setFlatFeeError({ ...flatFeeError });
    setFlatFees(flatFees);
  };

  const deleteFlatFee = (props) => {
    flatFees.splice(props.rowIndex, 1);
    setFlatFees([...flatFees]);
    isChanged.flat_fees = true;
    setIsChanged({ ...isChanged });
    setFlatFeeError({});
  };

  const deleteTimeEntry = (props) => {
    timeEntires.splice(props.rowIndex, 1);
    setTimeEntries([...timeEntires]);
    isChanged.time_entries = true;
    setIsChanged({ ...isChanged });
    setTimeEntriesError({});
  };

  const deleteExpense = (props) => {
    expenses.splice(props.rowIndex, 1);
    setExpenses([...expenses]);
    isChanged.expenses = true;
    setIsChanged({ ...isChanged });
    setExpenseError({});
  };

  const deleteAdjustment = (props) => {
    adjustments.splice(props.rowIndex, 1);
    setAdjustments([...adjustments]);
    isChanged.adjustments = true;
    setIsChanged({ ...isChanged });
    setAdjustmentError({});
  };

  const generatePlan = () => {
    validateForm();
    validatePaymentPlan();
    let totalAmountLocal = totalAmount;
    if (
      paymentPlan &&
      totalAmountLocal &&
      totalAmountLocal > 0 &&
      paymentStartDate &&
      selectedFrequency &&
      numberOfPayments &&
      numberOfPayments > 0 &&
      firstPaymentAmount >= 0
    ) {
      paymentPlanError['payment_plan'] = '';
      setPaymentPlanError({ ...paymentPlanError });
      let payment_dates = [];
      let number_of_payments = numberOfPayments ? parseInt(numberOfPayments) : 0;
      if (number_of_payments >= 1) {
        let amount = totalAmountLocal - firstPaymentAmount;
        if (firstPaymentAmount && firstPaymentAmount > 0) number_of_payments = number_of_payments - 1;

        let due_amount = amount / number_of_payments;
        due_amount = fixToTwoDecimal(due_amount);

        let totalCalculatedAmount = due_amount * number_of_payments + firstPaymentAmount;
        let diff = totalAmountLocal - totalCalculatedAmount;
        let first_payment = due_amount;

        let date = new Date(paymentStartDate);
        if (!firstPaymentAmount || firstPaymentAmount === 0) {
          payment_dates.push({ date: paymentStartDate, amount: first_payment });
          number_of_payments = number_of_payments - 1;
        } else if (firstPaymentAmount && firstPaymentAmount > 0) {
          payment_dates.push({
            date: paymentStartDate,
            amount: firstPaymentAmount,
          });
        }

        for (let i = 0; i < number_of_payments; i++) {
          payment_dates.push({
            date: date,
            amount: typeof due_amount === 'number' ? due_amount.toString() : due_amount,
          });
          date = nextDate(selectedFrequency, date);
        }
        if (diff) {
          let diff_total = fixToTwoDecimal(due_amount + diff);
          diff_total = typeof diff_total === 'number' ? diff_total.toString() : diff_total;
          if (payment_dates && payment_dates.length > 0) {
            payment_dates[payment_dates.length - 1].amount = diff_total;
          }
        }

        setPaymentDates([...payment_dates]);
      } else {
        setPaymentDates([]);
      }
      setPaymentPlanError({});
    } else {
      setPaymentDates([]);
    }
  };

  const sendInvoice = async (data) => {
    const { pk, sk } = data;
    const params = {
      contact_id: selectedBillingContact?.contact_id,
      invoice_pk: pk,
      invoice_sk: sk,
      is_lead: isLeadSpecific === true ? isLeadSpecific : selectedCase?.is_lead,
    };
    sendInvoiceReport(convertObjectToQuery(params))
      .then((response) => {
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.SEND_INVOICE_SUCCESS);
      })
      .catch((err) => {
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.SEND_INVOICE_FAILED);
      });
  };

  const accept = () => {
    props.onHide();
  };

  const reject = () => {};

  const confirm = () => {
    confirmDialog({
      message: 'Unsaved Data will be lost, Do you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept,
      reject,
      closable: false,
    });
  };

  const setHoursRate = () => {
    getUserHourRate().then((response) => {
      if (response?.data?.rate?.rate) {
        setUserHourRate(response?.data?.rate?.rate);
      }
    });
  };

  function getAllCaseList(is_lead = false, case_id = '', exclude_pi_case = true, keyword = '', limit = 15, is_filter_dropdown = true) {
    listCasesAndLeads(is_lead, case_id, exclude_pi_case, keyword, limit, is_filter_dropdown)
      .then((res) => {
        let tempArray = [];
        res?.data?.case_list?.forEach((item) => {
          tempArray.push({
            label:
              item && item.case_description && item.case_description.case_name
                ? `${item.unique_number ? `${item.unique_number} ` : ''}${item.case_description.case_name}`
                : 'TBD',
            value: item.case_description.case_name,
            id: item?.case_id,
          });
        });
        if (!props?.caseView && !props?.isLeadSpecific) {
          res?.data?.lead_list?.forEach((item) => {
            tempArray.push({
              label:
                item && item.case_description && item.case_description.case_name
                  ? `${item.unique_number ? `${item.unique_number} ` : ''}${item.case_description.case_name}`
                  : 'TBD',
              value: item.case_description.case_name,
              id: item?.case_id,
              is_lead: item?.is_lead,
            });
          });
        }
        setCaseList(tempArray);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const fetchData = useCallback((query) => {
    getAllCaseList(false, '', true, query, 15, true);
  }, []);

  const delayedSearch = useCallback(
    debounce((query) => {
      fetchData(query);
    }, 500),
    [fetchData]
  );

  const accountsListFiltered = useMemo(() => {
    let filteredAccounts = [...(accountList || [])];
    const _caseDetails = caseDetails || caseDetailsState?.caseDetails;
    if (_caseDetails?.default_trust_account?.[0]?.account_id) {
      filteredAccounts = filteredAccounts?.filter(
        (account) => account?.account_id === _caseDetails?.default_trust_account?.[0]?.account_id || account?.account_type === 'operating'
      );
    }
    if (_caseDetails?.default_operating_account?.[0]?.account_id) {
      filteredAccounts = filteredAccounts.filter(
        (account) => account?.account_id === _caseDetails?.default_operating_account?.[0]?.account_id || account?.account_type === 'trust'
      );
    }
    return filteredAccounts;
  }, [accountList, caseDetails, caseDetailsState?.caseDetails]);

  /** =========================================   All useEffects section starts  ====================================================== **/

  /**--------Total count api section starts -----------*/

  const loadActivityType = () => {
    totalCountEvent('activity_type')
      .then((response) => {
        listActivityTypes('', response.data).then((response) => {
          if (response?.data?.activity_types?.length > 0) {
            let activity_types = response?.data?.activity_types?.map((index) => {
              return {
                activity_type_code: index.activity_type_code,
                activity_type: index.activity_type,
                activity_type_id: index.activity_type_id,
              };
            });

            setActivityTypes([...activity_types]);
          }
        });
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (searchValue !== '') {
      delayedSearch(searchValue);
    } else if (searchValue === '' && load) {
      getAllCaseList(false, '', true, searchValue, 15, true);
    }
  }, [searchValue, delayedSearch, load]);

  useEffect(() => {
    loadActivityType();

    totalCountEvent('expense_type').then((response) => {
      dispatch(listExpenseTypes('', response.data));
    });

    setHoursRate();
    dispatch(getOfficeLocation());
  }, []);

  /**--------Total count api section ends -----------*/

  useEffect(() => {
    if (caseDetailsState && caseDetailsState.caseDetails && !props.selectedInvoice) {
      setCaseDetails({ ...caseDetailsState.caseDetails });
      setCaseDetails(props?.caseDetails);
      setTaxPercentage(caseDetailsState?.caseDetails?.billing_preferences?.tax_percentage ?? 0);
      getSubscriptionPlan(caseDetailsState?.caseDetails?.sk)
        .then((response) => {
          if (response?.data?.is_unpaid_invoice && response?.data?.unpaid_invoices) {
            setUnPaidInvoices(response?.data?.unpaid_invoices);
          } else {
            setUnPaidInvoices([]);
          }
          if (response?.data?.is_subscription && response?.data?.subscription) {
            if (response?.data?.subscription?.subscription_data?.amount_with_tax) {
              setSubscriptionTotal(parseFloat(response?.data?.subscription?.subscription_data?.amount_with_tax));
            } else {
              setSubscriptionTotal(parseFloat(response?.data?.subscription?.subscription_data?.rate));
            }
            setSubscriptionItems(response?.data?.subscription?.subscription_data);
            setDueDate(new Date(response?.data?.subscription?.subscription_data?.due_date));
            setInvoiceDate(new Date(response?.data?.subscription?.subscription_data?.invoice_date));
            setSelectedPaymentTerm('Monthly');
          }
        })
        .catch((err) => {
          if (err?.response?.data) {
            formErrors.subscription = err?.response?.data;
            setFormErrors({ ...formErrors });
          }
        });
      getSettleMentDetails(caseDetailsState?.caseDetails?.case_id).then((response) => {
        if (!!response?.data?.total_settlement) {
          setSettlementDetails([response?.data]);
          setSettlementAmount(response?.data?.total_settlement ?? 0);
        }
      });
      if (!!caseDetailsState?.caseDetails?.billing_preferences?.due) {
        if (parseFloat(caseDetailsState?.caseDetails?.billing_preferences?.due) === 0) {
          setPaymentPlan(false);
        }
      } else {
        setPaymentPlan(caseDetailsState?.caseDetails?.billing_preferences?.payment_plan);
      }
      setNumberOfPayments(caseDetailsState?.caseDetails?.billing_preferences?.number_of_payments);
      setSelectedFrequency(caseDetailsState?.caseDetails?.billing_preferences?.frequency_of_payment);
      setRate(caseDetailsState?.caseDetails?.billing_preferences?.rate);
      setFirstPaymentAmount(parseFloat(caseDetailsState?.caseDetails?.billing_preferences?.payment_plan_details?.first_payment_amount));
      // setPaymentPlanTaxPercentage(caseDetailsState?.caseDetails?.billing_preferences?.tax_percentage);
      // setPaymentPlanRateWithTax(caseDetailsState?.caseDetails?.billing_preferences?.amount_with_tax);
      if (caseDetailsState?.caseDetails?.billing_preferences?.payment_plan_details?.payment_starting_date) {
        setPaymentStartDate(new Date(caseDetailsState?.caseDetails?.billing_preferences?.payment_plan_details?.payment_starting_date));
      }
      if (caseDetailsState?.caseDetails?.billing_preferences?.payment_plan_details?.payment_dates) {
        let payment_dates = caseDetailsState?.caseDetails?.billing_preferences?.payment_plan_details?.payment_dates;
        if (payment_dates && payment_dates.length > 0) {
          payment_dates.forEach((index) => {
            index.date = new Date(index.date);
          });
          setPaymentDates(payment_dates);
        }
      }
      setDueDate();
      if (
        caseDetailsState.caseDetails?.billing_preferences?.payment_structure?.toLowerCase() === paymentStructure.FLAT_FEE?.toLowerCase()
      ) {
        let name = caseDetailsState.caseDetails?.created_by_details?.first_name;
        name = name + ' ' + caseDetailsState.caseDetails?.created_by_details?.last_name;
        let flat_fee_amount = '';
        let due = caseDetailsState?.caseDetails?.billing_preferences?.due;
        due = parseFloat(due);
        if (caseDetailsState?.caseDetails?.billing_preferences?.due) {
          flat_fee_amount = due;
        } else {
          if (!!caseDetailsState?.caseDetails?.billing_preferences?.amount_with_tax) {
            flat_fee_amount = caseDetailsState?.caseDetails?.billing_preferences?.amount_with_tax;
          } else {
            flat_fee_amount = caseDetailsState?.caseDetails?.billing_preferences?.rate;
          }
        }
        let obj = {
          flat_fee_date: new Date(),
          flat_fee_amount: flat_fee_amount,
          employee_name: name,
          employee_id: caseDetailsState.caseDetails?.created_by_details?.user_id,
          billable: true,
          item_name: paymentStructure.FLAT_FEE,
          tax_applied: true,
        };
        if (!!caseDetailsState?.caseDetails?.billing_preferences?.tax_percentage) {
          obj.actual_amount = reduceTax(flat_fee_amount, caseDetailsState?.caseDetails?.billing_preferences?.tax_percentage);
        }
        flatFees = [obj];
        setFlatFees([...flatFees]);
      }
      if (
        caseDetailsState.caseDetails?.billing_preferences?.payment_structure?.toLowerCase() === paymentStructure.SUBSCRIPTION?.toLowerCase()
      ) {
      }

      let clients = [];

      if (caseDetailsState?.caseDetails?.created_by_details && caseDetailsState?.caseDetails?.created_by_details.user_id) {
        let created_by_details = caseDetailsState?.caseDetails?.created_by_details;
        let name = created_by_details.first_name;
        if (created_by_details.last_name) name = name + ' ' + created_by_details.last_name;
        let obj = {
          contact_id: created_by_details.user_id,
          name: name,
        };
        clients.push(obj);
      }
      let team_members = [];
      if (caseDetailsState?.caseDetails?.team_member && caseDetailsState?.caseDetails?.team_member.length > 0) {
        caseDetailsState?.caseDetails?.team_member.forEach((index) => {
          let name = index.first_name;
          if (index.last_name) name = name + ' ' + index.last_name;
          let obj = {
            contact_id: index.contact_id,
            /*  first_name: index.first_name,
            last_name: index.last_name,
            middle_name: index.middle_name,
            client_cognito_username: index.client_cognito_username, */
            /*    rate: index.rate, */
            name: name,
          };
          if (clients.filter((index2) => index2.contact_id === index.contact_id).length === 0) {
            team_members.push(obj);
          }
        });
      }
      setTeamMembers([...team_members, ...clients]);
    } else if (caseDetailsState && caseDetailsState.caseDetails) {
      setCaseDetails({ ...caseDetailsState.caseDetails });
      let clients = [];

      if (caseDetailsState?.caseDetails?.created_by_details && caseDetailsState?.caseDetails?.created_by_details.user_id) {
        let created_by_details = caseDetailsState?.caseDetails?.created_by_details;
        let name = created_by_details.first_name;
        if (created_by_details.last_name) name = name + ' ' + created_by_details.last_name;
        let obj = {
          contact_id: created_by_details.user_id,
          name: name,
        };
        clients.push(obj);
      }
      let team_members = [];
      if (caseDetailsState?.caseDetails?.team_member && caseDetailsState?.caseDetails?.team_member.length > 0) {
        caseDetailsState?.caseDetails?.team_member.forEach((index) => {
          let name = index.first_name;
          if (index.last_name) name = name + ' ' + index.last_name;
          let obj = {
            contact_id: index.contact_id,
            /*  first_name: index.first_name,
            last_name: index.last_name,
            middle_name: index.middle_name,
            client_cognito_username: index.client_cognito_username, */
            /*    rate: index.rate, */
            name: name,
          };
          if (clients.filter((index2) => index2.contact_id === index.contact_id).length === 0) {
            team_members.push(obj);
          }
        });
      }
      setTeamMembers([...team_members, ...clients]);
      // getSubscriptionPlan(caseDetailsState?.caseDetails?.sk)
      //   .then((response) => {
      //     if (response?.data?.is_unpaid_invoice && response?.data?.unpaid_invoices && response?.data?.unpaid_invoices.length > 0) {
      //       response.data.unpaid_invoices = response?.data?.unpaid_invoices.filter(
      //         (index) => index.invoice_number !== props.selectedInvoice?.invoice_number
      //       );
      //       setUnPaidInvoices([...unPaidInvoices, ...response?.data?.unpaid_invoices]);
      //     }
      //   })
      //   .catch((err) => {});
      getSubscriptionPlan(caseDetailsState?.caseDetails?.sk)
        .then((response) => {
          const selectedInvoiceNumber = props?.selectedInvoice?.invoice_number;

          if (response?.data?.is_unpaid_invoice && Array?.isArray(response?.data?.unpaid_invoices)) {
            const selectedInvoiceSk = props?.selectedInvoice?.sk;

            // Find if the selected invoice exists in the response
            const responseInvoice = response?.data?.unpaid_invoices?.find((invoice) => invoice?.sk === selectedInvoiceSk);

            // If found, use the response invoice; otherwise, filter out the selected invoice
            const filteredInvoices = response?.data?.unpaid_invoices?.filter((invoice) => invoice?.sk !== selectedInvoiceSk);

            // Update state, ensuring uniqueness
            setUnPaidInvoices((prevInvoices) => {
              const updatedInvoices = responseInvoice
                ? [...prevInvoices, responseInvoice, ...filteredInvoices] // Keep response version
                : [...prevInvoices, ...filteredInvoices]; // Just filter

              // Remove duplicates based on sk and filter out selectedInvoice's invoice_number
              return Array?.from(new Map(updatedInvoices?.map((inv) => [inv?.sk, inv]))?.values())?.filter(
                (inv) => inv?.invoice_number !== selectedInvoiceNumber
              );
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      setCaseDetails('');
    }
  }, [caseDetailsState]);

  useEffect(() => {
    if (props.show && !props.selectedInvoice) {
      setSelectedCase();
      setSelectedBillingContact();
      setInvoiceDate();
      setInvoiceNumber();
      setDueDate();
      setSelectedPaymentTerm();
      setValue('reminder', []);
      setValue('is_client_contact', false);
      setValue('is_billing_contact', false);
      setSelectedStatus('Created');
      setExpenseTotal(0);
      setTotalHours(0);
      setTimeEntriesTotal(0);
      setTotalAmount(0);

      setSubTotalAmount(0);
      setTimeEntries([]);
      setExpenses([]);
      setFlatFees([]);
      setAdjustments([]);
      setFlatFeeTotal(0);
      setAdjustmentTotal(0);
      setDiscount(0);
      setPaymentDates([]);
      setPaymentPlan(false);
      setSelectedFrequency();
      setNumberOfPayments();
      setPaymentStartDate();
      setRate();
      setContingencyTotal(0);
      setContingencyItems();
      setSubscriptionTotal(0);
      setForwardedSubtotal(0);
      setUnPaidInvoices([]);
      setSelectedUnpaidInvoices([]);
      setTrustAccountData();
      setTrustBalance();
      setFormErrors({
        case: '',
        contact: '',
        invoice_id: '',
        invoice_date: '',
        due_date: '',
        payment_terms: '',
        status: '',
      });
      setContactDetails({});

      if (!props.selectedInvoice) {
        getInvoiceNumber()
          .then((response) => {
            if (response?.data?.invoice_number) {
              setInvoiceNumber(response?.data?.invoice_number);
            }
          })
          .catch((err) => {});
      }
    } else if (props.show) {
      setFormErrors({
        case: '',
        contact: '',
        invoice_id: '',
        invoice_date: '',
        due_date: '',
        payment_terms: '',
        status: '',
      });
      setUnPaidInvoices([]);
    }
  }, [props.show]);

  useEffect(() => {
    if (timeEntires && timeEntires.length > 0) {
      let subTotal = 0;
      let hours = 0;
      timeEntires.forEach((index) => {
        if (
          [paymentStructure.SUBSCRIPTION?.toLowerCase()].includes(
            caseDetailsState?.caseDetails?.billing_preferences?.payment_structure?.toLowerCase()
          )
        ) {
          index.billable = false;
        }
        if (index.billable) {
          let hour_rate = index.hour_rate;
          let total_hours = index.total_hours;
          hour_rate = hour_rate ? parseFloat(hour_rate) : 0;
          total_hours = total_hours ? parseFloat(total_hours) : 0;
          let total = hour_rate * total_hours;
          subTotal += total;
          hours += total_hours;
        }
      });
      //setSubTotalAmount(expenseTotal + subTotal);
      setTimeEntriesTotal(fixToTwoDecimal(subTotal));
      setTotalHours(hours.toFixed(2));
    } else {
      setTimeEntriesTotal(0);
      setTotalHours(0);
    }
  }, [timeEntires, caseDetailsState]);

  useEffect(() => {
    if (expenses && expenses.length > 0) {
      let subTotal = 0;
      expenses.forEach((index) => {
        if (
          [paymentStructure.SUBSCRIPTION?.toLowerCase()].includes(
            caseDetailsState?.caseDetails?.billing_preferences?.payment_structure?.toLowerCase()
          ) ||
          (caseDetailsState?.caseDetails?.billing_preferences?.payment_structure?.toLowerCase() ===
            paymentStructure.CONTINGENCY_FEE?.toLowerCase() &&
            !caseDetailsState?.caseDetails?.billing_preferences?.is_expense)
        ) {
          index.billable = false;
        }
        if (index.billable) {
          let total_amount = index.total_amount;
          total_amount = total_amount ? parseFloat(total_amount) : 0;
          subTotal += total_amount;
          subTotal = +parseFloat(subTotal).toFixed(2);
        }
      });
      setExpenseTotal(subTotal);
    } else {
      setExpenseTotal(0);
    }
  }, [expenses, caseDetailsState]);

  useEffect(() => {
    if (flatFees && flatFees.length > 0) {
      let total = 0;
      let actualTotal = 0;
      flatFees.forEach((index) => {
        if (index.billable && index.flat_fee_amount) {
          let amount = parseFloat(index.flat_fee_amount);
          if (!!index?.actual_amount) {
            actualTotal += parseFloat(index.actual_amount);
          } else {
            actualTotal += amount;
          }
          total = total + amount;
          total = fixToTwoDecimal(total);
        }
      });
      //setSubTotalAmount(subTotalAmount + total);
      setFlatFeeTotal(total);
      setFlatFeeActualTotal(actualTotal);
    } else {
      setFlatFeeTotal(0);
      setFlatFeeActualTotal(0);
    }
  }, [flatFees]);

  useEffect(() => {
    if (props.selectedInvoice && props.show) {
      setIsEdit(false);
      setInvoiceDetails();
      getInvoice(props.selectedInvoice.sk)
        .then((response) => {
          setInvoiceDetails({ ...response.data });
        })
        .catch((err) => {});
    } else {
      setIsEdit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedInvoice, props.show]);

  useEffect(() => {
    if (invoiceDetails && isEdit) {
      //clearFields()
      setDepositInfo(invoiceDetails?.deposit_account_id);
      setInvoiceNumber(invoiceDetails.invoice_number);
      if (invoiceDetails?.invoice?.invoice_date) {
        setInvoiceDate(new Date());
      }
      if (invoiceDetails?.invoice?.due_date) setDueDate(new Date(invoiceDetails?.invoice?.due_date));

      setSelectedStatus(invoiceDetails?.invoice?.invoice_status);
      setSelectedPaymentTerm(invoiceDetails?.invoice?.payment_terms || '');

      // let case_list = [];
      // if (caseList && caseList.length > 0) {
      //   case_list = caseList.filter((index) => index?.case_id === invoiceDetails?.invoice?.case_id);
      // }
      if (props.isLeadSpecific) {
        onChangeCase({
          value: {
            id: invoiceDetails?.invoice?.case_id,
            label: invoiceDetails?.invoice?.case_name,
            ...invoiceDetails,
            ...props?.caseDetails,
          },
        });
        // setSelectedCase(props.caseDetails);
      } else {
        // if (case_list.length > 0) {
        //   onChangeCase({ value: case_list[0] });
        //   //setSelectedCase()
        // }
        onChangeCase({
          value: {
            id: invoiceDetails?.invoice?.case_id,
            label: invoiceDetails?.invoice?.case_name,
            ...invoiceDetails,
            ...props?.caseDetails,
          },
        });
      }

      if (billingContacts && billingContacts.length > 0) {
        let contact_id = billingContacts.filter((index) => index?.contact_id === invoiceDetails?.invoice?.contact_id);
        if (contact_id.length > 0) setSelectedBillingContact(contact_id[0]);
      }
      setContactDetails({
        ...{
          city: invoiceDetails?.invoice?.city,
          street: invoiceDetails?.invoice?.street,
          state: invoiceDetails?.invoice?.state,
          country: invoiceDetails?.invoice?.country,
          zip: invoiceDetails?.invoice?.zip_code,
          phone: invoiceDetails?.invoice?.phone,
          country_code: invoiceDetails?.invoice?.country_code,
        },
      });

      setValue('reminder', parseReminderData(invoiceDetails?.invoice?.reminder) || []);
      setValue(
        'is_client_contact',
        invoiceDetails?.invoice?.invoice_assignee?.some((v) => v === 'main_client')
      );
      setValue(
        'is_billing_contact',
        invoiceDetails?.invoice?.invoice_assignee?.some((v) => v === 'billing_contact')
      );

      setTimeEntriesTotal(invoiceDetails?.time_entries?.total_amount ? parseFloat(invoiceDetails?.time_entries?.total_amount) : 0);
      setTotalHours(invoiceDetails?.time_entries?.total_amount ? parseFloat(invoiceDetails?.time_entries?.total_amount) : 0);
      setTimeEntries(invoiceDetails?.time_entries?.time_entry_data);
      setExpenseTotal(invoiceDetails?.expenses?.total_amount ? parseFloat(invoiceDetails?.expenses?.total_amount) : 0);
      setExpenses(invoiceDetails?.expenses?.expense_data);

      setAdjustments(invoiceDetails?.adjustments?.adjustment_data);
      setDiscount(invoiceDetails?.adjustments?.discount_amount ? parseFloat(invoiceDetails?.adjustments?.discount_amount) : 0);
      setAdjustmentTotal(invoiceDetails?.adjustments?.addition_amount);

      // setFlatFees(invoiceDetails?.flat_fees?.flat_fee_data);
      setFlatFees(() => {
        if (!!invoiceDetails?.flat_fees?.tax_percentage) {
          if (invoiceDetails?.flat_fees?.flat_fee_data.length > 0) {
            return invoiceDetails?.flat_fees?.flat_fee_data.map((data) => ({
              ...data,
              actual_amount: reduceTax(data.flat_fee_amount, invoiceDetails?.flat_fees?.tax_percentage),
            }));
          }
          return [];
        } else {
          return invoiceDetails?.flat_fees?.flat_fee_data;
        }
      });

      setPaymentPlan(invoiceDetails?.is_payment_plan);
      if (invoiceDetails?.is_payment_plan) {
        setPaymentStartDate(
          invoiceDetails?.payment_plan?.payment_starting_date ? new Date(invoiceDetails?.payment_plan?.payment_starting_date) : ''
        );
        setFirstPaymentAmount(
          invoiceDetails?.payment_plan?.first_payment_amount ? parseFloat(invoiceDetails?.payment_plan?.first_payment_amount) : 0
        );
        setPaymentDates(invoiceDetails?.payment_plan?.payment_plan_data);
        setSelectedFrequency(invoiceDetails?.payment_plan?.frequency_of_payment);
        setRate(invoiceDetails?.payment_plan?.total_amount);
        setNumberOfPayments(invoiceDetails?.payment_plan?.number_of_payments);
      }

      setSettlementAmount(invoiceDetails?.contingency?.settled_amount ? parseFloat(invoiceDetails?.contingency?.settled_amount) : 0);
      setContingencyTotal(invoiceDetails?.contingency?.total_amount ? parseFloat(invoiceDetails?.contingency?.total_amount) : 0);
      setContingencyItems(invoiceDetails?.contingency?.contingency_data);
      setLitigation(invoiceDetails?.contingency?.is_litigation);

      if (invoiceDetails?.subscription?.total_amount) {
        setTotalAmount(invoiceDetails?.subscription?.total_amount ? parseFloat(invoiceDetails?.subscription?.total_amount) : 0);
        setSubscriptionItems(invoiceDetails?.subscription?.subscription_data);
      }

      if (invoiceDetails?.trust_account_data) {
        setTrustAccountData(invoiceDetails?.trust_account_data);
      }
      if (invoiceDetails?.unpaid_invoices?.unpaid_invoice_data) {
        setUnPaidInvoices(invoiceDetails?.unpaid_invoices?.unpaid_invoice_data);
        setSelectedUnpaidInvoices(invoiceDetails?.unpaid_invoices?.unpaid_invoice_data);
        setForwardedSubtotal(parseFloat(invoiceDetails?.unpaid_invoices?.total_amount));
        setForwardedTotalDueAmount(
          invoiceDetails?.unpaid_invoices?.unpaid_invoice_data
            .map((invoice) => parseFloat(invoice.due_amount)) // Map to an array of due amounts
            .reduce((total, amount) => total + amount, 0) // Reduce to get the sum of the due amounts
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceDetails, isEdit]);
  useEffect(() => {
    if (expense_types && expense_types.length > 0) {
      let types = expense_types.map((index) => {
        return {
          expense_type: index.expense_type,
          expense_type_id: index.expense_type_id,
        };
      });

      setExpenseTypes([...types]);
    }
  }, [expense_types]);

  useEffect(() => {
    if (adjustments && adjustments.length > 0) {
      let discount = 0;
      let total = 0;

      adjustments.forEach((index) => {
        let amount = 0;

        let basic_amount = index.basic_amount ? index.basic_amount : '';
        if (basic_amount) basic_amount = parseFloat(basic_amount);
        let percentage = index.percentage ? index.percentage : '';
        if (percentage) percentage = parseFloat(percentage);
        index.percentage = percentage;
        if (
          index.adjustment_type_id &&
          (index.adjustment_type_id === 1 || index?.adjustment_type_id === '1') &&
          basic_amount &&
          percentage
        ) {
          amount = basic_amount * (percentage / 100);
          amount = fixToTwoDecimal(amount);
          index.amount = amount;
        } else if (index.adjustment_type_id && (index.adjustment_type_id === 2 || index.adjustment_type_id === '2') && basic_amount) {
          amount = index.amount;
        }

        if (index.adjustment_item_id === 1 || index.adjustment_item_id === '1') {
          discount = discount + amount;
        } else {
          total = total + amount;
        }
      });

      setDiscount(fixToTwoDecimal(discount));
      setAdjustmentTotal(fixToTwoDecimal(total));
    } else {
      setDiscount(0);
      setAdjustmentTotal(0);
    }
  }, [adjustments]);

  useEffect(() => {
    let expense_total = expenseTotal ? fixToTwoDecimal(expenseTotal) : 0;
    let flat_fee_total = flatFeeTotal ? fixToTwoDecimal(flatFeeTotal) : 0;
    let rate_with_tax = rateWithTax ? +parseFloat(rateWithTax).toFixed(2) : 0;
    let adjustment_total = adjustmentTotal ? fixToTwoDecimal(adjustmentTotal) : 0;
    let discount_amount = discount ? fixToTwoDecimal(discount) : 0;
    if (
      caseDetailsState.caseDetails?.billing_preferences?.payment_structure?.toLowerCase() ===
        paymentStructure.CONTINGENCY_FEE?.toLowerCase() &&
      caseDetails?.case_description?.case_practice_area === 'Personal Injury'
    ) {
      setTotalAmount((settlementAmount ?? 0) - (settlementDetails[0]?.attorney_fee_amount ?? 0) - (billsAndExpenseTotal ?? 0));
    } else {
      setSubTotalAmount(fixToTwoDecimal(expense_total + flat_fee_total + rate_with_tax + contingencyTotal + subscriptionTotal));
      setTotalAmount(
        fixToTwoDecimal(
          expense_total +
            flat_fee_total +
            rate_with_tax +
            adjustment_total +
            contingencyTotal +
            forwardedSubtotal +
            subscriptionTotal -
            discount_amount
        )
      );
      setTotalDueAmount(fixToTwoDecimal(forwardedTotalDueAmount));
    }
  }, [
    expenseTotal,
    flatFeeTotal,
    timeEntriesTotal,
    rateWithTax,
    adjustmentTotal,
    discount,
    contingencyTotal,
    forwardedSubtotal,
    subscriptionTotal,
    billsAndExpenseTotal,
    settlementAmount,
    settlementDetails,
  ]);

  useEffect(() => {
    if (caseDetails?.billing_preferences?.payment_structure?.toLowerCase() === paymentStructure.CONTINGENCY_FEE?.toLowerCase()) {
      let item = {
        type: 'Service',
        date: format(new Date(), 'MM/dd/yyyy'),
      };
      let balance = settlementAmount ?? 0;
      if (caseDetails?.billing_preferences?.is_expense) {
        balance = (settlementAmount ?? 0) - expenseTotal;
      }

      if (balance >= 0) {
        formErrors.settlement_amount = '';
        setFormErrors({ ...formErrors });
      } else {
        formErrors.settlement_amount = 'Settlement Amount should be greater than expense';
        setFormErrors({ ...formErrors });
      }

      if (litigation) {
        let litigationValue = fixToTwoDecimal(caseDetails?.billing_preferences?.litigation);
        item.notes = `Contingency Fee: ${caseDetails?.billing_preferences?.litigation} % of ${formatNumbersToCurrencyString(balance)}`;
        item.quantity = '1.00';
        if (balance > 0) {
          item.rate = fixToTwoDecimal((litigationValue / 100) * balance);
          item.total = fixToTwoDecimal((litigationValue / 100) * balance);
          setContingencyTotal(fixToTwoDecimal((litigationValue / 100) * balance));
        } else {
          item.rate = 0;
          item.total = 0;
          setContingencyTotal(0);
        }
      } else {
        let preLitigationValue = fixToTwoDecimal(caseDetails?.billing_preferences?.pre_litigation);
        item.notes = `Contingency Fee: ${caseDetails?.billing_preferences?.pre_litigation} % of ${formatNumbersToCurrencyString(balance)}`;
        item.quantity = '1.00';
        if (balance > 0) {
          item.rate = fixToTwoDecimal((preLitigationValue / 100) * balance);
          item.total = fixToTwoDecimal((preLitigationValue / 100) * balance);
          setContingencyTotal(fixToTwoDecimal((preLitigationValue / 100) * balance));
        } else {
          item.rate = 0;
          item.total = 0;
          setContingencyTotal(0);
        }
      }
      setContingencyItems([item]);
    } else {
      formErrors.settlement_amount = '';
      setFormErrors({ ...formErrors });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settlementAmount, litigation, expenseTotal, selectedCase, totalAmount]);

  useEffect(() => {
    let total = 0;
    let total_due_amount = 0;
    if (selectedUnpaidInvoices && selectedUnpaidInvoices.length > 0) {
      selectedUnpaidInvoices.forEach((index) => {
        let total_amount = parseFloat(index.invoice_amount);
        // let total_amount = parseFloat(index?.due_amount);
        total = total + total_amount;
        let due_amount = parseFloat(index.due_amount);
        total_due_amount = total_due_amount + due_amount;
      });
    }
    setForwardedSubtotal(total);
    setForwardedTotalDueAmount(total_due_amount);
  }, [selectedUnpaidInvoices]);

  useEffect(() => {
    formErrors.total_amount = '';
    setFormErrors({ ...formErrors });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalAmount]);

  useEffect(() => {
    if (props.caseView && !props.selectedInvoice) {
      if (props.isLeadSpecific) {
        onChangeCase({
          value: { id: props?.caseDetails?.case_id, label: props?.caseDetails?.case_description?.case_name, ...props?.caseDetails },
        });
      } else {
        onChangeCase({
          value: { id: props?.caseDetails?.case_id, label: props?.caseDetails?.case_description?.case_name, ...props?.caseDetails },
        });
      }
    }
  }, [props.caseView, props.selectedInvoice, props.show]);

  useEffect(() => {
    if (
      caseDetails?.billing_preferences?.payment_structure?.toLowerCase() == paymentStructure.STANDARD_RETAINER?.toLowerCase() ||
      caseDetails?.billing_preferences?.payment_structure?.toLowerCase() == paymentStructure.EVERGREEN_RETAINER?.toLowerCase()
    ) {
      if (timeEntriesTotal > 0) {
        if (taxPercentage > 0) {
          let taxAmount = timeEntriesTotal * (parseFloat(taxPercentage) / 100);
          setRateWithTax(timeEntriesTotal + taxAmount);
        } else {
          setRateWithTax(timeEntriesTotal);
        }
      } else {
        setRateWithTax(0);
      }
    } else {
      setRateWithTax(timeEntriesTotal);
    }
  }, [taxPercentage, timeEntriesTotal]);

  useEffect(() => {
    if (!!paymentPlan) {
      setDueDate();
    }
  }, [paymentPlan]);

  useEffect(() => {
    setBillsAndExpenseTotal(
      parseFloat(settlementDetails[0]?.total_bill ?? 0) +
        parseFloat(expenseTotal ?? 0) +
        parseFloat(settlementDetails[0]?.total_case_expense ?? 0)
    );
  }, [settlementDetails, expenseTotal]);

  useEffect(() => {
    if (invoiceDetails?.subscription?.total_amount && invoiceDetails?.subscription?.total_amount) {
      setSubscriptionTotal(invoiceDetails && invoiceDetails?.subscription?.total_amount);
    }
  }, [invoiceDetails]);
  /** =========================================   All useEffects section ends  ====================================================== **/

  return (
    <Dialog
      maximized={true}
      visible={props.show}
      onHide={() => {
        if (!props.selectedInvoice) {
          confirm();
        } else {
          props.onHide();
        }
        setPaymentPlanError({});
        setFlatFeeError({});
        setTimeEntriesError({});
        setExpenseError({});
        setAdjustmentError({});
        setInvoiceDetails();
      }}
      header={props?.viewInvoice ? 'Invoice Details' : 'Create Invoice'}
      className="invoice-popup"
      id="main-div"
    >
      {/* TODO:Why is this used for */}
      <div style={{ display: 'none' }}>
        <div id="invoicePrint">
          <InvoicePrintView invoiceDetails={invoiceDetails} />
        </div>
      </div>

      {(!props?.viewInvoice || isEdit) && (
        <div>
          <div className="row">
            {/* invoice for */}
            <InvoiceForForm
              isLeadSpecific={isLeadSpecific}
              caseDetails={caseDetails}
              caseView={caseView}
              selectedInvoice={selectedInvoice}
              caseList={caseList}
              selectedCase={selectedCase}
              billingContacts={billingContacts}
              selectedBillingContact={selectedBillingContact}
              contactDetails={contactDetails}
              onChangeCase={onChangeCase}
              onChangeContact={onChangeContact}
              setContactDetails={setContactDetails}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              load={load}
              setLoad={setLoad}
              showAddContactModal={showAddContactModal}
              setShowAddContactModal={setShowAddContactModal}
              setBillingContacts={setBillingContacts}
              showEnableLawftAccessModal={showEnableLawftAccessModal}
              setShowEnableLawftAccessModal={setShowEnableLawftAccessModal}
              enableLawftAccessBillingContact={enableLawftAccessBillingContact}
              caseDetailsState={caseDetailsState}
            />

            {/* invoice details */}
            <InvoiceDetailsForm
              isSubscription={isSubscription}
              isContigencyFee={isContigencyFee}
              isPersonalInjuryPracticeArea={isPersonalInjuryPracticeArea}
              invoiceNumber={invoiceNumber}
              invoiceDate={invoiceDate}
              paymentPlan={paymentPlan}
              selectedPaymentTerm={selectedPaymentTerm}
              dueDate={dueDate}
              control={control}
              watch={watch}
              setValue={setValue}
              selectedStatus={selectedStatus}
              checkDate={checkDate}
              checkNumber={checkNumber}
              setSelectedPaymentTerm={setSelectedPaymentTerm}
              setDueDate={setDueDate}
              setCheckDate={setCheckDate}
              setSelectedStatus={setSelectedStatus}
              setInvoiceDate={setInvoiceDate}
              setCheckNumber={setCheckNumber}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
              isChanged={isChanged}
              setIsChanged={setIsChanged}
              viewInvoice={props?.viewInvoice}
              invoiceDetails={invoiceDetails}
              depositInfo={depositInfo}
              setDepositInfo={setDepositInfo}
              accountList={accountsListFiltered}
            />
          </div>

          <div className="mt-4">
            <BillingDetails
              isFlatFee={isFlatFee}
              isSubscription={isSubscription}
              isContigencyFee={isContigencyFee}
              isEverGreenRetainer={isEverGreenRetainer}
              isStandardRetainer={isStandardRetainer}
              isPersonalInjuryPracticeArea={isPersonalInjuryPracticeArea}
              caseDetails={caseDetails}
              invoiceDetails={invoiceDetails}
              flatFees={flatFees}
              isChanged={isChanged}
              teamMembers={teamMembers}
              flatFeeActualTotal={flatFeeActualTotal}
              flatFeeTotal={flatFeeTotal}
              settlementAmount={settlementAmount}
              setSettlementAmount={setSettlementAmount}
              setFormErrors={setFormErrors}
              litigation={litigation}
              setLitigation={setLitigation}
              contingencyItems={contingencyItems}
              showTimeEntry={showTimeEntry}
              timeEntires={timeEntires}
              activityTypes={activityTypes}
              expenses={expenses}
              rateWithTax={rateWithTax}
              expenseTotal={expenseTotal}
              subTotalAmount={subTotalAmount}
              billsAndExpenseTotal={billsAndExpenseTotal}
              expenseTypes={expenseTypes}
              expenseError={expenseError}
              adjustmentError={adjustmentError}
              adjustments={adjustments}
              flatFeeError={flatFeeError}
              timeEntiresError={timeEntiresError}
              adjustmentTotal={adjustmentTotal}
              discount={discount}
              settlementDetails={settlementDetails}
              subscriptionItems={subscriptionItems}
              addExpense={addExpense}
              addFlatFee={addFlatFee}
              onChangeFlatFeeDate={onChangeFlatFeeDate}
              setIsChanged={setIsChanged}
              setFlatFees={setFlatFees}
              deleteFlatFee={deleteFlatFee}
              setShowTimeEntry={setShowTimeEntry}
              setTimeEntriesError={setTimeEntriesError}
              setTimeEntries={setTimeEntries}
              addTimeEntry={addTimeEntry}
              setExpenses={setExpenses}
              setExpenseError={setExpenseError}
              deleteExpense={deleteExpense}
              addAdjustment={addAdjustment}
              setAdjustments={setAdjustments}
              setAdjustmentError={setAdjustmentError}
              setFlatFeeError={setFlatFeeError}
              deleteTimeEntry={deleteTimeEntry}
              deleteAdjustment={deleteAdjustment}
              setCaseDetails={setCaseDetails}
              formErrors={formErrors}
              totalHours={totalHours}
              timeEntriesTotal={timeEntriesTotal}
              selectedCase={selectedCase}
              onChangeCalendar={onChangeCalendar}
              selectedDate={selectedDate}
              selectedInvoice={selectedInvoice}
            />

            {/* unpaid invoice */}
            {unPaidInvoices?.length > 0 && (
              <UnPaidInvoice
                unPaidInvoices={unPaidInvoices}
                selectedUnpaidInvoices={selectedUnpaidInvoices}
                setSelectedUnpaidInvoices={setSelectedUnpaidInvoices}
              />
            )}
          </div>

          {/* invoice total component */}
          <InvoiceTotal
            flatFeeTotal={flatFeeTotal}
            rateWithTax={rateWithTax}
            adjustmentTotal={adjustmentTotal}
            expenseTotal={expenseTotal}
            forwardedSubtotal={forwardedSubtotal}
            discount={discount}
            trustAccountData={trustAccountData}
            totalAmount={totalAmount}
            settlementAmount={settlementAmount}
            billsAndExpenseTotal={billsAndExpenseTotal}
            isContigencyFee={isContigencyFee}
            attorneyFeeAmount={attorneyFeeAmount}
            isPersonalInjuryPracticeArea={isPersonalInjuryPracticeArea}
            totalDueAmount={totalDueAmount}
            unPaidInvoices={unPaidInvoices}
          />

          {/* available trust fund component */}
          <AvailableTrustFund
            selectedInvoice={selectedInvoice}
            trustBalance={trustBalance}
            trustAccountData={trustAccountData}
            setTrustAccountData={setTrustAccountData}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
            accountList={accountsListFiltered}
          />

          {/* payment plan component  - only for flat fee */}
          {isFlatFee && (
            <PaymentPlan
              paymentPlan={paymentPlan}
              setPaymentPlan={setPaymentPlan}
              paymentDates={paymentDates}
              setPaymentDates={setPaymentDates}
              setPaymentPlanError={setPaymentPlanError}
              isChanged={isChanged}
              setIsChanged={setIsChanged}
              paymentPlanError={paymentPlanError}
              totalAmount={totalAmount}
              setRate={setRate}
              resetFormErrors={resetFormErrors}
              formErrors={formErrors}
              numberOfPayments={numberOfPayments}
              setNumberOfPayments={setNumberOfPayments}
              selectedFrequency={selectedFrequency}
              setSelectedFrequency={setSelectedFrequency}
              paymentStartDate={paymentStartDate}
              setPaymentStartDate={setPaymentStartDate}
              firstPaymentAmount={firstPaymentAmount}
              setFirstPaymentAmount={setFirstPaymentAmount}
              generatePlan={generatePlan}
            />
          )}

          {/* cancel ,create,create and send buttons */}
          <div className="mt-3 border-top">
            <div className="col-12 mt-3 d-flex justify-content-end">
              <Button className="p-button-secondary outline" label="Cancel" onClick={props?.onHide} />
              <Button
                className="p-button-secondary ms-2"
                label={props?.selectedInvoice ? 'Update' : 'Create'}
                disabled={totalAmount === 0 ? true : false}
                onClick={() => {
                  onSubmit();
                }}
                loading={loading}
              />
              {!props?.selectedInvoice && (
                <Button
                  className="p-button-secondary ms-2"
                  label={'Create and Send'}
                  disabled={totalAmount === 0 ? true : false}
                  onClick={() => {
                    onSubmit(true);
                  }}
                  loading={loading}
                />
              )}
            </div>
          </div>
        </div>
      )}

      {props?.viewInvoice && !isEdit && (
        <ViewEditInvoice
          invoiceDetails={invoiceDetails}
          setIsEdit={setIsEdit}
          isCaseClosed={props.isCaseClosed}
          isLeadClosed={props.isLeadClosed}
          isLeadSpecific={props.isLeadSpecific}
          caseExpense={settlementDetails[0]?.case_expense_per_vendor}
          setShowAcceptPaymentModal={setShowRecordModal}
          loadInvoices={loadInvoices}
          filters={filters}
          getPageLimit={getPageLimit}
        />
      )}
    </Dialog>
  );
};

export default CreateInvoice;
