import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { confirmDialog } from 'primereact/confirmdialog';
import { TieredMenu } from 'primereact/tieredmenu';
import { classNames } from 'primereact/utils';
import moment from 'moment';

import ImageComponent from 'shared/ImageComponent';
import PriorityDropdown from 'components/UI/PriorityDropdown/PriorityDropdown';
import { generateFullName } from 'utils/generateFullNameUtils';
import { PI_PROVIDERS_TYPE } from 'constants/index';
import { useLeadContext } from 'modules/lead/Context/LeadContext';
import { handleRedirection } from 'utils/utils';
import { handleSettingSkAndPkToLocalStorage } from 'utils/localStorageUtils';
import { dollarFormat } from 'utils/utils';

const getCardLeadTitle = (lead) => {
  if (lead?.is_draft) {
    if (
      lead?.draft_payload?.main_client?.first_name ||
      lead?.draft_payload?.main_client?.last_name ||
      lead?.draft_payload?.main_client?.title
    ) {
      return generateFullName(lead?.draft_payload?.main_client);
    } else if (lead?.draft_payload?.client?.first_name || lead?.draft_payload?.client?.last_name || lead?.draft_payload?.client?.title) {
      return generateFullName(lead?.draft_payload?.client);
    } else if (lead?.draft_payload?.contact?.first_name || lead?.draft_payload?.contact?.last_name || lead?.draft_payload?.contact?.title) {
      return generateFullName(lead?.draft_payload?.contact);
    } else return 'No Name';
  } else if (lead) {
    let leadTitle = '';
    let contactType = lead?.main_client?.contact_type;
    if (contactType === 'business' || PI_PROVIDERS_TYPE.includes(contactType)) {
      leadTitle = lead?.main_client?.title;
    } else if (contactType === 'person') {
      leadTitle = generateFullName(lead?.main_client);
    }
    return leadTitle;
  }
};

const noOfDaysInColumn = (stageSK, timeLine) => {
  return moment().diff(timeLine?.find((v) => v.lead_stage === stageSK)?.started_at, 'days');
};

function getStyle({ draggableStyle, virtualStyle, isDragging }) {
  const combined = {
    ...virtualStyle,
    ...draggableStyle,
  };

  // Being lazy: this is defined in our css file
  const grid = 4;

  // when dragging we want to use the draggable style for placement, otherwise use the virtual style
  const result = {
    ...combined,
    height: isDragging ? combined.height : combined.height - grid,
    left: isDragging ? combined.left : combined.left + grid,
    width: isDragging ? draggableStyle.width : `calc(${combined.width} - ${grid * 2}px)`,
    marginBottom: grid,
  };

  return result;
}

export default function Card(props) {
  const { provided, item, style, isDragging, index, setRowHeight, cardAction, stages } = props;
  const menu = useRef(null);
  const rowRef = useRef({});
  const history = useHistory();
  const { updateLead } = useLeadContext();
  const { sk, pk } = item;
  const leadAssigned = (item?.draft_payload?.lead_assigned_to ? [item?.draft_payload?.lead_assigned_to] : item?.assign_to) || [];
  const firmUserId = leadAssigned?.[0]?.firm_user_id;

  const [openSubMenu, setOpenSubMenu] = useState(false);

  useEffect(() => {
    if (rowRef.current) {
      setRowHeight(index, rowRef.current.clientHeight);
    }
  }, [rowRef]); // eslint-disable-line

  const confirmReOpen = () => {
    confirmDialog({
      className: 'common-popup-style',
      icon: 'pi pi-exclamation-triangle',
      header: 'Confirm Reopen Lead',
      message: 'Do you want to reopen this lead?',
      accept: () => cardAction('reopen', item),
      onHide: () => {},
      reject: () => {},
    });
  };

  const moveToItems = stages
    ?.filter((stage) => stage.sk !== item.lead_stage)
    .map((stage, idx) => {
      return {
        label: stage.lead_stage_name,
        command: () => {
          // const sourceIndex = stage.items.findIndex(colItem => colItem.pk === item.pk);
          const moveEvent = {
            draggableId: item?.pk,
            type: 'DEFAULT',
            source: {
              index: index,
              droppableId: item.lead_stage,
            },
            reason: 'DROP',
            mode: 'FLUID',
            destination: {
              droppableId: stage.sk,
              index: 0,
            },
            combine: null,
          };
          // Pass the moveEvent object to cardAction
          cardAction('move', moveEvent);

          // setMovePayload(moveEvent)
          setOpenSubMenu(false);
          setOpenSubMenu(false); // Close submenu after action
        },
      };
    });

  const items = [
    {
      label: 'Convert to Case',
      show: item?.is_open,
      command: () => cardAction('convert', item),
    },
    {
      label: 'Close Lead',
      show: item?.is_open,
      command: () => cardAction('close', item),
    },
    {
      label: 'Reopen Lead',
      show: !item?.is_open,
      command: confirmReOpen,
    },
    {
      label: 'Move To',
      // show: true,
      show: item?.is_open, //Only show move to if lead is open
      command: () => setOpenSubMenu(!openSubMenu),
      items: moveToItems,
    },
  ];

  const naviageDetails = (e) => {
    e.preventDefault();
    if (item?.is_draft) {
      cardAction('draft', item);
    } else if (item?.sk) {
      const route = `leads/${item?.sk?.split('#')?.pop()}`;
      handleRedirection(history, route);
      handleSettingSkAndPkToLocalStorage(sk, pk);
    }
  };

  const updateTaskPriority = (e) => {
    const _item = { ...item };
    _item.lead_details.priority = e.value;
    delete _item.case_description;
    updateLead(_item).then(() => cardAction('refresh'));
  };

  return (
    <div
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      style={getStyle({
        draggableStyle: provided.draggableProps.style,
        virtualStyle: style,
        isDragging,
      })}
      className={`item ${isDragging ? 'is-dragging' : ''}`}
    >
      <div className="container bg-white w-100 p-3 card-layout" ref={rowRef}>
        <div className="d-flex align-items-center pb-2">
          <div className="col-8">
            <div className="task-title text-bold redirection-link-no-text-transform" onClick={naviageDetails}>
              {getCardLeadTitle(item)}
            </div>
          </div>
          <div className="col-4 d-flex justify-content-end">
            {Boolean(item?.is_draft) ? (
              <span className="tag m-1 ms-0 draft-tag">Draft</span>
            ) : (
              <>
                <PriorityDropdown
                  className="w-100 border rounded priority-dropdown align-items-center me-2"
                  value={item?.lead_details?.priority}
                  onChange={updateTaskPriority}
                  iconOnly
                  filter
                  placeholder=""
                  disabled={Boolean(item?.is_draft)}
                />
                <div
                  className="d-flex border border-1 rounded  justify-content-center p-2 task-lane-header-options pointer"
                  onClick={(e) => menu.current.toggle(e)}
                >
                  <i className="pi pi-ellipsis-v"></i>
                  <TieredMenu model={items.filter((v) => v.show)} popup ref={menu} id="popup_menu" />
                </div>
              </>
            )}
          </div>
        </div>
        <hr className="my-1"></hr>
        <div className="d-flex align-items-center justify-content-between py-2">
          <div
            className="col-12 d-flex align-items-center"
            onClick={() => {
              if (leadAssigned?.length) {
                const route = firmUserId ? `/settings/team-management/${firmUserId}` : '/settings/team-management';
                handleRedirection(history, route);
                handleSettingSkAndPkToLocalStorage(sk, pk);
              }
            }}
          >
            <ImageComponent
              filePath={leadAssigned?.[0]?.profile_image}
              fileName={generateFullName(leadAssigned?.[0]) || 'C'}
              fileSize="medium.jpg"
              avatarSize="medium"
              className={classNames({ 'pe-none': !leadAssigned?.length })}
            />

            <div className={classNames({ 'redirection-link-no-text-transform': leadAssigned?.length, 'ms-1': !leadAssigned?.length })}>
              {leadAssigned?.[0]?.firm_user_id && <i className="fas fa-user-tie me-2"></i>}
              {leadAssigned?.length ? generateFullName(leadAssigned?.[0]) : 'Un-Assigned'}{' '}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between py-1">
          <div className="col-7 d-flex align-items-center black-600 F-size14">
            <i className="icon-workload pe-1" />
            Practice Area
          </div>
          <div className="col-5">
            <div className="taskcreate-date d-flex justify-content-end">
              <span className="d-flex">
                {item?.case_description?.case_practice_area || item?.draft_payload?.case_description?.case_practice_area || '-'}
              </span>
            </div>
          </div>
        </div>
        {(item?.case_description?.potential_case_value || item?.draft_payload?.case_description?.potential_case_value) && (
          <div className="d-flex align-items-center justify-content-between py-1">
            <div className="col-8 d-flex align-items-center black-600 F-size14">
              <i className="icon-money pe-1" />
              Potential Case Value
            </div>
            <div className="col-4">
              <div className="taskcreate-date d-flex justify-content-end">
                <span className="d-flex">
                  {dollarFormat(
                    item?.case_description?.potential_case_value || item?.draft_payload?.case_description?.potential_case_value
                  )}
                </span>
              </div>
            </div>
          </div>
        )}
        <div className="date-wrap d-flex align-items-center py-1">
          <div className="col-8 d-flex align-items-center black-600 F-size14">
            <i className="icon-calendar pe-1" /> In this Column
          </div>
          <div className="col-4 d-flex justify-content-end">{noOfDaysInColumn(item?.lead_stage, item?.lead_timeline)}d</div>
        </div>
      </div>
    </div>
  );
}
