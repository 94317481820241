import usePrintFile from 'modules/file_manager/hooks/usePrintFile';
import React from 'react';
import { useReactToPrint } from 'react-to-print';
import GlobalTooltip from 'components/GlobalTooltip';

function PrintFile(props) {
  const { rowData, isFromFilePreviewModal, filePreviewModalIconClassName, imageRef } = props;

  const { pdfTronPrintFile } = usePrintFile();

  const handlePrint = useReactToPrint({
    content: () => imageRef.current,
  });

  const printFile = (rowData) => {
    if (rowData?.file_type.includes('image')) {
      handlePrint();
    } else {
      pdfTronPrintFile(rowData);
    }
  };

  const iconClassName =
    isFromFilePreviewModal && filePreviewModalIconClassName ? filePreviewModalIconClassName : 'icon-Print text-primary-main';

  return (
    <>
      <span role="button" onClick={() => printFile(rowData)}>
        <GlobalTooltip tooltip="Print" className="me-3 text-primary-main">
          <i className={iconClassName} aria-hidden="true" />
        </GlobalTooltip>
      </span>
    </>
  );
}

export default PrintFile;
