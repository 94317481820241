import React from 'react';
import { Card } from 'primereact/card';
import { Controller } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import { RequiredValidation } from 'components/UI/RequiredValidation/RequiredValidation';
import { MultiSelect } from 'primereact/multiselect';

export default function Contacts({ formState, control, leadDetails, setPrimaryContact, setBillingContact }) {
  // Extract contact details from leadDetails
  const { contact, main_client, relations } = leadDetails;

  // Combine contacts from all sources into a single array
  const combinedContacts = [...contact, ...main_client, ...relations];

  // Filter out duplicate contacts based on their IDs
  const uniqueContacts = combinedContacts.reduce((unique, contact) => {
    if (!unique.some((c) => c.contact_id === contact.contact_id)) {
      unique.push(contact);
    }
    return unique;
  }, []);

  return (
    <Card className="shadow-middle billing-preferences F-size14">
      <div className="d-flex align-items-center py-2 flex-wrap">
        <div className="col-lg-4 col-12">
          <div className="p-medium">
            Primary Contact
            <span className="text-danger">*</span>
          </div>
        </div>
        <div className="col-lg-8 col-12">
          <Controller
            name="primary_contact"
            control={control}
            rules={{ required: 'Primary Contact is Required' }}
            render={({ field }) => (
              <Dropdown
                id={field.name}
                placeholder="Select primary contact"
                value={field.value}
                className="w-50 autowidth"
                options={uniqueContacts.map((contact) => ({
                  label:
                    contact.contact_type !== 'person'
                      ? contact.title
                      : `${contact.first_name} ${contact.middle_name ? contact.middle_name + ' ' : ''}${contact.last_name}`,
                  value: contact.contact_id,
                }))}
                optionLabel="label"
                optionValue="value"
                onChange={(e) => {
                  field.onChange(e.value); // Update the form field value with the selected contact IDs
                  const selectedContact = uniqueContacts.find((contact) => contact.contact_id === e.value);
                  setPrimaryContact(selectedContact);
                }}
                filter
              />
            )}
          />
          <RequiredValidation field={'primary_contact'} errors={formState?.errors} />
        </div>
      </div>
      <div className="d-flex align-items-start py-2 flex-wrap">
        <div className="col-lg-4 col-12">
          <div className="p-medium">Billing Contact</div>
        </div>
        <div className="col-lg-8 col-12 p-0">
          <Controller
            name="billing_contact"
            control={control}
            render={({ field }) => (
              <MultiSelect
                id={field.name}
                value={field.value}
                placeholder="Select billing contact"
                className="w-50 autowidth input-shadow"
                options={uniqueContacts.map((contact) => ({
                  label:
                    contact.contact_type !== 'person'
                      ? contact.title
                      : `${contact.first_name} ${contact.middle_name ? contact.middle_name + ' ' : ''}${contact.last_name}`,
                  value: contact.contact_id,
                }))}
                optionLabel="label"
                optionValue="value"
                onChange={(e) => {
                  field.onChange(e.value); // Update the form field value with the selected contact IDs
                  const selectedBillingContacts = uniqueContacts.filter((contact) => e?.value?.includes(contact.contact_id));
                  setBillingContact(selectedBillingContacts);
                }}
                filter
              />
            )}
          />
        </div>
      </div>
    </Card>
  );
}
