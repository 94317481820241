import React, { useContext, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { handleDateTimeOffset } from 'utils/utility_functions/timezone';
import { timeDifference } from 'services/notification/notificationService';
import constants from 'constants/index';
import { UserDetailsContext } from './userDetailsContext';

const ToastContext = React.createContext(null);

const ToastProvider = (props) => {
  const userContext = useContext(UserDetailsContext);
  const toastRef = useRef(null);
  const [toastPosition, setToastPosition] = useState();
  const addToast = (
    isSticky,
    severityValue,
    summaryValue,
    detailValue,
    position = 'top-right',
    contentFlag = false,
    notificationData = {},
    toastLife = 3000
  ) => {
    setToastPosition(position);

    //for notifications

    //here we are applying separate destructuring since undefined error occured
    let {
      notification_type: notificationType,
      body: notificationDataBody,
      time: notificationMessageTime = new Date(),
    } = notificationData || {};
    let {
      client_name: clientName,
      from: notificationMessageFrom,
      count: automationTriggerErrorCount,
      message_body: notificationMessage,
    } = notificationDataBody || {};

    let outboundMessagecondition = contentFlag && notificationType === 'outbound_status_callback';

    let timeDifferenceValue = timeDifference(new Date(notificationMessageTime), new Date()) || null;

    let toastContentMainTitle = '';
    let toastContentMainTitleIcon = '';

    if (notificationType === 'inbound_text_message') {
      toastContentMainTitle = 'New Text Message Received';
      toastContentMainTitleIcon = 'icon-messages';
    } else if (notificationType === 'automation_error_logs') {
      toastContentMainTitle = `New Automation Error Occured ${automationTriggerErrorCount ? `(${automationTriggerErrorCount})` : ''}`;

      toastContentMainTitleIcon = 'icon-Alert';
    }

    const toastContent = (
      <div className="d-flex flex-column justify-content-between mb-3 toast-contents">
        <div className="modal-heading F-size16 text-bold text-black-color d-flex align-items-center">
          <i className={`${toastContentMainTitleIcon} F-size18 me-2`}></i>
          {toastContentMainTitle}
        </div>
        <div className="p-2 mt-3 ">
          <div className="erro-details-container black-800 d-flex flex-column">{notificationMessage || ''}</div>
          <div className="d-flex F-size13 black-600 mt-2">
            <div className="">
              {notificationType === 'inbound_text_message' ? (
                <span className="F-size14 text-bold text-black-color">
                  {clientName || ''} ({notificationMessageFrom || ''})
                </span>
              ) : null}

              {/* <span className="">{timeDifference(new Date(notificationMessageTime), new Date())} </span> */}
              <span className="">{timeDifferenceValue} </span>
              <span className="black-600">{notificationMessageTime && timeDifferenceValue ? 'ago' : null}</span>
            </div>
            <div className="">
              <span className="mx-2">&#x2022;</span>
              {handleDateTimeOffset(
                userContext?.userDetails?.timezone,
                notificationMessageTime,
                constants.full_month_DateYear_Fulltime_12hr
              )}
            </div>
          </div>
        </div>
      </div>
    );

    /** --- This function is to get the toast message content --- */
    const getToastContent = () => {
      let condition = Object?.keys(notificationData)?.length && contentFlag;
      if (condition) {
        return toastContent;
      }
      return null;
    };

    /** --- Works if it is not an outbound message (or outbox message: the message those are sent from ourselves) --- **/
    if (!outboundMessagecondition) {
      return (
        toastRef.current.show({
          sticky: isSticky, //When enabled, message is not removed automatically.
          severity: severityValue,
          summary: summaryValue,
          detail: detailValue,
          position: position,
          content: getToastContent(),
          life: toastLife,
        }) | null
      );
    }
  };

  return (
    <ToastContext.Provider
      value={{
        addToast,
      }}
    >
      <Toast animation={false} ref={toastRef} position={toastPosition} />
      {props.children}
    </ToastContext.Provider>
  );
};

const useToast = () => {
  const toastHelpers = useContext(ToastContext);
  return toastHelpers;
};

export { ToastContext, useToast };
export default ToastProvider;
